
import tooltip from "../../../_root/components/tooltip/tooltip";
import colors from "../../base/colors";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...tooltip,

  styleOverrides: {
    ...tooltip.styleOverrides,
    backgroundColor: colors.black,
    color: colors.light.main,
  },
  arrow: {
    ...tooltip.arrow,
    color: colors.black,
  },
};
