
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
  styleOverrides: {},
  variants: []
};
