import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	formControl: {
		borderRadius: `50%`
	},
	label: {
		color: `${theme.palette.text.primary} !important`,
		fontWeight: `400 !important`,
		fontSize: `12px !important`,
		letterSpacing: `0.5px !important`,
		lineHeight: `7px !important`,
		marginTop: `5px !important`
	},
	inputLabel: {
		'& .MuiFormControlLabel-label': {
			fontSize: `10px !important`,
			fontWeight: `400 !important`,
			'& .MuiRadio-root': {
				padding: `5px 9px !important`
			}
		}
	},
	info: {
		marginLeft: `0!important`,
		fontSize: 10,
		marginTop: 0,
		padding: 0,
		color: theme.palette.text.secondary
	}
}));

export { useStyles };
