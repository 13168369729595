import dialogContent from "../../../_root/components/dialog/dialogContent";
import borders from "../../base/borders";
import colors from '../../base/colors'

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  ...dialogContent,
  styleOverrides: {
    ...dialogContent.styleOverrides,
    root: {
      ...dialogContent.styleOverrides.root,
      color: colors.text.main,
    },

    dividers: {
      ...dialogContent.styleOverrides.dividers,
      borderTop: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
      borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
    },
  },
};
