import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box } from '@mui/material';
import { FormPassword, SubmitButton, ModalHeader, ModalBody, ModalFooter } from '../../../../_components';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { validate } from '.';
import { preAuthRoutes } from '../../../../routes';
import { ForgotPasswordAction } from '../../../../redux/actions';

// Initialize form input values to null
const inputs = { password: '', confirm_password: '', email: '', otp: '' };

function ResetPassword({ backTo, onClose, forgotPasswordData }) {

    const dispatch = useDispatch();

    /** Redux actions and state */
    const { reset_password_loading: isLoading, reset_password_errors: resetPassowrdErrors } = useSelector((state) => state.ForgotPasswordReducer);
    const resetPassword = (params) => dispatch(ForgotPasswordAction.resetPassword(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...resetPassowrdErrors });
    }, [resetPassowrdErrors])

    useEffect(() => {
        setData({ ...data, email: forgotPasswordData.email, otp: forgotPasswordData.otp });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPasswordData])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSendPasswordResetLinkAction();
    }

    /**
     * function to dispatch send password reset link
     * @author Akshay N
     */
    async function dispatchSendPasswordResetLinkAction() {
        const isPasswordChanged = await resetPassword(data);
        if (isPasswordChanged.status === 1) {
            backTo('login')
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={preAuthRoutes(`reset_password`).name} onClose={onClose} />
                <ModalBody>
                    <Box>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={1}
                                    label='Password'
                                    name='password'
                                    value={data.password}
                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={2}
                                    label='Re-enter password'
                                    name='confirm_password'
                                    value={data.confirm_password}
                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box>
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label="Reset Password" color={`secondary`} fullWidth loading={isLoading} />
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { ResetPassword };
