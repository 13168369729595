import Swal from 'sweetalert2';

const SweetAlert = Swal.mixin({
	customClass: {
		confirmButton: '',
		cancelButton: 'btn btn-danger'
	},
	buttonsStyling: true
});

export { SweetAlert };
