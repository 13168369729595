import {
    FEATURED_PROMOS_REQUEST, FEATURED_PROMOS_SUCCESS, FEATURED_PROMOS_FAILURE,
    PROMOS_REQUEST, PROMOS_SUCCESS, PROMOS_FAILURE,
    PROMO_DETAIL_REQUEST, PROMO_DETAIL_SUCCESS, PROMO_DETAIL_FAILURE,
    PROMO_INFO_REQUEST, PROMO_INFO_SUCCESS, PROMO_INFO_FAILURE,
} from "../actions";

const initialState = {
    featured_promos_loading: false, featured_promos: [], featured_promos_errors: {},
    promos_loading: false, promos: [], promos_errors: {},
    promo_loading: false, promo: {}, promo_errors: {},
    promo_info_loading: false, promo_info: {}, promo_info_errors: {}
};

export function PromoReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand promos redux */
        case FEATURED_PROMOS_REQUEST: {
            return {
                ...state,
                featured_promos_loading: true,
                featured_promos_errors: {}
            };
        }
        case FEATURED_PROMOS_SUCCESS: {

            let newArray = [...state.featured_promos]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.promos.forEach((payload) => {
                const promoIndex = newArray.findIndex(promos => promos._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                featured_promos_loading: false,
                featured_promos: newArray,
                featured_promos_errors: {}
            };

        }
        case FEATURED_PROMOS_FAILURE: {
            return {
                ...state,
                featured_promos_loading: false,
                featured_promos_errors: action.errors
            };
        }
        /** end::List brand promos redux */

        /** begin::List brand promos redux */
        case PROMOS_REQUEST: {
            return {
                ...state,
                promos_loading: true,
                promos_errors: {}
            };
        }
        case PROMOS_SUCCESS: {

            let newArray = [...state.promos]; //making a new array
            
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.promos.forEach((payload) => {
                const promoIndex = newArray.findIndex(promos => promos._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                promos_loading: false,
                promos: newArray,
                promos_errors: {}
            };

        }
        case PROMOS_FAILURE: {
            return {
                ...state,
                promos_loading: false,
                promos_errors: action.errors
            };
        }
        /** end::List brand promos redux */

        /** begin:: Details brand promos redux */
        case PROMO_DETAIL_REQUEST: {
            return {
                ...state,
                promo_loading: true,
                promo: {},
                promo_errors: {}
            };
        }
        case PROMO_DETAIL_SUCCESS: {

            let newArray = [...state.promos]; //making a new array
            const promoIndex = newArray.findIndex(promos => promos._id === action.data._id);
            if (promoIndex !== -1) {
                newArray[promoIndex] = action.data;
            } else {
                newArray = newArray.concat(action.data);
            }

            return {
                ...state,
                promo_loading: false,
                promos: newArray,
                promo: action.data,
                promo_errors: {}
            };

        }
        case PROMO_DETAIL_FAILURE: {
            return {
                ...state,
                promo_loading: false,
                promo_errors: action.errors
            };
        }
        /** end:: Details brand promos redux */

        /** begin:: Details brand promos redux */
        case PROMO_INFO_REQUEST: {
            return {
                ...state,
                promo_info_loading: true,
                promo_info: {},
                promo_info_errors: {}
            };
        }
        case PROMO_INFO_SUCCESS: {
            return {
                ...state,
                promo_info_loading: false,
                promo_info: action.data,
                promo_info_errors: {}
            };

        }
        case PROMO_INFO_FAILURE: {
            return {
                ...state,
                promo_info_loading: false,
                promo_info_errors: action.errors
            };
        }
        /** end:: Details brand promos redux */


        default:
            return state;
    }
}
