import { cookieServices } from "../../_helpers";
import {
	SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
	SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE,
} from "../actions";

let isLoggedIn = cookieServices.get('accessToken') ? (cookieServices.get('accessToken')) : false;
const initialState = isLoggedIn ? { loggedIn: true, errors: {}, password_errors: {} } : { loggedIn: false, request: {}, errors: {}, };

export function SignInReducer(state = { ...initialState }, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SIGN_IN_REQUEST: {
			return {
				loading: true,
				loggedIn: false,
				request: action.request
			};
		}
		case SIGN_IN_SUCCESS: {
			return {
				loading: false,
				loggedIn: true,
			};
		}
		case SIGN_IN_FAILURE: {
			return {
				loading: false,
				loggedIn: false,
				errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::Sign out redux */
		case SIGN_OUT_REQUEST: {
			return {
				loading: true
			};
		}
		case SIGN_OUT_SUCCESS: {
			return {
				loading: false,
				loggedIn: false,
			};
		}
		case SIGN_OUT_FAILURE: {
			return {
				loading: false,
				loggedIn: false
			};
		}
		/** end::Sign out redux */

		default:
			return state;
	}
}
