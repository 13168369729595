
/**
 * The function allows only alphabets, numbers, and underscores to be entered in an input field.
 * @param {Object} e - The parameter "e" is an event object that is passed to the function when it is called. It
 * contains information about the event that triggered the function, such as the type of event, the
 * target element, and any user input that was involved. In this case, the function is using the "
 * @returns The function `returnAlphabetsNumbersWithUnderScore` returns `true` if the input character
 * is an alphabet, number or underscore, and `false` otherwise. It also prevents the default behavior
 * of the event if the input character is not valid.
 * @author Akshay N
 * @created_at 22/05/2023
 */
const returnAlphabetsNumbersWithUnderScore = (e) => {
    var regex = new RegExp('^[a-zA-Z0-9_]*$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
}

export { returnAlphabetsNumbersWithUnderScore }
