import { failure, request, success } from ".";
import { GlobalAction } from ".";
import { NotificationServices } from "../services";

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const GET_NOTIFICATIONS_LIST_REQUEST = 'GET_NOTIFICATIONS_LIST_REQUEST';
export const GET_NOTIFICATIONS_LIST_SUCCESS = 'GET_NOTIFICATIONS_LIST_SUCCESS';
export const GET_NOTIFICATIONS_LIST_FAILURE = 'GET_NOTIFICATIONS_LIST_FAILURE';

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_READ_FAILURE';

export const MARK_NOTIFICATION_READ_REQUEST = 'MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_FAILURE = 'MARK_NOTIFICATION_READ_FAILURE';

export const NotificationAction = {

    getNotifications: (params) => {
        return dispatch => {
            dispatch(request(GET_NOTIFICATIONS_REQUEST, params));
            return NotificationServices.getNotificationList(params)
                .then(
                    response => { return dispatch(success(GET_NOTIFICATIONS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_NOTIFICATIONS_FAILURE, params, error)); }
                );
        };
    },

    getNotificationList: (params) => {
        return dispatch => {
            dispatch(request(GET_NOTIFICATIONS_LIST_REQUEST, params));
            return NotificationServices.getNotificationList(params)
                .then(
                    response => { return dispatch(success(GET_NOTIFICATIONS_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_NOTIFICATIONS_LIST_FAILURE, params, error)); }
                );
        };
    },

    markAllRead: () => {
        return dispatch => {
            dispatch(request(MARK_ALL_NOTIFICATIONS_READ_REQUEST, {}));
            return NotificationServices.markAllRead()
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(MARK_ALL_NOTIFICATIONS_READ_SUCCESS, {}, response)); },
                    error => { return dispatch(failure(MARK_ALL_NOTIFICATIONS_READ_FAILURE, {}, error)); }
                );
        };
    },

    markRead: (params) => {
        return dispatch => {
            dispatch(request(MARK_NOTIFICATION_READ_REQUEST, params));
            return NotificationServices.markRead(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(MARK_NOTIFICATION_READ_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MARK_NOTIFICATION_READ_FAILURE, params, error)); }
                );
        };
    }
};
