import { Grid, Typography } from '@mui/material';
import React from 'react';
import { MenuItem } from '../_component/menu-item';
import { MenuCategoryStyled } from '.';

function MenuCategory({ ...props }) {

    const { menu } = props;

    return (
        <MenuCategoryStyled item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Typography variant="h6" className='category-title'>{menu.category}</Typography>
            <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                {
                    menu.items.map((items, index) => {
                        return (<Grid item xs={5} sm={5} md={4} lg={3} xl={2} key={index} marginRight= "18px"><MenuItem {...items} /></Grid>);
                    })
                }
            </Grid>
        </MenuCategoryStyled>
    );
}

export { MenuCategory };