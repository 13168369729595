import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borders.borderRadius.md,
    },
  },
  variants: []
};
