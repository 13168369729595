import tabs from "../../../_root/components/tabs/tabs";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...tabs,
  styleOverrides: {
    ...tabs.styleOverrides,
    root: {
      ...tabs.styleOverrides.root,
      backgroundColor: colors.grey[100],
    },


    indicator: {
      ...tabs.styleOverrides.indicator,
      backgroundColor: colors.common.white,
    },
  },
};
