import { failure, request, success } from ".";
import { GiveawayServices } from "../services";

export const FEATURED_GIVEAWAYS_REQUEST = 'FEATURED_GIVEAWAYS_REQUEST';
export const FEATURED_GIVEAWAYS_SUCCESS = 'FEATURED_GIVEAWAYS_SUCCESS';
export const FEATURED_GIVEAWAYS_FAILURE = 'FEATURED_GIVEAWAYS_FAILURE';

export const GIVEAWAYS_REQUEST = 'GIVEAWAYS_REQUEST';
export const GIVEAWAYS_SUCCESS = 'GIVEAWAYS_SUCCESS';
export const GIVEAWAYS_FAILURE = 'GIVEAWAYS_FAILURE';

export const GIVEAWAY_DETAIL_REQUEST = 'GIVEAWAY_DETAIL_REQUEST';
export const GIVEAWAY_DETAIL_SUCCESS = 'GIVEAWAY_DETAIL_SUCCESS';
export const GIVEAWAY_DETAIL_FAILURE = 'GIVEAWAY_DETAIL_FAILURE';

export const GIVEAWAY_INFO_REQUEST = 'GIVEAWAY_INFO_REQUEST';
export const GIVEAWAY_INFO_SUCCESS = 'GIVEAWAY_INFO_SUCCESS';
export const GIVEAWAY_INFO_FAILURE = 'GIVEAWAY_INFO_FAILURE';

export const GiveawayAction = {

    listFeaturedGiveaways: (params) => {
        return dispatch => {
            dispatch(request(FEATURED_GIVEAWAYS_REQUEST, params));
            return GiveawayServices.listFeaturedGiveaways(params)
                .then(
                    response => { return dispatch(success(FEATURED_GIVEAWAYS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(FEATURED_GIVEAWAYS_FAILURE, params, error)); }
                );
        };
    },

    listGiveaways: (params) => {
        return dispatch => {
            dispatch(request(GIVEAWAYS_REQUEST, params));
            return GiveawayServices.listGiveaways(params)
                .then(
                    response => { return dispatch(success(GIVEAWAYS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GIVEAWAYS_FAILURE, params, error)); }
                );
        };
    },

    getGiveawayDetails: (params) => {
        return dispatch => {
            dispatch(request(GIVEAWAY_DETAIL_REQUEST, params));
            return GiveawayServices.getGiveawayDetails(params)
                .then(
                    response => { return dispatch(success(GIVEAWAY_DETAIL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GIVEAWAY_DETAIL_FAILURE, params, error)); }
                );
        };
    },

    getGiveawayInfo: (params) => {
        return dispatch => {
            dispatch(request(GIVEAWAY_INFO_REQUEST, params));
            return GiveawayServices.getGiveawayInfo(params)
                .then(
                    response => { return dispatch(success(GIVEAWAY_INFO_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GIVEAWAY_INFO_FAILURE, params, error)); }
                );
        };
    }
};
