import { apiServices, cookieServices } from '../../_helpers';


export const RegisterServices = {

    /**
     * Service for register brand
     * @param {Object} params - The params which are used for registration api.
     * @param {string} params.name - Name of the brand 
     * @param {string} params.insta_handle - Instagram handle of the brand
     * @param {string} params.email - Email of the brand 
     * @param {string} params.mobile_number - Mobile number entered by the brand 
     * @param {string} params.password - Password entered by the brand 
     * @param {string} params.confirm_password - Confirm password entered by the brand 
     * @author Akshay 
     */
    register: (params) => {
        return apiServices.post('/influencer/register', params)
            .then((response) => {
                if (response.status === 1) {
                    let date = new Date();
                    date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                    const options = { path: '/', expires: date };
                    cookieServices.set('accessToken', response.data.token, options);
                }
				return response;
            })
    },
    /**
     * Service for sign out 
     *
     * @author Akshay 
     */
    confirmEmail: (verificationString) => {

        return apiServices.get(`/influencer/confirm-email/${verificationString}`)
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            })
    },
    /**
     * Service for sign out 
     *
     * @author Akshay 
     */
    submitNewsLetter: (params) => {

        return apiServices.post(`/influencer/newsletter/add`, params)
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            })
    },

    /**
     * Service for register brand
     * @param {Object} params - The params which are used for registration api.
     * @author Akshay 
     */
    completeProfile: (params) => {
        return apiServices.post('/influencer/complete-profile', params)
            .then((response) => {
				return response;
            })
    },

    /**
     * Service for register brand
     * @param {Object} params - The params which are used for registration api.
     * @param {string} params.name - Name of the brand 
     * @param {string} params.email - Email of the brand 
     * @param {string} params.mobile_number - Mobile number entered by the brand 
     * @param {string} params.password - Password entered by the brand 
     * @param {string} params.confirm_password - Confirm password entered by the brand 
     * @author Akshay 
     */
    completeProfileSuccess: (params) => {
        return apiServices.post('/influencer/complete-profile-success', params)
            .then((response) => {
                if (response.status === 1) {
                    let date = new Date();
                    date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                    const options = { path: '/', expires: date };
                    cookieServices.set('accessToken', response.data.token, options);
                }
				return response;
            })
    },
};
