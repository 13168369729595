import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantAction } from '../../../redux/actions';
import { APP_NAME, cookieServices } from '../../../_helpers';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { postAuthRoutes } from '../../../routes';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { NoData } from '../../../_components';
import { GeneralButton } from "../../../_components/controls";
import { CurrencySymbol } from '../../../_components/currency-symbol';
import { RestaurantCartStyled, CartDrawer, CartItem, CartItemLoader } from '.';
import { CachedRounded } from '@mui/icons-material';

function RestaurantCart() {
    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let orderId = cookieServices.get('orderId');

    /** Redux actions and state */
    const { cart_loading, cart, cart_errors, cart_total_discounted_price, cart_total_gst_price } = useSelector((state) => state.RestaurantReducer);
    const getCartList = (params) => dispatch(RestaurantAction.getCartList(params));

    const [drawer, setDrawer] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        getNext(); // Initially load participants list
        setInterval(() => {
            getCartList({ order_id: orderId });
        }, 120000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, refreshPage]);

    const getNext = async () => {
        getCartList({ order_id: orderId });
    };

    const verifyCart = async () => {
        let cartDataUpdated = await getCartList({ order_id: orderId });
        if (cartDataUpdated.status === 1)
            setDrawer(true);
    };

    return (
        <RestaurantCartStyled>
            <Helmet>
                <title>{`${APP_NAME} | ${postAuthRoutes('restaurant_cart').name}`}</title>
            </Helmet>
            <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                {(cart.length > 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack display={`flex`} direction={`row`} alignItems={'center'} justifyContent={`space-between`}>
                            <Stack>
                                <Typography variant="h5">My Order</Typography>
                                <Typography variant="caption">{`You have added ${cart.length} items`}</Typography>
                            </Stack>
                            <GeneralButton label={<CachedRounded />} onClick={() => setRefreshPage(pageRefresh => !pageRefresh)} />
                        </Stack>
                    </Grid>
                }

                {cart.length > 0 && cart.map((item, index) => {
                    return <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}><CartItem item={item} /></Grid>;
                })}
                {cart_loading && <CartItemLoader category={2} item={4} />}
                {!cart_loading && cart.length === 0 && <NoData content1={`Cart`} content2={`is Empty`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`80vh`} />}
            </Grid>
            {(cart.length > 0) &&
                <Fragment>
                    <Box className="place-order">
                        <Button variant='contained' fullWidth size="large" color='secondary' onClick={() => verifyCart()}>Place Order |  <CurrencySymbol />{cart_total_gst_price} </Button>
                    </Box>
                    <CartDrawer drawer={drawer} setDrawer={setDrawer} />
                </Fragment>
            }
        </RestaurantCartStyled>
    );
}

export { RestaurantCart };
