import { apiServices, cookieServices } from '../../_helpers';


export const UserServices = {

    /**
     * Service for get user details
     * @author Akshay 
     */
    getUserDetails: () => {
        return apiServices.get('/influencer')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },
    /**
     * Service for delete account
     *
     * @author Akshay 
     */
    updateTheme: () => {
        return apiServices.put('/influencer/update/theme')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },
    /**
     * Service for delete account
     *
     * @author Akshay 
     */
    deleteAccount: () => {
        return apiServices.put('/influencer/delete')
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },
    /**
     * Service for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the brand user
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay 
     */
    changePassword: (params) => {

        return apiServices.put('/influencer/change-password', params)
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    }

};
