import { GlobalServices } from "../services";

export const INITIAL_LOADER = 'INITIAL_LOADER';
export const PAGE_LOADER = 'PAGE_LOADER';
export const SET_DRAWER = 'SET_DRAWER';
export const SET_TOAST = 'SET_TOAST';
export const SET_THEME = 'SET_THEME';
export const SHOW_AUTH_DIALOG = 'SHOW_AUTH_DIALOG';

export const GlobalAction = {

    /**
     * The `initialLoader` function is an action creator in JavaScript. It creates an action object with
     * a type of `INITIAL_LOADER` and a payload of `loader`. The `loader` parameter is passed as an
     * argument to the function and is assigned to the `loader` property of the action object. This
     * action object can then be dispatched to the Redux store to update the state.
     */
    initialLoader: (loader) => {
        return { type: INITIAL_LOADER, loader: loader }
    },

    /**
     * The `pageLoader` function is an action creator in JavaScript. It creates an action object with a
     * type of `PAGE_LOADER` and a payload of `loader`. The `loader` parameter is passed as an argument
     * to the function and is assigned to the `loader` property of the action object. This action
     * object can then be dispatched to the Redux store to update the state.
     */
    pageLoader: (loader) => {
        return { type: PAGE_LOADER, loader: loader }
    },

    /**
     * Action for setting drawer
     * @author Akshay N
     */
    setDrawer: (payload) => {
        localStorage.setItem('is_drawer_open', payload);
        return { type: SET_DRAWER, isOpen: payload }
    },
    /**
     * Action for showing notification message
     * @author Akshay N
     */
    showToastMessage: (message) => {
        return { type: SET_TOAST, message: message }
    },

    /**
    * Action for showing authorization dialog
    * @author Akshay
    */
    showAuthDialog: (isOpen) => {
        return { type: SHOW_AUTH_DIALOG, isOpen: isOpen }
    },

    getBannerImage: () => {
        return GlobalServices.getBannerImage()
            .then(
                response => { return response; },
                error => { return error }
            );
    },
    setTheme: (mode) => {
        return { type: SET_THEME, mode: mode }
    }

};
