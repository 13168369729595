import React from 'react'
import { Link } from '@mui/material'

function AnchorLink({ href, label, props }) {
    return (
        <Link href={href} underline={'none'} fontSize={`14px`}  {...props}>{label}</Link>
    )
}

export { AnchorLink }
