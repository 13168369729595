import avatar from "../../../_root/components/avatar/avatar";
import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...avatar,
  styleOverrides: {
    ...avatar.styleOverrides,
    root: {
      ...avatar.styleOverrides.root,
      // color: colors.text.main,
      // backgroundColor: colors.text.light,
      border: `${borders.borderWidth[1]} solid ${borders.borderColor} !important`,
    }
  }
};
