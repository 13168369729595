import { Alert, styled } from "@mui/material";

const StyledAlert = styled(({ ...props }) => (<Alert {...props} />))`
        marginBottom: ${props => props.theme.spacing(1)};
        & .MuiPaper-root {
            display: flex;
            alignItems: center;
            justifyContent: center;
        }
`;

export { StyledAlert }