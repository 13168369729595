import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,
} from "../actions";

const initialState = {
    user_loading: true, user: {}, user_errors: [],
    change_password_loading: false, change_password: {}, change_password_errors: []
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::User details redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::User details redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::Change password redux */
        case CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                change_password_loading: true,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_password_loading: false,
                change_password: action.data,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                change_password_loading: false,
                change_password_errors: action.errors
            };
        }
        /** end::Change passwor redux */

        default:
            return state;
    }
}
