import { Box, Stack, Typography, Button } from "@mui/material";
import { CurrencySymbol } from '../../../../../../_components/currency-symbol';

function MenuAddOns({ add_on, selectedAddOns, handleSelectedAddOn }) {

    const { name, price, add_on_id } = add_on;
    const isSelected = selectedAddOns.some(item => item.add_on_id === add_on_id);

    const handleToggleAddOn = () => {
        handleSelectedAddOn(add_on_id, name, price);
    };

    return (
        <Stack direction="row" spacing={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%", padding: 1, border: 1, borderColor: isSelected ? "primary.main" : "grey.400", borderRadius: 1 }}>
                <Box flexGrow={1} display="flex" justifyContent="flex-start" flexWrap="wrap">
                    <Stack direction="column" spacing={1}>
                        <Typography variant="body2">{name}</Typography>
                        <Typography variant="body2"><CurrencySymbol />{price}</Typography>
                    </Stack>
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleToggleAddOn} variant="contained" color={isSelected ? "secondary" : "primary"}> {isSelected ? "Remove" : "Add"} </Button>
                </Box>
            </Box>
        </Stack>
    )

}

export { MenuAddOns }