import { Card, CardContent, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GiveawayAction } from '../../../redux/actions';
import { preAuthRoutes } from '../../../routes';
import { NoData } from '../../../_components/layout';
import { APP_NAME } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { GiveawayDetailsLoader } from '../../ad-run/giveaways';

const inputs = { title: '', description: '', image: '', url: '' };

function SharedGiveawayDetails() {

    const dispatch = useDispatch();

    const { id } = useParams();
    const [data, setData] = useState({ ...inputs });

    /** Redux actions and state */
    const { giveaway_info_loading: isLoading, giveaway_info, giveaway_info_errors } = useSelector((state) => state.GiveawayReducer);
    const getGiveawayInfo = (params) => dispatch(GiveawayAction.getGiveawayInfo(params));

    useEffect(() => {
        getGiveawayInfo({ giveaway_id: id });
        //eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (giveaway_info && Object.keys('giveaway_info').length > 0) {
            setData({
                ...data,
                title: giveaway_info.name,
                description: giveaway_info.description,
                image: giveaway_info.sample_story,
                url: window.location.href
            });

        }
        //eslint-disable-next-line
    }, [giveaway_info])

    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('shared_giveaway').name}`}</title>
                <meta name="description" content="A brief description of your page" />
                <meta property="og:title" content={data.title} />
                <meta property="og:description" content={data.description} />
                <meta property="og:image" content={data.image} />
                <meta property="og:url" content={data.url} />
            </Helmet>
            {(isLoading || Object.keys(giveaway_info).length > 0) &&
                <Card sx={{ padding: 8 }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                            {isLoading && (<GiveawayDetailsLoader />)}
                            {!isLoading && Object.keys(giveaway_info).length > 0 && (
                                <CardContent>
                                    <React.Fragment>
                                        <Box>
                                            <Typography variant={`h5`} sx={{ mb: 1 }}>{giveaway_info.name}</Typography>
                                            <Box style={{ minHeight: `60px` }}>
                                                <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{giveaway_info.description}</Typography>
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                </CardContent>
                            )
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                            {isLoading ? (
                                <Skeleton variant="rectangular" width={381} height={678} />
                            ) : (
                                <CardMedia component="img" image={giveaway_info.sample_story} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={giveaway_info.name} onError={e => { e.target.src = giveaway_info.sample_story_error }} />
                            )}
                        </Grid>
                    </Grid>
                </Card>
            }
            {!isLoading && Object.keys(giveaway_info).length === 0 && giveaway_info_errors.type === 'NOT_FOUND' && <NoData content1={`Giveaway`} content2={`Not Found`} minHeight={`55vh`} > </NoData>}
            {!isLoading && Object.keys(giveaway_info).length === 0 && giveaway_info_errors.type === 'NOT_AVAILABLE' && <NoData content1={`This Giveaway is`} content2={`Not Available`} minHeight={`55vh`} > </NoData>}
        </PreSignIn>

    );
}

export { SharedGiveawayDetails };
