import {
    GET_BRANDS_LIST_REQUEST, GET_BRANDS_LIST_SUCCESS, GET_BRANDS_LIST_FAILURE,
    GET_BRAND_DETAILS_REQUEST, GET_BRAND_DETAILS_SUCCESS, GET_BRAND_DETAILS_FAILURE
} from "../actions";

const initialState = {
    brands_loading: false, brands: [], brands_errors: {},
    brand_loading: false, brand: {}, brand_errors: {}
};

export function BrandReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::notifications redux */
        case GET_BRANDS_LIST_REQUEST: {
            return {
                ...state,
                brands_loading: true,
                brands_errors: {}
            };
        }
        case GET_BRANDS_LIST_SUCCESS: {

            let newArray = [...state.brands]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.brands.forEach((payload) => {
                const brandIndex = newArray.findIndex(brand => brand._id === payload._id);
                if (brandIndex !== -1) {
                    newArray[brandIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                brands_loading: false,
                brands_errors: {},
                brands: newArray,
            };

        }
        case GET_BRANDS_LIST_FAILURE: {
            return {
                ...state,
                brands_loading: false,
                brands_errors: action.errors,
                brands: []
            };
        }

        /** end::notifications redux */

        case GET_BRAND_DETAILS_REQUEST: {
            return {
                ...state,
                brand_loading: true,
                brand_errors: {}
            };
        }
        case GET_BRAND_DETAILS_SUCCESS: {
            return {
                ...state,
                brand_loading: false,
                brand: action.data,
                brand_errors: {}
            };
        }
        case GET_BRAND_DETAILS_FAILURE: {
            return {
                ...state,
                brand_loading: false,
                brand_errors: action.errors,
                brand: {}
            };
        }




        default:
            return state;
    }
}
