
/**
 * The function allows only alphabets, numbers, and hyphens to be entered in an input field.
 * @param {Object} e - The parameter "e" is an event object that is passed to the function when it is triggered
 * by an event, such as a keypress event. It contains information about the event, such as the type of
 * event, the target element, and the key that was pressed. In this function, the
 * @returns The function `returnAlphabetsNumbersWithHyphen` returns `true` if the input character is a
 * letter, number, or hyphen, and `false` if it is not. If the input character is not valid, the
 * function also prevents the default behavior of the event.
 * @author Akshay N
 * @created_at 22/05/2023
 */
const returnAlphabetsNumbersWithHyphen = (e) => {
    var regex = new RegExp('^[a-zA-Z0-9-]*$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
}

export { returnAlphabetsNumbersWithHyphen }
