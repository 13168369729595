import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { preAuthRoutes } from '../../../routes'
import { CardHeader, NoData } from '../../../_components'
import { APP_NAME } from '../../../_helpers'
import { PreSignIn } from '../../../_layouts/pre-signin'
import { ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { FaqAction } from '../../../redux/actions';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import Linkify from 'react-linkify';

function FrequentlyAskedQuestions() {

     // const classes = useStyles();
     const dispatch = useDispatch();
     const { search } = useLocation();
     let navigate = useNavigate();
     const searchParams = new URLSearchParams(search);

     /** Redux actions and state */
     const { faqs_loading: isLoading, faqs } = useSelector((state) => state.FaqReducer);
     const getFaqList = (params) => dispatch(FaqAction.getFaqList(params));

     const [payload, setPayload] = useState({
         limit: 100, // Number of records that are displayed when a page loads
         page: 1, // Page number to load
         search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
     });

     const getNext = async () => {
         const result = await getFaqList(payload);
         if (result.status) {
             setPayload({ ...payload });
         }
     }


     useEffect(() => {

         /* Read the payload and append the parameters to the url for proper reload */
         const queryParam = {};

        //  queryParam.p = payload.page;
         if (payload.search) queryParam.q = payload.search; else delete queryParam.q;

         navigate({
             pathname: preAuthRoutes('faq').path,
             search: `?${queryString.stringify(queryParam)}`
         });

         getNext()

         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [payload.search])


    return (

        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('faq').name}`}</title>
            </Helmet>
            <CardHeader title={preAuthRoutes('faq').name} />

            {
                faqs.map((faq, index) => {
                        return <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{faq.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>
                                            <Typography style={{ whiteSpace: 'pre-line' }}  variant="body2" paddingTop={2} paddingBottom={1}>{`${faq.answer}`}</Typography>
                                        </Linkify>
                                        <Typography>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                })
            }
            {isLoading}
            {!isLoading && faqs.length === 0 && <NoData content1={`Faqs`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`15vh`} />}


        </PreSignIn>
    )
}

export { FrequentlyAskedQuestions }