import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {
            marginTop: pxToRem(1),
        },
    },
    variants: []
};
