import tableCell from "../../../_root/components/table/tableCell";
import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...tableCell,
  styleOverrides: {
    ...tableCell.styleOverrides,
    root: {
      ...tableCell.styleOverrides.root,
      borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
    },
  },
};
