import { Card, CardContent, CardMedia, Grid } from '@mui/material'
import React, { useState } from 'react'
import { CardHeader, FormInput, SubmitButton } from '../../../_components'
import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers'
import { PreSignIn } from '../../../_layouts/pre-signin'
import contactUsImage from './contact-us.png'
import { validate } from './'
import { useDispatch } from 'react-redux'
import { ContactUsAction } from '../../../redux/actions'
import { preAuthRoutes } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Initialize form input values to null
const inputs = { name: '', insta_handle: '', email: '', mobile_number: '', message: '' };

function ContactUs() {


    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Redux reducer actions */
    const submitContactUsInfo = (params) => dispatch(ContactUsAction.submitContactUsInfo(params));


    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /**
    * function to handle input changes and modify the value
    * @param {string} e.name input name
    * @param {string} e.value input value
    * @author Akshay N
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchContactUsAction();
    }

    async function dispatchContactUsAction() {
        const isSubmitted = await submitContactUsInfo(data);
        if (isSubmitted && isSubmitted.status === 1) {
            let redirectPath = preAuthRoutes('home').path;
            navigate(redirectPath);
        }
    }



    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('contact_us').name}`}</title>
            </Helmet>
            <Card>
                <Grid container>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} >
                        <CardMedia component={`img`} image={contactUsImage} sx={{ m: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, width: `100%` }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={8} lg={8} xl={8} display={`flex`} alignItems={`center`}>
                        <CardContent sx={{ pl: 16, pr: 16 }}>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CardHeader title={preAuthRoutes('contact_us').name} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Name'
                                            name='name'
                                            value={data.name}
                                            error={action.isSubmitted && errors.name ? errors.name : ''}
                                            onChange={handleChange}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={2}
                                            label='Instagram Handle'
                                            name='insta_handle'
                                            value={data.insta_handle}
                                            error={action.isSubmitted && errors.insta_handle ? errors.insta_handle : ''}
                                            onChange={handleChange}
                                            maskType={`insta_handle`}
                                            startIcon={'@'}
                                            inputProps={{ "autoCapitalize": "none" }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Email'
                                            name='email'
                                            value={data.email}
                                            error={action.isSubmitted && errors.email ? errors.email : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={2}
                                            label='Mobile Number'
                                            name='mobile_number'
                                            value={data.mobile_number}
                                            error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                            onChange={handleChange}
                                            maskType={`digit-only`}
                                            inputProps={{ maxLength: 12 }}
                                            autoComplete={`mobile_number`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={2}
                                            label='Message'
                                            name='message'
                                            value={data.message}
                                            error={action.isSubmitted && errors.message ? errors.message : ''}
                                            onChange={handleChange}
                                            multiline
                                            maxLength={1000}
                                            minRows={6}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                        <SubmitButton color={`secondary`} sx={{ width: `250px` }} />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </PreSignIn>
    )
}

export { ContactUs }