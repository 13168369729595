import { apiServices } from '../../_helpers';

export const FaqServices = {

    /**
     * Service to get faq list
     * @author Drishya 
     */
    getFaqList: (params) => {
        return apiServices.get(`/influencer/faqs/list`, params)
            .then((response) => { return response; })
    },

};
