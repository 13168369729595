
/**
 * The base breakpoints for the Soft UI Dashboard PRO Material.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Soft UI Dashboard PRO Material using this file.
 */

import breakpoints from "../../_root/base/breakpoints";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...breakpoints
};
