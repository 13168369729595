import { apiServices } from '../../_helpers';

export const GiveawayServices = {

    /**
     * Service for list brand giveaways
     * @author Akshay 
     */
    listFeaturedGiveaways: (params) => {
        return apiServices.post(`/influencer/giveaway/featured/list`, params)
            .then((response) => { return response; })
    },
    /**
     * Service for list brand giveaways
     * @author Akshay 
     */
    listGiveaways: (params) => {
        return apiServices.post(`/influencer/giveaway/list`, params)
        .then((response) => { return response; })
    },

    /**
     * Service for list brand giveaways
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getGiveawayDetails: (params) => {
        return apiServices.post('/influencer/giveaway/detail', params)
            .then((response) => { return response; })
    },

    getGiveawayInfo: (params) => {
        return apiServices.post('/influencer/giveaway/info', params)
            .then((response) => { return response; })
    }
};
