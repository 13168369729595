import { Circle } from '@mui/icons-material'
import { Card, CardContent, Grid, Typography, Box, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import React from 'react'


// Styled header container
const StyledCardContent = styled(({ ...props }) => (<CardContent  {...props} />))`
    padding: 2.5rem !important;
    & .MuiListItem-root{
        padding: 0 15px 5px;

        & .MuiListItemIcon-root{
            min-width:20px;
        }
    }
    & .MuiSvgIcon-root {
        width: 10px;
        height: 10px;
    }
`;

const steps = [
    { step: `01`, description: `Choose the promo.` },
    { step: `02`, description: `Post the promo picture or product picture as your story.` },
    { step: `03`, description: `Tag the promo instagram handle.` },
    { step: `04`, description: `Get rewarded.` },
]

function InfluencerTutorial() {
    return (
        <Box>
            <Card >
                <StyledCardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`} pl={2}>
                            <Box >
                                <Typography variant="h5" pb={2} >How to redeem your offer</Typography>
                                <List>
                                    {
                                        steps.map((step, i) => {
                                            return (
                                                <ListItem key={i}>
                                                    <ListItemIcon ><Circle color='secondary' /></ListItemIcon>
                                                    <ListItemText ><Typography variant="body2">{step.description}</Typography></ListItemText>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                                <Typography pt={3} variant="body2">Once the influencer posts a story about their experience of using your products or services with the special offer, our app verifies if the entire process of posting your ad was successfully completed. Then, you get to choose one amongst the two types of coupon to be given to the influencer inreturn of their efforts</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/K87aFjB7Ff0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </Grid>
                    </Grid>
                </StyledCardContent>
            </Card>
        </Box>
    )
}

export { InfluencerTutorial }