import stepper from "../../../_root/components/stepper/stepper";
import linearGradient from "../../../_root/functions/linearGradient";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...stepper,
  styleOverrides: {
    ...stepper.styleOverrides,
    root: {
      ...stepper.styleOverrides.root,
      background: linearGradient(colors.gradients.info.main, colors.gradients.info.state),
      boxShadow: shadows.colored.info,

      "&.MuiPaper-root": {
        ...stepper.styleOverrides.root['&.MuiPaper-root'],
        backgroundColor: colors.transparent,
      },
    },
  },
};
