import React from 'react';
import { styled, SwipeableDrawer } from '@mui/material';

const OrderDrawerStyled = styled(({ ...props }) => <SwipeableDrawer {...props} />)`
	& .MuiPaper-root {
		border-radius: ${(props) => props.theme.borders.borderRadius.section};
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding: ${(props) => `${props.theme.spacing(4)}`};
		display: flex;
		align-items: left;
		background: ${(props) => (props.view === 'bill_issued' ? `#005600` : props.theme.palette.background.card)};
		min-height: ${(props) => `${props.theme.functions.pxToRem(75)}`};
		padding-bottom: 60px;
		& .items {
			& .MuiTableCell-root {
				padding: 5px;
			}
		}
		& .content {
			margin-top: 10px;
			& .MuiGrid-container {
				padding: 5px;
				border-bottom: 1px dashed #545454;
			}
			& .MuiGrid-item:nth-of-type(2) {
				display: flex;
				justify-content: right;
			}
		}
		& .total-amount {
			padding: 5px;
			padding-top: 15px;
			padding-bottom: 15px;
			margin-top: 10px;
			& .MuiGrid-item:nth-of-type(2) {
				display: flex;
				justify-content: right;
			}
		}

		& .action {
			width: 100%;
			padding: 20px;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		& .bill-issued {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			min-height: ${(props) => `${props.theme.functions.pxToRem(300)}`};
			& .MuiStack-root {
				& .MuiSvgIcon-root {
					& path {
						fill: #fff;
					}
				}

				& .icons{
					position: absolute;
					bottom:${(props) => `${props.theme.functions.pxToRem(24)}`};
					margin-top: ${(props) => `${props.theme.functions.pxToRem(8)}`};
					& .MuiSvgIcon-root {
						margin-right: ${(props) => `${props.theme.functions.pxToRem(8)}`};
					}
				}
			}
		}
	}
`;

export { OrderDrawerStyled };
