import { Box, styled } from '@mui/material';

const FormQuantityInputStyled = styled(({ ...props }) => <Box {...props} />)`
	& .MuiNumberInput-root {
		font-weight: 400;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}
	& .MuiNumberInput-input {
		font-size: 0.875rem;
		font-family: inherit;
		font-weight: 400;
		line-height: 1.375;
		border-radius: 8px;
		margin: 0 8px;
		padding: 10px 12px;
		outline: 0;
		min-width: 0;
		width: 4rem;
		text-align: center;

		&:hover {
		}

		&:focus {
		}

		&:focus-visible {
			outline: 0;
		}
	}

	& button {
		font-size: 0.875rem;
		box-sizing: border-box;
		line-height: 1.5;
		border: 1px solid;
		border-radius: 8px;
		width: 32px;
		height: 32px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		transition-property: all;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 120ms;

		&:hover {
			cursor: pointer;
		}

		&:focus-visible {
			outline: 0;
		}

		&.increment {
			order: 1;
		}
	}
`;

export { FormQuantityInputStyled };
