import React from 'react'
import { Button, CircularProgress } from '@mui/material'

function GeneralButton({ label = "submit", variant = "contained", size = "large", tabIndex, loading = false, isProcessing = false, disabled = false, fullWidth = false, ...props }) {
    return (
        <Button
            variant={variant}
            size={size}
            tabIndex={tabIndex}
            disabled={loading || disabled}
            startIcon={loading ? (<CircularProgress size={14} />) : ('')}
            fullWidth={fullWidth}
            {...props}
        >
            {label}
        </Button>
    )
}

export { GeneralButton }
