import tab from "../../../_root/components/tabs/tab";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...tab,
  styleOverrides: {
    ...tab.styleOverrides,
    root: {
      ...tab.styleOverrides.root,
      color: `${colors.dark.main} !important`,
    },
  },
};
