
import text from "../../../../_root/components/buttons/button/text";
import rgba from "../../../../_root/functions/rgba";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...text,
  base: {
    ...text.base,
    color: colors.text.main,

    "&:hover": {
      ...text.base['&:hover'],
    },

  },

  primary: {
    ...text.primary,
    // color: colors.primary.main,

    "&:hover": {
      ...text.primary['&:hover'],
      color: colors.primary.dark,
      backgroundColor: rgba(colors.primary.dark, '0.1')
    },

    "&:focus:not(:hover)": {
      ...text.primary['&:focus:not(:hover)'],
      color: colors.primary.dark,
      backgroundColor: rgba(colors.primary.dark, '0.1'),
    },
  },

  secondary: {
    ...text.secondary,
    color: colors.secondary.main,

    "&:hover": {
      ...text.secondary['&:hover'],
      color: colors.secondary.main,
      backgroundColor: rgba(colors.secondary.dark, '0.1')
    },

    "&:focus:not(:hover)": {
      ...text.secondary['&:focus:not(:hover)'],
      color: colors.secondary.focus,
      backgroundColor: rgba(colors.secondary.dark, '0.1'),
    },
  },
};
