import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantAction } from '../../../../../redux/actions';
import { CartDrawerStyled } from '.';
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { Box, Button, Grid, Slide, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography, capitalize } from '@mui/material';
import { cookieServices } from '../../../../../_helpers';
import { ReactComponent as portionIcon } from './../../../../../_theme/images/restaurant/portion.svg';
import { useNavigate } from 'react-router-dom';
import { postAuthRoutes } from '../../../../../routes';
import { SubmitButton } from '../../../../../_components';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';



const CartDrawer = ({ drawer = false, setDrawer }) => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [open, setOpen] = useState(drawer);
    const [response, setResponse] = useState({});
    const [view, setView] = useState('details');

    const orderId = cookieServices.get('orderId');
    const brandId = cookieServices.get('brandId');
    const tableId = cookieServices.get('tableId');

    const { cart_loading, cart_total_actual_price, cart_total_discount, cart_total_discounted_price, cart_total_gst_price, cart, gst, place_order_loading } = useSelector((state) => state.RestaurantReducer);

    const placeOrder = (params) => dispatch(RestaurantAction.placeOrder(params));
    const getCartList = (params) => dispatch(RestaurantAction.getCartList(params));
    const getOrderList = (params) => dispatch(RestaurantAction.getOrderList(params));

    useEffect(() => {
        setOpen(drawer);
    }, [drawer]);

    const toggleDrawer = (openStatus) => (event) => {
        if (event && event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) { return; }
        setDrawer(openStatus);
    };

    const handlePlaceOrder = async (e) => {

        e.preventDefault();

        let orderData = {
            order_id: orderId,
            table_id: tableId,
            brand_id: brandId,
            items: cart.map((item) => {
                let returnData = { item_id: item.item_id, cart_id: item.cart_id, item_quantity: item.item_quantity };
                if (item.have_portions) returnData.portion = item.portion_slug;
                if (item.is_add_on) returnData.is_add_on = item.is_add_on;
                if (item.add_on_by) returnData.add_on_by = item.add_on_by;
                return ({ ...returnData });
            })
        };
        let response = await placeOrder(orderData);
        setResponse(response);
    };


    useEffect(() => {

        if (response.status === 1) {
            setView('order_placed');
            setTimeout(async () => {
                navigate(postAuthRoutes('restaurant_menu').path);
                setView('details');
                getCartList({ order_id: orderId });
                getOrderList({ order_id: orderId });
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    if (cart_loading) {
        return '';
    }
    else {
        return (
            <CartDrawerStyled anchor={'bottom'} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} view={view}>
                <Slide in={view === 'details'} direction="up" >
                    <Box className={`content`}>
                        {view === 'details' && (
                            <form onSubmit={handlePlaceOrder} noValidate style={{ width: `100%` }}>
                                <Fragment>
                                    <Typography variant="h5" textAlign={`left`} >Bill Details</Typography>
                                    {gst && gst.gst_number && <Typography variant="caption" textAlign={`left`} >{`GSTIN: ${gst.gst_number}`}</Typography>}
                                    <Box className='items'>
                                        <Table>
                                            <TableHead style={{ display: `table-header-group` }}>
                                                <TableRow>
                                                    <TableCell width={`50%`} align="left">Item </TableCell>
                                                    <TableCell width={`20%`} align="center">Price </TableCell>
                                                    <TableCell width={`10%`} align="center">Qty </TableCell>
                                                    <TableCell width={`20%`} align="right">Amount </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cart.map((row) => (
                                                    <TableRow key={row.cart_id} >
                                                        <TableCell align="left">
                                                            <Typography variant='body2' style={{ textTransform: 'uppercase' }}> {`${row.title}`}</Typography>
                                                            <Typography variant='caption' color="secondary" style={{ display: `flex`, alignItems: `center`, justifyContent: `left` }}>
                                                                {row.have_portions ? <Box style={{ marginRight: `5px`, texTransform: 'uppercase' }}>{row.portion}</Box> : ``}
                                                                <Box><CurrencySymbol />{`${row.price}`}</Box>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><CurrencySymbol />{row.item_actual_discounted_price}</Box></TableCell>
                                                        <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>{row.item_quantity}</Box></TableCell>
                                                        <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{row.discounted_price}</Box></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                    <Box className='content'>
                                        <Grid container>
                                            <Grid item xs={8}> <Typography variant="h6" component={'span'}>Item total</Typography> </Grid>
                                            <Grid item xs={4}> <Typography variant="h6" component={'span'}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{cart_total_actual_price}</Box></Typography> </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={8}>Discount</Grid>
                                            <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{cart_total_discount}</Box></Grid>
                                        </Grid>
                                    </Box>
                                    <Box className='total-amount'>
                                        <Grid container>
                                            <Grid item xs={8}>AMOUNT</Grid>
                                            <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{cart_total_discounted_price}</Box></Grid>
                                        </Grid>
                                        {gst && gst.cgst && gst.sgst && (
                                            <Box className='gst-amount'>
                                                <Grid container>
                                                    <Grid item xs={8}>CGST ({gst.cgst.percent}%)</Grid>
                                                    <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{gst.cgst.amount}</Box></Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={8}>SGST ({gst.sgst.percent}%)</Grid>
                                                    <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{gst.sgst.amount}</Box></Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box className='action'>
                                        <SubmitButton label={<Fragment>Place Order | <CurrencySymbol />{cart_total_gst_price}</Fragment>} variant='contained' fullWidth size="large" color='secondary' loading={place_order_loading}/>
                                    </Box>
                                </Fragment>
                            </form>
                        )}
                    </Box>
                </Slide>
                <Slide in={view === 'order_placed'} direction="up" >
                    <Box className={`content`}>
                        {view === 'order_placed' && (
                            <Box className={`order-placed`}>
                                <Stack display={`flex`} direction={`column`} alignItems={`center`} justifyContent={`center`}>
                                    <SvgIcon component={portionIcon} viewBox="0 0 180 180" fontSize="large" />
                                    <Typography variant="h1">Hooray!</Typography>
                                    <Typography variant="body2">Your order placed</Typography>
                                </Stack>
                            </Box>
                        )}
                    </Box>
                </Slide>
            </CartDrawerStyled>
        );
    }
};

export { CartDrawer };