import typography from "../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: typography.size.sm,
    },
  },
  variants: []
};
