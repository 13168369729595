
import skelton from "../../../_root/components/skelton/skelton";
import rgba from "../../../_root/functions/rgba";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...skelton,
  styleOverrides: {
    ...skelton.styleOverrides,
    root: {
      ...skelton.styleOverrides.root,
      backgroundColor: `${rgba(colors.text.main, 0.8)}`, "&:after": { background: colors.text.main },
    },
  },
};
