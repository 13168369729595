import React from 'react';
import { styled, CardHeader } from '@mui/material';

const StyledCardHeader = styled(({ ...props }) =><CardHeader {...props} />)`
	padding: ${(props) => props.theme.spacing(1)};
	margin-bottom: ${(props) => props.theme.spacing(3)};
	padding-left: 0;
	& .MuiTypography-root {
		position: relative;
		&:after {
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			width: ${(props) => `${props.theme.functions.pxToRem(75)}`};
			height: ${(props) => `${props.theme.functions.pxToRem(2)}`};
			background: ${(props) => props.theme.palette.primary.main};
		}
	}
`;

export { StyledCardHeader };
