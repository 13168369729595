import { returnAlphabets, returnAlphabetsWithSpace, returnAlphabetsNumbersWithHyphen, returnAlphabetsNumbersWithUnderScore, returnDigitWithOneDot, returnInstagramHandle, returnDigitOnly } from ".";

/**
 * The function takes in a mask type and an event, and returns a specific type of masked input based on
 * the mask type.
 * @param {String} maskType - a string that specifies the type of mask to be applied to the input. It can be one
 * of the following: 'instagram_handle', 'alphabets-only', 'alphabets-with-space',
 * 'alphanumeric-with-hyphen', 'alphanumeric-with-underscore', 'digit-with-one
 * @param {Object} e - The parameter "e" is likely an event object that is passed to the function when it is
 * triggered by an event, such as a keypress or input change. It contains information about the event,
 * such as the target element and the value of the input. The function likely uses this information to
 * apply
 * @returns The function `maskInput` is returning the result of calling one of the helper functions
 * based on the `maskType` parameter passed to it. The specific helper function being called depends on
 * the value of `maskType`.
 * @author Akshay N
 * @created_at 22/05/2023
 */
const maskInput = (maskType, e) => {
    if (maskType === 'instagram_handle') {
        return returnInstagramHandle(e);
    } else if (maskType === 'alphabets-only') {
        return returnAlphabets(e);
    } else if (maskType === 'alphabets-with-space') {
        return returnAlphabetsWithSpace(e);
    } else if (maskType === 'alphanumeric-with-hyphen') {
        return returnAlphabetsNumbersWithHyphen(e);
    } else if (maskType === 'alphanumeric-with-underscore') {
        return returnAlphabetsNumbersWithUnderScore(e);
    } else if (maskType === 'digit-with-one-dot') {
        return returnDigitWithOneDot(e);
    } else if (maskType === 'digit-only') {
        return returnDigitOnly(e);
    }
}

export { maskInput }
