import { failure, request, success } from ".";
import { RestaurantServices } from "../services";

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const MENU_LIST_REQUEST = 'MENU_LIST_REQUEST';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_FAILURE = 'MENU_LIST_FAILURE';

export const SELECT_MENU_ITEM_REQUEST = 'SELECT_MENU_ITEM_REQUEST';
export const SELECT_MENU_ITEM_SUCCESS = 'SELECT_MENU_ITEM_SUCCESS';
export const SELECT_MENU_ITEM_FAILURE = 'SELECT_MENU_ITEM_FAILURE';

export const UNSELECT_MENU_ITEM = 'UNSELECT_MENU_ITEM';

export const ADD_ITEM_TO_CART_REQUEST = 'ADD_ITEM_TO_CART_REQUEST';
export const ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS';
export const ADD_ITEM_TO_CART_FAILURE = 'ADD_ITEM_TO_CART_FAILURE';

export const UPDATE_CART_ITEM_QUANTITY_REQUEST = 'UPDATE_CART_ITEM_QUANTITY_REQUEST';
export const UPDATE_CART_ITEM_QUANTITY_SUCCESS = 'UPDATE_CART_ITEM_QUANTITY_SUCCESS';
export const UPDATE_CART_ITEM_QUANTITY_FAILURE = 'UPDATE_CART_ITEM_QUANTITY_FAILURE';

export const REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';

export const CART_REQUEST = 'CART_REQUEST';
export const CART_SUCCESS = 'CART_SUCCESS';
export const CART_FAILURE = 'CART_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAILURE = 'ORDERS_FAILURE';

export const ORDER_BILL_REQUEST = 'ORDER_BILL_REQUEST';
export const ORDER_BILL_SUCCESS = 'ORDER_BILL_SUCCESS';
export const ORDER_BILL_FAILURE = 'ORDER_BILL_FAILURE';

export const MENU_SELECT_LIST_REQUEST = 'MENU_SELECT_LIST_REQUEST';
export const MENU_SELECT_LIST_SUCCESS = 'MENU_SELECT_LIST_SUCCESS';
export const MENU_SELECT_LIST_FAILURE = 'MENU_SELECT_LIST_FAILURE';

export const ORDER_ADDRESS_REQUEST = 'ORDER_ADDRESS_REQUEST';
export const ORDER_ADDRESS_SUCCESS = 'ORDER_ADDRESS_SUCCESS';
export const ORDER_ADDRESS_FAILURE = 'ORDER_ADDRESS_FAILURE';

export const ORDER_BILL_DETAILS_REQUEST = 'ORDER_BILL_DETAILS_REQUEST';
export const ORDER_BILL_DETAILS_SUCCESS = 'ORDER_BILL_DETAILS_SUCCESS';
export const ORDER_BILL_DETAILS_FAILURE = 'ORDER_BILL_DETAILS_FAILURE';

export const RestaurantAction = {

    sendOTP: (params) => {
        return dispatch => {
            dispatch(request(SEND_OTP_REQUEST, params));
            return RestaurantServices.sendOTP(params)
                .then(
                    response => { return dispatch(success(SEND_OTP_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SEND_OTP_FAILURE, params, error)); }
                );
        };
    },

    verifyOtp: (params) => {
        return dispatch => {
            dispatch(request(VERIFY_OTP_REQUEST, params));
            return RestaurantServices.verifyOtp(params)
                .then(
                    response => { return dispatch(success(VERIFY_OTP_SUCCESS, params, response)); },
                    error => { return dispatch(failure(VERIFY_OTP_FAILURE, params, error)); }
                );
        };
    },

    getMenuList: (params) => {
        return dispatch => {
            dispatch(request(MENU_LIST_REQUEST, params));
            return RestaurantServices.getMenuList(params)
                .then(
                    response => { return dispatch(success(MENU_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENU_LIST_FAILURE, params, error)); }
                );
        };
    },

    selectMenuItem: (params) => {
        return dispatch => {
            dispatch(request(SELECT_MENU_ITEM_REQUEST, params));
            return RestaurantServices.getItemDetails(params)
                .then(
                    response => { return dispatch(success(SELECT_MENU_ITEM_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SELECT_MENU_ITEM_FAILURE, params, error)); }
                );
        };
    },

    unselectMenuItem: (params) => {
        return dispatch => {
            dispatch(request(UNSELECT_MENU_ITEM, params));
        };
    },


    addToCart: (params) => {
        return dispatch => {
            dispatch(request(ADD_ITEM_TO_CART_REQUEST, params));
            return RestaurantServices.addToCart(params)
                .then(
                    response => { return dispatch(success(ADD_ITEM_TO_CART_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ADD_ITEM_TO_CART_FAILURE, params, error)); }
                );
        };
    },

    updateCartItemQuantity: (params) => {
        return dispatch => {
            dispatch(request(UPDATE_CART_ITEM_QUANTITY_REQUEST, params));
            return RestaurantServices.updateCartItemQuantity(params)
                .then(
                    response => { return dispatch(success(UPDATE_CART_ITEM_QUANTITY_SUCCESS, params, response)); },
                    error => { return dispatch(failure(UPDATE_CART_ITEM_QUANTITY_FAILURE, params, error)); }
                );
        };
    },

    removeItemFromCart: (params) => {
        return dispatch => {
            dispatch(request(REMOVE_CART_ITEM_REQUEST, params));
            return RestaurantServices.removeItemFromCart(params)
                .then(
                    response => { return dispatch(success(REMOVE_CART_ITEM_SUCCESS, params, response)); },
                    error => { return dispatch(failure(REMOVE_CART_ITEM_FAILURE, params, error)); }
                );
        };
    },


    getCartList: (params) => {
        return dispatch => {
            dispatch(request(CART_REQUEST, params));
            return RestaurantServices.getCartList(params)
                .then(
                    response => { return dispatch(success(CART_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CART_FAILURE, params, error)); }
                );
        };
    },

    placeOrder: (params) => {
        return dispatch => {
            dispatch(request(PLACE_ORDER_REQUEST, params));
            return RestaurantServices.placeOrder(params)
                .then(
                    response => { return dispatch(success(PLACE_ORDER_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PLACE_ORDER_FAILURE, params, error)); }
                );
        };
    },

    getOrderList: (params) => {
        return dispatch => {
            dispatch(request(ORDERS_REQUEST, params));
            return RestaurantServices.getOrderList(params)
                .then(
                    response => { return dispatch(success(ORDERS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ORDERS_FAILURE, params, error)); }
                );
        };
    },

    orderBill: (params) => {
        return dispatch => {
            dispatch(request(ORDER_BILL_REQUEST, params));
            return RestaurantServices.orderBill(params)
                .then(
                    response => { return dispatch(success(ORDER_BILL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(ORDER_BILL_FAILURE, params, error)); }
                );
        };
    },

    /**
     * Action for get category select list
     * @param {Object} params - The params which are used for the api
     * @author Naveen K
     * @created_at 15 May 2024
     */
    menuSelectList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantServices.menuSelectList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: MENU_SELECT_LIST_REQUEST, request: request } }
        function success(request, response) { return { type: MENU_SELECT_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: MENU_SELECT_LIST_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
	 * Action for add order address details
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 03 Jun 2024
	 */
    getOrderAddress: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantServices.getOrderAddress(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type:ORDER_ADDRESS_REQUEST, request: request } }
        function success(request, response) { return { type:ORDER_ADDRESS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type:ORDER_ADDRESS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for get Order bill details
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 06 Jun 2024
    */
    getOrderBillDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RestaurantServices.getOrderBillDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ORDER_BILL_DETAILS_REQUEST, request: request }; }
        function success(request, response) { return { type: ORDER_BILL_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code }; }
        function failure(request, error) { return { type: ORDER_BILL_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code }; };
    },
};