import { apiServices } from '../../_helpers';


export const ForgotPasswordServices = {

	/**
	 * Service for send password reset link 
	 * @param {Object} params - The params which are used for change password api.
	 * @param {string} params.email - Email of the brand user
	 * @author Akshay 
	 */
	sendPasswordResetLink: (params) => {

		return apiServices.post('/influencer/send-password-reset-otp', params)
			.then((response) => { return response; })
	},

	/**
	 * Service for send password reset link 
	 * @param {Object} params - The params which are used for change password api.
	 * @param {string} params.email - Email of the brand user
	 * @author Akshay 
	 */
	verifyOtp: (params) => {

		return apiServices.post('/influencer/verify-password-reset-otp', params)
			.then((response) => { return response; })
	},

	/**
	 * Service for send password reset link 
	 * @param {Object} params - The params which are used for change password api.
	 * @param {string} params.email - Email of the admin user
	 * @author Jasin
	 */
	resetPassword: (params) => {

		return apiServices.post('/influencer/reset-password', params)
			.then((response) => { return response; })
	},
}
