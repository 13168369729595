import React from 'react'
import couponSvg from './coupon-svg.svg'
import Linkify from 'react-linkify';
import { styled } from '@mui/system';
import { Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { postAuthRoutes } from '../../../../routes';



const CouponCard = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
` ;


function CouponCodesCard({ height = `150px`, isClickable = true, ...props }) {

    let navigate = useNavigate();

    const { status, promo_name, coupon, _id } = props;

    const handleClick = () => {
        if (isClickable) {
            let redirectPath = `${postAuthRoutes('story_detail').path}?sId=${_id}`;
            navigate(redirectPath);
        }
    }


    if (status === 'done') {
        return (
            <CouponCard {...props} style={{ backgroundColor: `#3a5d71`, height: height }}>
                <CardActionArea style={{ height: `100%` }} onClick={handleClick}>
                    <CardMedia style={{ position: "absolute", bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.05', margin: 4 }} component="img" image={couponSvg} />
                    <CardContent style={{ display: `flex`, alignItems: `center`, paddingBottom: 0 }}>
                        <Stack direction="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant={`h4`} color={`#fff`} style={{ cursor: "pointer" }}>{coupon.coupon_code}</Typography>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>
                                <Typography variant={`caption`} color={`#fff`} style={{ paddingTop: 3, paddingBottom: 3 }}>{coupon.description}</Typography>
                            </Linkify>
                            <Typography variant={`h6`} sx={{ fontWeight: 500 }}  >{promo_name ? promo_name : ''}</Typography>
                            <Typography variant={`caption`} sx={{ fontWeight: 500, paddingBottom: 3 }} >{`Completed on ${coupon.completed_at}`}</Typography>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </CouponCard>
        )
    } else {
        return (
            <CouponCard {...props} style={{ backgroundColor: `#403a71`, height: height }}>
                <CardActionArea style={{ height: `100%` }} onClick={handleClick}>
                    <CardMedia style={{ position: "absolute", bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.05', margin: 4 }} component="img" image={couponSvg} />
                    <CardContent style={{ display: `flex`, alignItems: `center`, paddingBottom: 0 }}>
                        <Stack direction="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant={`h4`} color={`#fff`} style={{ cursor: "pointer" }}>{`Processing`}</Typography>
                            <Typography variant={`h6`} sx={{ fontWeight: 500 }} color={`#cbcbcb`} >{promo_name}</Typography>
                            <Typography variant={`caption`} color={`#fff`} style={{ cursor: "pointer" }}>{`Dont delete your story.`}</Typography>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </CouponCard>
        )
    }
}

export { CouponCodesCard }