import {
    FEATURED_GIVEAWAYS_REQUEST, FEATURED_GIVEAWAYS_SUCCESS, FEATURED_GIVEAWAYS_FAILURE,
    GIVEAWAYS_REQUEST, GIVEAWAYS_SUCCESS, GIVEAWAYS_FAILURE,
    GIVEAWAY_DETAIL_REQUEST, GIVEAWAY_DETAIL_SUCCESS, GIVEAWAY_DETAIL_FAILURE,
    GIVEAWAY_INFO_REQUEST, GIVEAWAY_INFO_SUCCESS, GIVEAWAY_INFO_FAILURE,

} from "../actions";

const initialState = {
    featured_giveaways_loading: false, featured_giveaways: [], featured_giveaways_errors: {},
    giveaways_loading: false, giveaways: [], giveaways_errors: {},
    giveaway_loading: false, giveaway: {}, giveaway_errors: {},
    giveaway_info_loading: false, giveaway_info: {}, giveaway_info_errors: {}

};

export function GiveawayReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand giveaways redux */
        case FEATURED_GIVEAWAYS_REQUEST: {
            return {
                ...state,
                featured_giveaways_loading: true,
                featured_giveaways_errors: {}
            };
        }
        case FEATURED_GIVEAWAYS_SUCCESS: {

            let newArray = [...state.featured_giveaways]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.giveaways.forEach((payload) => {
                const giveawayIndex = newArray.findIndex(giveaways => giveaways._id === payload._id);
                if (giveawayIndex !== -1) {
                    newArray[giveawayIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                featured_giveaways_loading: false,
                featured_giveaways: newArray,
                featured_giveaways_errors: {}
            };

        }
        case FEATURED_GIVEAWAYS_FAILURE: {
            return {
                ...state,
                featured_giveaways_loading: false,
                featured_giveaways_errors: action.errors
            };
        }
        /** end::List brand giveaways redux */

        /** begin::List brand giveaways redux */
        case GIVEAWAYS_REQUEST: {
            return {
                ...state,
                giveaways_loading: true,
                giveaways_errors: {}
            };
        }
        case GIVEAWAYS_SUCCESS: {

            let newArray = [...state.giveaways]; //making a new array
            
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.giveaways.forEach((payload) => {
                const giveawayIndex = newArray.findIndex(giveaways => giveaways._id === payload._id);
                if (giveawayIndex !== -1) {
                    newArray[giveawayIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                giveaways_loading: false,
                giveaways: newArray,
                giveaways_errors: {}
            };

        }
        case GIVEAWAYS_FAILURE: {
            return {
                ...state,
                giveaways_loading: false,
                giveaways_errors: action.errors
            };
        }
        /** end::List brand giveaways redux */

        /** begin:: Details brand giveaways redux */
        case GIVEAWAY_DETAIL_REQUEST: {
            return {
                ...state,
                giveaway_loading: true,
                giveaway: {},
                giveaway_errors: {}
            };
        }
        case GIVEAWAY_DETAIL_SUCCESS: {

            let newArray = [...state.giveaways]; //making a new array
            const giveawayIndex = newArray.findIndex(giveaways => giveaways._id === action.data._id);
            if (giveawayIndex !== -1) {
                newArray[giveawayIndex] = action.data;
            } else {
                newArray = newArray.concat(action.data);
            }

            return {
                ...state,
                giveaway_loading: false,
                giveaways: newArray,
                giveaway: action.data,
                giveaway_errors: {}
            };

        }
        case GIVEAWAY_DETAIL_FAILURE: {
            return {
                ...state,
                giveaway_loading: false,
                giveaway_errors: action.errors
            };
        }
        /** end:: Details brand giveaways redux */

        /** begin:: Details brand giveaway redux */
        case GIVEAWAY_INFO_REQUEST: {
            return {
                ...state,
                giveaway_info_loading: true,
                giveaway_info: {},
                giveaway_info_errors: {}
            };
        }
        case GIVEAWAY_INFO_SUCCESS: {
            return {
                ...state,
                giveaway_info_loading: false,
                giveaway_info: action.data,
                giveaway_info_errors: {}
            };

        }
        case GIVEAWAY_INFO_FAILURE: {
            return {
                ...state,
                giveaway_info_loading: false,
                giveaway_info_errors: action.errors
            };
        }
        /** end:: Details brand giveaway redux */

        default:
            return state;
    }
}
