import autocomplete from "../../../_root/components/form/autocomplete";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  ...autocomplete,
  styleOverrides: {
    ...autocomplete.styleOverrides,
    popper: {
      ...autocomplete.styleOverrides.popper,
      color: colors.text.main,
      backgroundColor: `${colors.common.white} !important`,
      boxShadow: shadows.xs,
    },

    paper: {
      ...autocomplete.styleOverrides.paper,
      backgroundColor: colors.transparent,
    },

    option: {
      ...autocomplete.styleOverrides.option,
      color: colors.text.main,
      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        ...autocomplete.styleOverrides.option['&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus'],
        backgroundColor: colors.light.main,
        color: colors.dark.main,
      },

      '&[aria-selected="true"]': {
        ...autocomplete.styleOverrides.option['&[aria-selected="true"]'],
        backgroundColor: `${colors.light.main} !important`,
        color: `${colors.dark.main} !important`,
      },
    },

    noOptions: {
      ...autocomplete.styleOverrides.noOptions,
      color: colors.text.main,
    },

    groupLabel: {
      ...autocomplete.styleOverrides.groupLabel,
      color: colors.dark.main,
    },

    loading: {
      ...autocomplete.styleOverrides.loading,
      color: colors.text.main,
    },

    tag: {
      ...autocomplete.styleOverrides.tag,
      backgroundColor: colors.gradients.dark.state,
      color: colors.common.white,

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        ...autocomplete.styleOverrides.tag['& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus'],
        color: colors.common.white,
      },
    },


  },
};
