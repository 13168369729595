import appBar from "../../../_root/components/appbar/appBar";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...appBar,
  defaultProps: {
    ...appBar.defaultProps,
    color: `transparent`
  },

  styleOverrides: {
    ...appBar.styleOverrides,
    root: {
      ...appBar.styleOverrides.root,
    },
  },
};
