import React from 'react';
import { MenuItemStyled } from '.';
import { Box, Button, CardActions, CardContent, Skeleton } from '@mui/material';

function MenuItemLoader() {
    return (
        <MenuItemStyled>
            <Skeleton className='item-image' animation="wave" variant='circular' />
            <CardContent>
                <Skeleton variant='text' className='item-title' animation="wave" height={15} width="90%" />
                <Skeleton variant='text' className='item-description' animation="wave" height={5} width="70%" />
                <Skeleton variant='text' className='item-description' animation="wave" height={5} width="80%" />
            </CardContent>
            <CardActions>
                <Box className={`price-box`} sx={{ width: `100%` }}>
                    <Skeleton animation="wave" className='item-price' height={15} width="40%" />
                    <Skeleton animation="wave" className='item-discount-price' height={15} width="20%" />
                </Box>
                <Button variant='contained' size="large" color='secondary' className='item-add-to-cart-btn'> <Skeleton animation="wave" height={10} width="80%" /></Button>
            </CardActions>
        </MenuItemStyled>
    );
}

export { MenuItemLoader };