import React from 'react';
import { CartItemStyled } from '.';
import { CardActions, CardContent, Grid, Skeleton } from '@mui/material';

function CartItemLoader() {
	return (
		<CartItemStyled>
			<Grid container spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`}>
				<Grid item xs={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
					<Skeleton className="item-image" animation="wave" variant="circular" width={40} height={40}/>
				</Grid>
				<Grid item xs={8}>
					<CardContent>
						<Skeleton variant="text" className="item-title" animation="wave" height={10} width="90%" />
						<Skeleton variant="text" className="item-description" animation="wave" height={5} width="70%" />
						<Skeleton variant="text" className="item-description" animation="wave" height={5} width="80%" />
					</CardContent>
				</Grid>
				<Grid item xs={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
					<CardActions> </CardActions>
				</Grid>
			</Grid>
		</CartItemStyled>
	);
}

export { CartItemLoader };
