import popover from "../../../_root/components/popover/popover";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...popover,
  styleOverrides: {
    ...popover.styleOverrides,
    paper: {
      ...popover.styleOverrides.paper,
      boxShadow: shadows.xs,
      backgroundColor: colors.transparent,
    },
  },
};
