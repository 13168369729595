import { apiServices } from '../../_helpers';

export const TestimonialsServices = {

    /**
     * Service for list brand promos
     * @author Akshay 
     */
    listTestimonials: () => {
        return apiServices.get(`/influencer/testimonials/list`)
            .then((response) => { return response; })
    },

};
