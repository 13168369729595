import { cookieServices } from '../../_helpers';
import {
    SEND_OTP_REQUEST, SEND_OTP_SUCCESS, SEND_OTP_FAILURE,
    VERIFY_OTP_REQUEST, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE,
    MENU_LIST_REQUEST, MENU_LIST_SUCCESS, MENU_LIST_FAILURE,
    SELECT_MENU_ITEM_REQUEST, SELECT_MENU_ITEM_FAILURE, SELECT_MENU_ITEM_SUCCESS, UNSELECT_MENU_ITEM,
    ADD_ITEM_TO_CART_REQUEST, ADD_ITEM_TO_CART_SUCCESS, ADD_ITEM_TO_CART_FAILURE,
    CART_REQUEST, CART_SUCCESS, CART_FAILURE,
    UPDATE_CART_ITEM_QUANTITY_REQUEST, UPDATE_CART_ITEM_QUANTITY_SUCCESS, UPDATE_CART_ITEM_QUANTITY_FAILURE,
    REMOVE_CART_ITEM_REQUEST, REMOVE_CART_ITEM_SUCCESS, REMOVE_CART_ITEM_FAILURE,
    ORDERS_REQUEST, ORDERS_SUCCESS, ORDERS_FAILURE,
    PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILURE,
    ORDER_BILL_REQUEST, ORDER_BILL_SUCCESS, ORDER_BILL_FAILURE,
    MENU_SELECT_LIST_REQUEST, MENU_SELECT_LIST_SUCCESS, MENU_SELECT_LIST_FAILURE,
    ORDER_ADDRESS_REQUEST, ORDER_ADDRESS_SUCCESS, ORDER_ADDRESS_FAILURE,
    ORDER_BILL_DETAILS_REQUEST, ORDER_BILL_DETAILS_SUCCESS, ORDER_BILL_DETAILS_FAILURE,
} from '../actions';

let isLoggedIn = cookieServices.get('accessToken') ? cookieServices.get('accessToken') : false;

const initialState = isLoggedIn ? { loggedIn: true, errors: {}, password_errors: {} } : { loggedIn: false, request: {}, errors: {} };

const generalState = {
    send_otp_loading: false, send_otp: {}, send_otp_errors: {},
    verify_otp_loading: false, verify_otp: {}, verify_otp_errors: {}, order: { 'status': 'active' },
    menu_list_loading: false, menu_list: [], menu_list_errors: {},
    is_menu_item_selected: false, selected_menu_item_loading: false, selected_menu_item: {}, selected_menu_item_errors: {},
    add_item_to_cart_loading: false, add_item_to_cart: {}, add_item_to_cart_errors: {},
    cart_loading: false, cart: [], cart_errors: {}, cart_total_actual_price: 0, cart_total_discount: 0, cart_total_discounted_price: 0, cart_total_gst_price: 0, gst: {},
    update_cart_item_quantity_loading: false, update_cart_item_quantity: {}, update_cart_item_quantity_errors: {},
    remove_cart_item_loading: false, remove_cart_item: {}, remove_cart_item_errors: {},
    orders_loading: false, orders: [], orders_errors: {}, orders_total_actual_price: 0, orders_total_discounted_price: 0, orders_total_gst_price: 0, orders_total_discount: 0,
    place_order_loading: false, place_order: {}, place_order_errors: {},
    order_bill_loading: false, order_bill: {}, order_bill_errors: {},
    menu_select_list_loading: false, menu_select_list: [], menu_select_list_errors: {},
    order_address_loading: false, order_address: [], order_address_errors: {},
    order_bill_details_loading: false, order_bill_details: {}, order_bill_details_errors: {},
};

let allState = { ...initialState, ...generalState };

export function RestaurantReducer(state = allState, action) {
    switch (action.type) {
        case SEND_OTP_REQUEST: {
            return {
                ...state,
                send_otp_loading: true,
                send_otp: {},
                send_otp_errors: {}
            };
        }
        case SEND_OTP_SUCCESS: {
            return {
                ...state,
                send_otp_loading: false,
                send_otp: action.data,
                send_otp_errors: {}
            };
        }
        case SEND_OTP_FAILURE: {
            return {
                ...state,
                send_otp_loading: false,
                send_otp: {},
                send_otp_errors: action.errors
            };
        }

        case VERIFY_OTP_REQUEST: {
            return {
                ...state,
                verify_otp_loading: true,
                verify_otp: {},
                verify_otp_errors: {}
            };
        }
        case VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                verify_otp_loading: false,
                verify_otp: action.data,
                verify_otp_errors: {}
            };
        }
        case VERIFY_OTP_FAILURE: {
            return {
                ...state,
                verify_otp_loading: false,
                verify_otp: {},
                verify_otp_errors: action.errors
            };
        }

        case MENU_LIST_REQUEST: {
            return {
                ...state,
                menu_list_loading: true,
                menu_list_errors: {}
            };
        }
        case MENU_LIST_SUCCESS: {
            return {
                ...state,
                menu_list_loading: false,
                menu_list: (action.data && action.data.items) ? action.data.items: [],
                menu_list_errors: {}
            };
        }
        case MENU_LIST_FAILURE: {
            return {
                ...state,
                menu_list_loading: false,
                menu_list: [],
                menu_list_errors: action.errors
            };
        }

        case SELECT_MENU_ITEM_REQUEST: {
            return {
                ...state,
                is_menu_item_selected: true,
                selected_menu_item_loading: true,
                selected_menu_item_errors: {}
            };
        }
        case SELECT_MENU_ITEM_SUCCESS: {
            return {
                ...state,
                is_menu_item_selected: true,
                selected_menu_item_loading: false,
                selected_menu_item: action.data,
                selected_menu_item_errors: {}
            };
        }
        case SELECT_MENU_ITEM_FAILURE: {
            return {
                ...state,
                is_menu_item_selected: false,
                selected_menu_item_loading: false,
                selected_menu_item_errors: action.errors
            };
        }

        case UNSELECT_MENU_ITEM: {
            return {
                ...state,
                is_menu_item_selected: false,
                selected_menu_item_loading: false,
                selected_menu_item: {},
                selected_menu_item_errors: {}
            };
        }
        case CART_REQUEST: {
            return {
                ...state,
                cart_loading: true,
                cart_errors: {},
                cart_total_actual_price: 0,
                cart_total_discount: 0,
                cart_total_discounted_price: 0,
                cart_total_gst_price: 0,
                gst: {}
            };
        }
        case CART_SUCCESS: {

            return {
                ...state,
                cart_loading: false,
                cart: action.data.items,
                cart_errors: {},
                cart_total_actual_price: action.data.total_actual_price,
                cart_total_discount: action.data.total_discount,
                cart_total_discounted_price: action.data.total_discounted_price,
                cart_total_gst_price: action.data.total_gst_price,
                gst: action.data.gst
            };
        }
        case CART_FAILURE: {
            return {
                ...state,
                cart_loading: false,
                cart_errors: action.errors,
                cart_total_actual_price: 0,
                cart_total_discount: 0,
                cart_total_discounted_price: 0,
                cart_total_gst_price: 0,
                gst: {}
            };
        }
        case ADD_ITEM_TO_CART_REQUEST: {
            return {
                ...state,
                add_item_to_cart_loading: true,
                add_item_to_cart_errors: {}
            };
        }
        case ADD_ITEM_TO_CART_SUCCESS: {
            return {
                ...state,
                add_item_to_cart_loading: false,
                add_item_to_cart: action.data,
                add_item_to_cart_errors: {}
            };
        }
        case ADD_ITEM_TO_CART_FAILURE: {
            return {
                ...state,
                add_item_to_cart_loading: false,
                add_item_to_cart_errors: action.errors
            };
        }

        case UPDATE_CART_ITEM_QUANTITY_REQUEST: {
            return {
                ...state,
                update_cart_item_quantity_loading: true,
                update_cart_item_quantity_errors: {}
            };
        }
        case UPDATE_CART_ITEM_QUANTITY_SUCCESS: {
            return {
                ...state,
                update_cart_item_quantity_loading: false,
                update_cart_item_quantity: action.data,
                update_cart_item_quantity_errors: {}
            };
        }
        case UPDATE_CART_ITEM_QUANTITY_FAILURE: {
            return {
                ...state,
                update_cart_item_quantity_loading: false,
                update_cart_item_quantity_errors: action.errors
            };
        }

        case REMOVE_CART_ITEM_REQUEST: {
            return {
                ...state,
                remove_cart_item_loading: true,
                remove_cart_item_errors: {}
            };
        }
        case REMOVE_CART_ITEM_SUCCESS: {
            return {
                ...state,
                remove_cart_item_loading: false,
                remove_cart_item: action.data,
                remove_cart_item_errors: {}
            };
        }
        case REMOVE_CART_ITEM_FAILURE: {
            return {
                ...state,
                remove_cart_item_loading: false,
                remove_cart_item_errors: action.errors
            };
        }

        case ORDERS_REQUEST: {
            return {
                ...state,
                orders_loading: true,
                orders_errors: {},
                orders_total_actual_price: 0,
                orders_total_discounted_price: 0,
                orders_total_discount: 0,
                orders_total_gst_price: 0,
                gst: {}
            };
        }
        case ORDERS_SUCCESS: {

            return {
                ...state,
                orders_loading: false,
                orders: action.data.items,
                order: { ...state.order, status: action.data.status, status_text: action.data.status_text },
                orders_errors: {},
                orders_total_actual_price: action.data.total_actual_price,
                orders_total_discounted_price: action.data.total_discounted_price,
                orders_total_discount: action.data.total_discount,
                orders_total_gst_price: action.data.total_gst_price,
                gst: action.data.gst
            };
        }
        case ORDERS_FAILURE: {
            return {
                ...state,
                orders_loading: false,
                orders_errors: action.errors,
                orders_total_actual_price: 0,
                orders_total_discount: 0,
                orders_total_discounted_price: 0,
                orders_total_gst_price: 0,
                gst: {}
            };
        }

        /** begin::Place Order redux */
        case PLACE_ORDER_REQUEST: {
            return {
                ...state,
                place_order_loading: true,
                place_order_errors: {}
            };
        }
        case PLACE_ORDER_SUCCESS: {

            return {
                ...state,
                place_order_loading: false,
                place_order: action.data,
                place_order_errors: {}
            };

        }
        case PLACE_ORDER_FAILURE: {
            return {
                ...state,
                place_order_loading: false,
                place_order_errors: action.errors
            };
        }
        /** end::Place Order redux */

        /** begin::Order Bill redux */
        case ORDER_BILL_REQUEST: {
            return {
                ...state,
                order_bill_loading: true,
                order_bill_errors: {}
            };
        }
        case ORDER_BILL_SUCCESS: {

            return {
                ...state,
                order_bill_loading: false,
                order_bill: action.data,
                order_bill_errors: {}
            };

        }
        case ORDER_BILL_FAILURE: {
            return {
                ...state,
                order_bill_loading: false,
                order_bill_errors: action.errors
            };
        }
        /** end::Order Bill redux */

        /** begin::category select list redux */
		case MENU_SELECT_LIST_REQUEST: {
			return {
				...state,
				menu_select_list_loading: true,
				menu_select_list_errors: {}
			};
		}
		case MENU_SELECT_LIST_SUCCESS: {

			return {
				...state,
				menu_select_list_loading: false,
				menu_select_list_errors: {},
				menu_select_list: action.data

			};

		}
		case MENU_SELECT_LIST_FAILURE: {
			return {
				...state,
				menu_select_list_loading: false,
				menu_select_list_errors: action.errors,
				menu_select_list: []
			};
		}
		/** end::category select list redux */

        /** begin::get order address redux */
        case ORDER_ADDRESS_REQUEST: {
            return {
                ...state,
                order_address_loading: true,
                order_address: {},
                order_address_errors: {}
            };
        }
        case ORDER_ADDRESS_SUCCESS: {
            return {
                ...state,
                order_address_loading: false,
                order_address: action.data,
                order_address_errors: {}
            };
        }
        case ORDER_ADDRESS_FAILURE: {
            return {
                ...state,
                order_address_loading: false,
                order_address: {},
                order_address_errors: action.errors
            };
        }
        /** begin::get order address redux */

        /** begin::get order bill details redux */
		case ORDER_BILL_DETAILS_REQUEST: {
			return {
				...state,
				order_bill_details_loading: true,
				order_bill_details_errors: {},
			};
		}
		case ORDER_BILL_DETAILS_SUCCESS: {
			return {
				...state,
				order_bill_details_loading: false,
				order_bill_details: action.data,
				order_bill_details_errors: {},
			};
		}
		case ORDER_BILL_DETAILS_FAILURE: {
			return {
				...state,
				order_bill_details_loading: false,
				order_bill_details_errors: action.errors,
			};
		}
		/** end::Generate restaurant bill redux */

        default:
            return state;
    }
}
