import chip from "../../../_root/components/chip/chip";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...chip,

    colorPrimary: {
        backgroundColor: colors.primary.main,
    }
};
