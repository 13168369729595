import checkbox from "../../../_root/components/form/checkbox";
import linearGradient from "../../../_root/functions/linearGradient";
import borders from "../../base/borders";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...checkbox,
  styleOverrides: {
    ...checkbox.styleOverrides,
    root: {
      ...checkbox.styleOverrides.root,
      "& .MuiSvgIcon-root": {
        ...checkbox.styleOverrides.root['& .MuiSvgIcon-root'],
        color: colors.transparent,
        border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
      },

      "&:hover": {
        ...checkbox.styleOverrides.root['&:hover'],
        backgroundColor: colors.transparent,
      },

      "&.Mui-focusVisible": {
        ...checkbox.styleOverrides.root['&.Mui-focusVisible'],
        border: `${borders.borderWidth[2]} solid ${borders.borderColor} !important`,
      },
    },

    colorPrimary: {
      ...checkbox.styleOverrides.colorPrimary,
      color: borders.borderColor,

      "&.Mui-checked": {
        color: colors.primary.main,

        "& .MuiSvgIcon-root": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            colors.primary.main,
            colors.primary.main
          )}`,
          borderColor: colors.primary.main,
        },
      },
    },

    colorSecondary: {
      ...checkbox.styleOverrides.colorSecondary,
      color: borders.borderColor,

      "& .MuiSvgIcon-root": {
        color: colors.primary.main,
        "&.Mui-checked": {
          backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${linearGradient(
            colors.primary.main,
            colors.primary.main,
          )}`,
          borderColor: colors.primary.main,
        },
      },
    },
  },
};
