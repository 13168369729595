import { apiServices } from '../../_helpers';

export const ContactUsServices = {

    /**
     * Service to get notification list
     * @author Jasin 
     */
    submitContactUsInfo: (params) => {
        return apiServices.post(`/influencer/contact-us/submit`, params)
            .then((response) => { return response; })
    },

};
