import { 
	REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE,
	COMPLETE_PROFILE_REQUEST, COMPLETE_PROFILE_SUCCESS, COMPLETE_PROFILE_FAILURE,
	COMPLETED_PROFILE_REQUEST, COMPLETED_PROFILE_SUCCESS, COMPLETED_PROFILE_FAILURE,
} from "../actions";

const initialState = { 
	request: {}, errors: {},
	complete_profile_loading: false, complete_profile_request: {}, complete_profile: {}, complete_profile_errors: {},
	completed_profile_loading: false, completed_profile_request: {}, completed_profile_errors: {}
};

export function RegisterReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign Up redux */
		case REGISTER_REQUEST: {
			return {
				loading: true,
				request: action.request,
			};
		}
		case REGISTER_SUCCESS: {
			return {
				loading: false
			};
		}
		case REGISTER_FAILURE: {
			return {
				loading: false,
				errors: action.errors
			};
		}
		/** end::Sign Up redux */

		/** begin::Sign Up redux */
		case COMPLETE_PROFILE_REQUEST: {
			return {
				complete_profile_loading: true,
				complete_profile_request: action.request,
			};
		}
		case COMPLETE_PROFILE_SUCCESS: {
			return {
				complete_profile_loading: false,
                complete_profile: action.data,
			};
		}
		case COMPLETE_PROFILE_FAILURE: {
			return {
				complete_profile_loading: false,
				completed_profile_errors: action.errors
			};
		}
		/** end::Sign Up redux */

		/** begin::Sign Up redux */
		case COMPLETED_PROFILE_REQUEST: {
			return {
				completed_profile_loading: true,
				completed_profile_request: action.request,
			};
		}
		case COMPLETED_PROFILE_SUCCESS: {
			return {
				completed_profile_loading: false
			};
		}
		case COMPLETED_PROFILE_FAILURE: {
			return {
				completed_profile_loading: false,
				completed_profile_errors: action.errors
			};
		}
		/** end::Sign Up redux */

		default:
			return state;
	}
}
