import { makeStyles } from "@mui/styles";

const FormTelePhoneInputStyle = makeStyles((theme) => ({

    formControl: {
        borderRadius: `50%`,
    },
    label: {
        padding: `10px`,
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: `0.5px`
    },
    telePhonenputs: {
        height: `47px`,
        width: '100%',
    },

    inputClass: {
        width: '100%',
        height: '100%',
        borderRadius: '.25rem',
        border: '1px solid #5e5e5f',
        color: 'inherit',
        backgroundColor: 'inherit'
    },
    info:
    {
        marginLeft: `0!important`,
        fontSize: 10,
        marginTop: `4px`,
        padding: 0,
        color: theme.palette.text.secondary,
    }
}))

export { FormTelePhoneInputStyle };