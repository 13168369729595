// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {
            "&.Mui-disabled": {
                pointerEvent: "none",
                opacity: `0.75 !important`,
            },
        }
    },
    variants: []
}
