import { failure, request, success } from ".";
import { TestimonialsServices } from "../services";

export const TESTIMONIALS_REQUEST = 'TESTIMONIALS_REQUEST';
export const TESTIMONIALS_SUCCESS = 'TESTIMONIALS_SUCCESS';
export const TESTIMONIALS_FAILURE = 'TESTIMONIALS_FAILURE';

export const TestimonialsAction = {

    listTestimonials: () => {
        return dispatch => {
            dispatch(request(TESTIMONIALS_REQUEST, {}));
            return TestimonialsServices.listTestimonials()
                .then(
                    response => { return dispatch(success(TESTIMONIALS_SUCCESS, {}, response)); },
                    error => { return dispatch(failure(TESTIMONIALS_FAILURE, {}, error)); }
                );
        };
    }

};
