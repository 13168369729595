module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const validInstaHandleRegex = RegExp(/^[a-z0-9._]{1,30}$/i);
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter your name.';
                break;
            } case 'insta_handle': {
                errors.insta_handle = validInstaHandleRegex.test(value) ? '' : 'Enter a valid instagram handle.';
                if (value === '')
                    errors.insta_handle = 'Enter your instagram handle.';
                break;
            } case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid.';
                if (value === '')
                    errors.email = 'Enter your email address.';
                break;
            } case 'mobile_number': {
                errors.mobile_number = '';
                if (value === '')
                    errors.mobile_number = 'Enter your mobile number.';
                break;
            } case 'message': {
                errors.message = '';
                if (value === '')
                    errors.message = 'Enter your message.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}