import formLabel from "../../../_root/components/form/formLabel";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...formLabel,
  styleOverrides: {
    ...formLabel.styleOverrides,
    root: {
      ...formLabel.styleOverrides.root,
      color: colors.text.main,
    },
  },
};
