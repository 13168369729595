import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles';



const StyledDialogTitle = styled(({ ...props }) => (<DialogTitle {...props} />))`
        padding: ${props => props.theme.spacing(1)};
        margin-bottom: 25px;
        padding-left:0;
        & .MuiTypography-root {
            font-weight: 600;
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                width: 75px;
                height: 2px;
                background: #1242de;
                margin-top: 7px;
            }
        }
`;


function ModalHeader({ id, title, onClose }) {
    return (
        <StyledDialogTitle sx={{ m: 0, p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant={`h5`}>{title}</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}   >
                    <Close />
                </IconButton>
            </Stack>
        </StyledDialogTitle>
    )
}


ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
}

export { ModalHeader }
