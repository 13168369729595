import chroma from "chroma-js";

/**
 * The function converts a hexadecimal color code to its RGB equivalent.
 * @param {String} color - The color parameter is a string representing a hexadecimal color code.
 * @returns {String} The function `hexToRgb` takes a color in hexadecimal format as input and returns the
 * corresponding RGB color values as a string separated by commas.
 */
function hexToRgb(color) {
  return chroma(color).rgb().join(", ");
}

export default hexToRgb;
