import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: `${pxToRem(16)} ${pxToRem(32)} ${pxToRem(32)}`,
      '&:last-child': {
        paddingBottom: `${pxToRem(32)}`
      },
    },
  },
  variants: []
};
