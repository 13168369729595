import React from 'react'
import { Box, styled } from '@mui/material';

const SocialShareStyled = styled(({ ...props }) => (<Box {...props} />))`


    & .MuiList-root{
            padding: 8px 16px ;

        & .MuiTypography-root {
            margin: 8px 16px ;
        }
    }

`;


export { SocialShareStyled }
