import { Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { preAuthRoutes } from '../../../routes'
import { CardHeader } from '../../../_components'
import { APP_NAME } from '../../../_helpers'
import { PreSignIn } from '../../../_layouts/pre-signin'

function PrivacyPolicy() {
    return (
        <PreSignIn>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('privacy_policy').name}`}</title>
            </Helmet>
            <CardHeader title={preAuthRoutes('privacy_policy').name} />
            <Typography variant="body2" paragraph>
                This page informs you (which includes Shoppers and Merchants, each as defined in the Terms and Conditions) of our policies regarding the collection, use and disclosure of Personal Information when you use our Service. We will not use or share your information with anyone except as described in this Privacy Policy.   If a third party requests your Personal Information, we will not provide it unless you have given us permission or we are legally required to provide the information. When we are legally required to share your personal information, we will tell you in advance if we have contact information for you, unless we are legally forbidden to contact you.
            </Typography>
            <Typography variant="body2" paragraph>
                The Brands with which we work are serious about their privacy obligations to their customers.  It is important to us that we actively participate in the effort to maintain confidentiality.  We use currently standard business practice to ensure privacy and security and abide by all applicable laws.
            </Typography>
            <Typography variant="body2" paragraph>
                We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </Typography>
            <Typography variant="body2" paragraph>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.  Personally identifiable information may include, but is not limited to, your email address, name, phone number (“Personal Information”). We capture this information as part of the process of providing the Service. We may store Shopper or Merchant information (including names, e-mail addresses, purchase history and other information) to facilitate use of our Services.  We maintain reasonable and standard privacy and security protocols designed to protect this information from being viewed, obtained or access by unauthorized parties.  We do not perform sales analysis data to attempt to assess Merchant or Shopper selling or buying trends; we do review data on an aggregate basis to assist us with the operation of Ad Run Digital and to help us improve our Services.
            </Typography>
            <Typography variant="body2" paragraph>
                When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use and other statistics (“Log Data”). Please see the section regarding Location Information below regarding the use of your location information and your options.
            </Typography>
            <Typography variant="body2" paragraph>
                We may use and store information about your location depending on the permissions you have set on your device. We use this information to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at anytime, through your mobile device settings.
            </Typography>
            <Typography variant="body2" paragraph>
                When you visit the Service, we may use cookies and similar technologies like pixels, web beacons, and local storage to collect information about how you use Ad Run Digital and provide features to you. We may ask advertisers or other partners to serve ads or services to your devices, which may use cookies or similar technologies placed by us or the third party.
            </Typography>
            <Typography variant="body2" paragraph>
                We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service related services and/or to assist us in analyzing how our Service is used. These third parties have access to your Personal Information only to perform specific tasks on our behalf and are obligated not to disclose or use your information for any other purpose.  They are also obligated to follow best business practice standards in protecting Personal Information from being accessed by third-parties.
            </Typography>
            <Typography variant="body2" paragraph>
                The security of your Personal Information is important to us, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure. However, please be aware that no method of transmission over the internet is 100% secure and we are unable to guarantee the absolute security of the Personal Information we have collected from you.
            </Typography>
            <Typography variant="body2" paragraph>
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            </Typography>
            <Typography variant="body2" paragraph>
                This Privacy Policy is effective as of December 01, 2022 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
            </Typography>
        </PreSignIn>
    )
}

export { PrivacyPolicy }