import typography from "../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: typography.size.sm,
      lineHeight: typography.lineHeight.xs,

      "&.MuiInputLabel-shrink": {
        fontSize: typography.size.sm,

      },

      "& .MuiInputLabel-shrink": {
        lineHeight: typography.lineHeight.xs,
        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: typography.size.sm,
        },
      },
    },
    sizeSmall: {
      fontSize: typography.size.xs,
      "&.MuiInputLabel-shrink": {
        fontSize: typography.size.sm,
      },
    },
  },
  variants: []
};
