

/* The `const boxShadowPalette` is an object that defines different box shadow styles with varying
levels of offset, blur, spread, and opacity. Each style is assigned a key (e.g. `xs`, `sm`, `md`,
etc.) and has an object value that contains the specific values for `hOffset`, `vOffset`, `blur`,
`spread`, and `opacity`. These styles can be used to apply box shadows to different elements in a
web page. */
const boxShadowPalette = {
  xs: {
    hOffset: 0, vOffset: 2, blur: 1, spread: -1, opacity: 0.2,
    hOffsetOne: 0, vOffsetOne: 1, blurOne: 1, spreadOne: 0, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 1, blurTwo: 3, spreadTwo: 0, opacityTwo: 0.12
  },
  sm: {
    hOffset: 0, vOffset: 3, blur: 1, spread: -2, opacity: 0.2,
    hOffsetOne: 0, vOffsetOne: 2, blurOne: 2, spreadOne: 0, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 1, blurTwo: 5, spreadTwo: 0, opacityTwo: 0.12
  },
  md: {
    hOffset: 0, vOffset: 3, blur: 3, spread: -2, opacity: 0.2,
    hOffsetOne: 0, vOffsetOne: 3, blurOne: 4, spreadOne: -0, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 1, blurTwo: 8, spreadTwo: 0, opacityTwo: 0.12
  },
  lg: {
    hOffset: 0, vOffset: 2, blur: 4, spread: -1, opacity: 0.14,
    hOffsetOne: 0, vOffsetOne: 2, blurOne: 1, spreadOne: -1, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 2, blurTwo: 1, spreadTwo: -1, opacityTwo: 0.14
  },
  xl: {
    hOffset: 0, vOffset: 3, blur: 5, spread: -1, opacity: 0.14,
    hOffsetOne: 0, vOffsetOne: 2, blurOne: 1, spreadOne: -1, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 2, blurTwo: 1, spreadTwo: -1, opacityTwo: 0.14
  },
  xxl: {
    hOffset: 0, vOffset: 3, blur: 5, spread: -1, opacity: 0.14,
    hOffsetOne: 0, vOffsetOne: 2, blurOne: 1, spreadOne: -1, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 2, blurTwo: 1, spreadTwo: -1, opacityTwo: 0.14
  },
  inset: {
    hOffset: 0, vOffset: 1, blur: 2, spread: 0, opacity: 'inset',
    hOffsetOne: 0, vOffsetOne: 2, blurOne: 1, spreadOne: -1, opacityOne: 0.14,
    hOffsetTwo: 0, vOffsetTwo: 2, blurTwo: 1, spreadTwo: -1, opacityTwo: 0.14
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  boxShadowPalette: { ...boxShadowPalette },
};
