
import fab from "../../../../_root/components/buttons/button-fab/fab";
import outlined from "../button/outlined";
import contained from "./contained";
import extended from "./extended";
import root from "./root";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...fab,
  styleOverrides: {
    root: { ...root },
    contained: { ...contained.base },
    containedSizeSmall: { ...contained.small },
    containedSizeMedium: { ...contained.medium },
    containedSizeLarge: { ...contained.large },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },
    outlined: { ...outlined.base },
    outlinedSizeSmall: { ...outlined.small },
    outlinedSizeMedium: { ...outlined.medium },
    outlinedSizeLarge: { ...outlined.large },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },
    text: { ...extended.base },
    textSizeSmall: { ...extended.small },
    textSizeMedium: { ...extended.medium },
    textSizeLarge: { ...extended.large },
    textPrimary: { ...extended.primary },
    textSecondary: { ...extended.secondary },
  }
};
