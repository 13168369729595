import { failure, request, success } from ".";
import { GlobalAction } from ".";

import { SignInServices } from "../services";

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

export const SignInAction = {

    signIn: (params) => {
        return dispatch => {
            dispatch(request(SIGN_IN_REQUEST, params));
            return SignInServices.signIn(params)
                .then(
                    response => { return dispatch(success(SIGN_IN_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SIGN_IN_FAILURE, params, error)); }
                );
        };
    },

    signOut: () => {
        return dispatch => {
            dispatch(request(SIGN_OUT_REQUEST, {}));
            dispatch(GlobalAction.pageLoader(true));
            return SignInServices.signOut()
                .then(
                    response => { dispatch(dispatch(GlobalAction.pageLoader(false))); return dispatch(success(SIGN_OUT_SUCCESS, {}, response)); },
                    error => { return dispatch(failure(SIGN_OUT_FAILURE, {}, error)); }
                );
        };
    }

};
