import React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Autorenew } from '@mui/icons-material'
import { CardContent, Skeleton } from '@mui/material'

function StoryTimelineLoader() {
    return (
        <CardContent >
            <Timeline position="alternate">
                <TimelineItem >
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="h6">
                        <Skeleton animation="wave" height={30} width="40%" style={{ marginBottom: 5, float: 'right' }} />
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color='primary'><Autorenew /></TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 5 }} />
                        <Skeleton animation="wave" height={15} width="40%" style={{ marginBottom: 5 }} />
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </CardContent>
    )
}

export { StoryTimelineLoader }