import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    paper: {
      padding: pxToRem(8),
      borderRadius: borders.borderRadius.md,
    },
  },
  variants: []
};
