import React from 'react';
import { styled, Card } from '@mui/material';

const CartItemStyled = styled(({ ...props }) => <Card {...props} />)`
	border-radius: ${(props) => `${props.theme.spacing(2)}`};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 100%;
	padding: 16px;
	& .MuiCardContent-root {
		width: 100%;
		padding: 0px;
		& .item-image {
			border-radius: 50%;
			height: ${(props) => `${props.theme.functions.pxToRem(56)}`};
			width: ${(props) => `${props.theme.functions.pxToRem(56)}`};
			margin: 0;
		}

		& .price-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 8px;
			& .item-price {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			& button {
				margin-left: 8px;
			}
		}

		& .MuiNumberInput-root {
			& button {
				width: 1.5rem;
				height: 1.5rem;
				background: unset;
				border: 1px solid #fff;
				& .MuiSvgIcon-root {
					width: 1rem;
					height: 1rem;
				}
			}
			& .MuiNumberInput-input {
				width: 2rem;
				height: 1.5rem;
				margin: 0px 4px;
				padding: 5px;
				border: none;
				font-size: 12px;
			}
		}
		&:last-child {
			padding-bottom: 8px !important;
		}
	}

	& .MuiCardActions-root {
		width: 100%;
		justify-content: left;
		padding: 0;
		& .MuiButtonBase-root {
			width: 100%;
		}
	}
`;

export { CartItemStyled };
