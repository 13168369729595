import root from "../../../../_root/components/buttons/button/root";
import rgba from "../../../../_root/functions/rgba";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...root,

  "&:disabled": {
    ...root['&:disabled'],
    color: rgba(colors.text.main, '0.5'),
    border: `1px solid ${rgba(colors.text.main, '0.5')}`,
  },
};
