import {
    STORIES_REQUEST, STORIES_SUCCESS, STORIES_FAILURE,
    STORY_DETAIL_REQUEST, STORY_DETAIL_SUCCESS, STORY_DETAIL_FAILURE,
    STORY_TIMELINE_REQUEST, STORY_TIMELINE_SUCCESS, STORY_TIMELINE_FAILURE,
    STORY_PROMO_DETAIL_REQUEST, STORY_PROMO_DETAIL_SUCCESS, STORY_PROMO_DETAIL_FAILURE
} from "../actions";

const initialState = {
    stories_loading: false, stories: [], stories_errors: {},
    story_loading: true, story: {}, story_errors: {},
    story_timeline_loading: true, story_timeline: {}, story_timeline_errors: {},
    promo_loading: false, promo: {}, promo_errors: {}
};

export function StoriesReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand stories redux */
        case STORIES_REQUEST: {
            return {
                ...state,
                stories_loading: true,
                stories_errors: {}
            };
        }
        case STORIES_SUCCESS: {

            let newArray = [...state.stories]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.stories.forEach((payload) => {
                const promoIndex = newArray.findIndex(stories => stories._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });
            return {
                ...state,
                stories_loading: false,
                stories: newArray,
                stories_errors: {}
            };

        }
        case STORIES_FAILURE: {
            return {
                ...state,
                stories_loading: false,
                stories_errors: action.errors
            };
        }
        /** end::List brand stories redux */

        /** begin::List brand stories redux */
        case STORY_DETAIL_REQUEST: {
            return {
                ...state,
                story_loading: true,
                story_errors: {}
            };
        }
        case STORY_DETAIL_SUCCESS: {

            return {
                ...state,
                story_loading: false,
                story: action.data,
                story_errors: {}
            };

        }
        case STORY_DETAIL_FAILURE: {
            return {
                ...state,
                story_loading: false,
                story_errors: action.errors
            };
        }
        /** end::List brand stories redux */

        /** begin::List brand stories redux */
        case STORY_TIMELINE_REQUEST: {
            return {
                ...state,
                story_timeline_loading: true,
                story_timeline_errors: {}
            };
        }
        case STORY_TIMELINE_SUCCESS: {

            return {
                ...state,
                story_timeline_loading: false,
                story_timeline: action.data,
                story_timeline_errors: {}
            };

        }
        case STORY_TIMELINE_FAILURE: {
            return {
                ...state,
                story_timeline_loading: false,
                story_timeline_errors: action.errors
            };
        }
        /** end::List brand stories redux */

        /** begin:: Details brand promos redux */
        case STORY_PROMO_DETAIL_REQUEST: {
            return {
                ...state,
                promo_loading: true,
                promo: {},
                promo_errors: {}
            };
        }
        case STORY_PROMO_DETAIL_SUCCESS: {

            return {
                ...state,
                promo_loading: false,
                promo: action.data,
                promo_errors: {}
            };

        }
        case STORY_PROMO_DETAIL_FAILURE: {
            return {
                ...state,
                promo_loading: false,
                promo_errors: action.errors
            };
        }
        /** end:: Details brand promos redux */

        default:
            return state;
    }
}
