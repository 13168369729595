import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, PromoReducer, StoriesReducer, CouponCodesReducer, NotificationReducer, BrandReducer, TestimonialsReducer, FaqReducer, GiveawayReducer, RestaurantReducer } from './reducers';

const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });
const rootReducer = combineReducers({ GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, PromoReducer, StoriesReducer, CouponCodesReducer, NotificationReducer, BrandReducer, TestimonialsReducer, FaqReducer, GiveawayReducer, RestaurantReducer })

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);