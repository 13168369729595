import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, CardMedia, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { PromoAction } from '../../../../redux/actions';
import { PromoCard, PromoCardLoader } from '../../../ad-run/promos';
import { useNavigate } from 'react-router-dom';
import { preAuthRoutes } from '../../../../routes';
import moreImage from './more.jpg';

function FeaturedPremiumPromos() {


    // const classes = useStyles();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    /** Redux actions and state */
    const { featured_promos_loading: isLoading, featured_promos } = useSelector((state) => state.PromoReducer);
    const listFeaturedPromos = (params) => dispatch(PromoAction.listFeaturedPromos(params));

    const [payload, setPayload] = useState({
        limit: 3, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: '', // Search key to check for word anywhere in the record
        filter: {},
    });

    const getNext = async () => {
        const result = await listFeaturedPromos(payload);
        if (result.status) {
            setPayload({ ...payload });
        }
    }

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function for redirect to promos
     * @author Akshay N
     * @created_at 05 June 2022
     */
    const redirectToPromos = () => {
        navigate(preAuthRoutes('promos').path);
    }

    if (featured_promos.length === 0)
        return ''

    return (
        <Box>
            <Grid container spacing={3} direction="row" justifyContent="left" alignItems="center" >
                {
                    featured_promos.slice(0, 3).map((promo, index) => {
                        return <PromoCard key={index} promo={promo} />
                    })
                }
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                    <ButtonBase onClick={redirectToPromos}>
                        <CardMedia style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} component="img" image={moreImage} />
                    </ButtonBase>
                </Grid>
                {isLoading && <PromoCardLoader />}
            </Grid>
        </Box>
    )
}

export { FeaturedPremiumPromos }