
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {
        size: "small",
        underline: "none",
    },
    styleOverrides: {},
    variants: []
};
