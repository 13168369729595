import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: typography.fontWeightRegular,
      fontSize: typography.size.xs,
      textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${typography.fontWeightRegular} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${typography.fontWeightRegular} !important`,
      },
    },
  },
  variants: []
};
