import React, { useEffect } from 'react';
import { Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoriesAction } from '../../../../../redux/actions';
import { StoryPromoDetailsLoader } from '../';

function StoryPromoDetails() {


    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // promo id from url

    /** Redux actions and state */
    const { promo_loading: isLoading, promo } = useSelector((state) => state.StoriesReducer);
    const getStoryPromoDetails = (params) => dispatch(StoriesAction.getStoryPromoDetails(params));

    useEffect(() => {
        getStoryPromoDetails({ _id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    return (
        <Card sx={{ padding: 6, width: '100%' }}>
            {isLoading ? (
                <StoryPromoDetailsLoader />
            ) : (
                <Grid container style={{ minHeight: `330px` }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                        <CardContent >
                            <Box>
                                <Typography variant={`h5`} sx={{ mb: 1 }}>{promo.name}</Typography>
                                <Box style={{ minHeight: `60px` }}>
                                    <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{promo.description}</Typography>
                                </Box>
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${promo.insta_handle}`}</Typography>
                                <Divider />
                                <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Brand - ${promo.brand_name}`}</Typography>
                                <Typography variant={`caption`} sx={{ fontWeight: 500 }} mt={2}>{`${promo.promo_start_date} - ${promo.promo_end_date}`}</Typography>
                            </Box>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                            <CardMedia component="img" image={promo.file} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={promo.name} onError={e => { e.target.src = promo.file_error }} />
                    </Grid>
                </Grid>
            )}
        </Card>
    )
}

export { StoryPromoDetails }