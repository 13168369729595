
/**
 * The function allows only digits, one dot, and one plus or minus sign to be entered in an input
 * field.
 * @param {Object} e - The parameter "e" is an event object that is passed to the function when it is triggered
 * by an event, such as a key press. It contains information about the event, such as the key code of
 * the pressed key.
 * @returns The function `returnDigitWithOneDot` returns a boolean value (`true` or `false`) based on
 * the conditions specified in the code. It returns `true` if the key pressed is a number between 0 and
 * 9, or if it is the plus sign (+) or minus sign (-) and they have not already been entered. It
 * returns `false` if the key pressed is
 * @author Akshay N
 * @created_at 22/05/2023
 */
const returnDigitWithOneDot = (e) => {
    var keyCodeEntered = e.which
        ? e.which
        : window.e.keyCode
            ? window.e.keyCode
            : -1;
    if (keyCodeEntered >= 48 && keyCodeEntered <= 57) {
        return true;
    } else if (keyCodeEntered === 43) {
        if (e.value && e.value.indexOf('+') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    } else if (keyCodeEntered === 45) {
        if (e.value && e.value.indexOf('-') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    } else if (keyCodeEntered === 46) {
        if (e.value && e.value.indexOf('.') >= 0) {
            e.preventDefault();
            return false;
        } else return true;
    }
    e.preventDefault();
    return false;
}

export { returnDigitWithOneDot }
