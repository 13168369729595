import rating from "../../../_root/components/form/rating";
import rgba from "../../../_root/functions/rgba";
import colors from "../../base/colors";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...rating,
    styleOverrides: {
        ...rating.styleOverrides,
        root: {
            "&.Mui-disabled": {
                ...rating.styleOverrides.root['&.Mui-disabled'],
                color: rgba(colors.text.main, '0.5'),
                "& .MuiSvgIcon-root": {
                    color: rgba(colors.text.main, '0.5'),
                }
            },
        },
    }
}
