import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

function RestaurantAuthLoader() {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className='wrapper'>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className='wrapper-grid' >
                <Box display="flex" alignItems="center">
                    <Box className={`wrapper-box`} >
                        <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" >
                            <Grid item xs={12} md={12}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Skeleton animation="wave" height={75} width={75} />
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Skeleton animation="wave" height={20} width="50%" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Skeleton animation="wave" height={10} width="100%" />
                                <Skeleton animation="wave" height={10} width="70%" />
                                <Skeleton animation="wave" height={10} width="85%" />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export { RestaurantAuthLoader };