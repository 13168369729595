import stepConnector from "../../../_root/components/stepper/stepConnector";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...stepConnector,
  styleOverrides: {
    ...stepConnector.styleOverrides,
    root: {
      ...stepConnector.styleOverrides.root,
      color: colors.primary.main,
      "&.Mui-active": {
        ...stepConnector.styleOverrides.root['&.Mui-active'],
        color: colors.common.white,
      },
      "&.Mui-completed": {
        ...stepConnector.styleOverrides.root['&.Mui-completed'],
        color: colors.common.white,
      },
    },

  },
};
