import input from "../../../_root/components/form/input";
import borders from "../../base/borders";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...input,
  styleOverrides: {
    ...input.styleOverrides,
    root: {
      ...input.styleOverrides.root,
      color: colors.text.main,
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
      },
      "&:before": {
        borderColor: colors.grey[300],
      },
      "&:after": {
        borderColor: colors.info.main,
      },

    },
  },
};
