import { Box, Button, CardMedia, Stack, SvgIcon, Typography, Slide, IconButton, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantAction } from '../../../../../redux/actions';
import { MenuDrawerStyled } from '.';
import { ReactComponent as NonVegIcon } from './../../../../../_theme/images/restaurant/non-veg-icon.svg';
import { ReactComponent as VegIcon } from './../../../../../_theme/images/restaurant/veg-icon.svg';
import { ReactComponent as portionIcon } from './../../../../../_theme/images/restaurant/portion.svg';
import { useState } from 'react';
import { Fragment } from 'react';
import { cookieServices, handleInputChange, validateForm } from '../../../../../_helpers';
import { FormQuantityInput } from '../../../../../_components/form';
import { SubmitButton } from '../../../../../_components';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { MenuAddOns } from './menu-add-ons';


const initialPayload = { order_id: '', item_quantity: 1, price: 0 };

const MenuDrawer = () => {

    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    let orderId = cookieServices.get('orderId');
    let tableId = cookieServices.get('tableId');
    let brandId = cookieServices.get('brandId');
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const unselectMenuItem = (params) => dispatch(RestaurantAction.unselectMenuItem(params));
    const addToCart = (params) => dispatch(RestaurantAction.addToCart(params));
    const getCartList = (params) => dispatch(RestaurantAction.getCartList(params));
    const { add_item_to_cart_loading, add_item_to_cart_errors } = useSelector((state) => state.RestaurantReducer);

    /** Redux reducer states */
    const { is_menu_item_selected, selected_menu_item_loading, selected_menu_item } = useSelector((state) => state.RestaurantReducer);

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ ...initialPayload, order_id: orderId, addOns: [] });
    const [action, setAction] = useState({ isSubmitted: false });
    const [response, setResponse] = useState({});
    const [errors, setErrors] = useState({ ...initialPayload });
    const [selectedAddOns, setSelectedAddOns] = useState([]);

    const [itemPrice, setItemPrice] = useState({ price: 0, discount: 0, discounted_price: 0 });

    const [view, setView] = useState('details');

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) { return; }
        unselectMenuItem(open);
        setView('details');
    };

    /**
      * function to handle input changes and alter the value
      * @param object e input object with name and value
      * @author Akshay N
      * @created_at 30 May 2022
      */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        payload[name] = value;
        setPayload({ ...payload });
        calculatePrice();
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchAddToCartAction();
    }

    const dispatchAddToCartAction = async () => {
        let response = await addToCart(payload);
        setResponse(response);
    };

    const handleSelectedAddOn = (id, name, price) => {
        setSelectedAddOns((prevAddOns) => {
            const isAlreadyAdded = prevAddOns.some((item) => item.add_on_id === id);
            let updatedAddOns;
            if (isAlreadyAdded) {
                updatedAddOns = prevAddOns.filter((item) => item.add_on_id !== id);
            } else {
                updatedAddOns = [...prevAddOns, { add_on_id: id, name, price }];
            }
            setPayload({...payload, addOns: updatedAddOns});
            return updatedAddOns;
        });

    };

    useEffect(() => {
        setPayload({...payload, addOns: selectedAddOns});
        calculatePrice();
    }, [selectedAddOns]);

    useEffect(() => {

        if (response.status === 1) {
            setView('added_to_cart');
            setTimeout(async () => {
                await getCartList({ order_id: orderId });
                await unselectMenuItem(false);
                setView('details');
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);


    useEffect(() => {
        if (is_menu_item_selected && selected_menu_item !== undefined) {
            let selectedItemPayload = { ...payload, item_quantity: 1, price: selected_menu_item.discounted_price, item_id: selected_menu_item._id };
            if (selected_menu_item.have_portions === true) {
                const initialPortion = selected_menu_item.portions.reduce((acc, curr) => parseFloat(curr.price) > parseFloat(acc.price) ? curr : acc).slug;
                selectedItemPayload.portion = `${initialPortion}`;
            }
            if (selected_menu_item.has_add_ons) {
                selectedItemPayload.addOns = selectedAddOns;
            }
            setPayload(selectedItemPayload);
            setItemPrice({ price: selected_menu_item.price, discount: selected_menu_item.discount, discounted_price: selected_menu_item.discounted_price });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_menu_item_selected, selected_menu_item]);

    const calculatePrice = () => {
        let currentPrice = selected_menu_item.discounted_price;
        setItemPrice({ price: selected_menu_item.price, discount: selected_menu_item.discount, discounted_price: selected_menu_item.discounted_price });
        if (selected_menu_item.have_portions) {
            let selectedPortion = selected_menu_item.portions.find(x => x.slug === payload.portion);
            currentPrice = selectedPortion.discounted_price;
            setItemPrice({ price: selectedPortion.price, discount: selectedPortion.discount, discounted_price: selectedPortion.discounted_price });
        }
        let quantity = payload.item_quantity;
        let priceCalculated = quantity * currentPrice;
        if (selected_menu_item.has_add_ons) {
            const addOnPrices = payload.addOns.reduce((total, addOn) => total + addOn.price, 0);
            priceCalculated += addOnPrices;
        }
        setPayload({ ...payload, price: priceCalculated });
    };

    if (is_menu_item_selected) {
        if (selected_menu_item_loading)
            return '';
        else
            return (
                <MenuDrawerStyled open={is_menu_item_selected} onClose={toggleDrawer(false)} onOpen={toggleDrawer(false)} view={view}>
                    <Slide in={view === 'details'} direction="up" >
                        <Box className={`content`}>
                            {view === 'details' && (
                                <Fragment>
                                    <CardMedia className='item-image' component="img" image={selected_menu_item.image} />
                                    <Stack direction="row" spacing={2} justifyContent={`space-between`}>
                                        <Box className="item-details-left">
                                            <Box className="item-title-wrapper">
                                                {selected_menu_item.is_non ? <SvgIcon component={NonVegIcon} viewBox="0 0 180 180" fontSize="small" /> : <SvgIcon component={VegIcon} viewBox="0 0 180 180" fontSize="small" />}
                                                <Typography className='item-title' variant="h6">{selected_menu_item.title}</Typography>
                                            </Box>
                                            <Box className={`price-box`}>
                                                <Typography variant="h6" className='item-price'><CurrencySymbol />{selected_menu_item.discounted_price}</Typography>
                                                {selected_menu_item.discount > 0 && <Typography variant="h6" className='item-discount-price' color={`error`} ><CurrencySymbol />{selected_menu_item.price}</Typography>}
                                            </Box>
                                        </Box>
                                        <Box className="item-details-right">
                                            <Button variant='contained' size="large" color='secondary' onClick={() => setView('customize')}>Add</Button>
                                        </Box>
                                    </Stack>
                                    <Box>
                                        <Typography className='item-description' gutterBottom variant="h6">{selected_menu_item.description}</Typography>
                                    </Box>
                                </Fragment>
                            )}
                        </Box>
                    </Slide>
                    <Slide in={view === 'customize'} direction="up" >
                        <Box className={`content`}>
                            {view === 'customize' && (
                                <form onSubmit={handleSubmit} noValidate style={{ width: `100%` }}>
                                    <Stack spacing={1} justifyContent={`space-between`} style={{ padding: 0 }}>
                                        <Stack direction="row" spacing={2} justifyContent={`space-between`} style={{ padding: 0 }}>
                                            <Box className="item-details-left">
                                                <Typography className='item-title' variant="h6">Customize as per your taste</Typography>
                                                <Box className="item-title-wrapper">
                                                    {selected_menu_item.is_non ? <SvgIcon component={NonVegIcon} viewBox="0 0 180 180" fontSize="small" /> : <SvgIcon component={VegIcon} viewBox="0 0 180 180" fontSize="small" />}
                                                    <Typography className='item-title' variant="h6">{selected_menu_item.title}</Typography>&nbsp;|&nbsp;
                                                    <Box className={`price-box`}>
                                                        <Typography variant="h6" className='item-price'><CurrencySymbol />{itemPrice.discounted_price}</Typography>
                                                        {itemPrice.discount > 0 && <Typography variant="h6" className='item-discount-price' color={`error`} ><CurrencySymbol />{itemPrice.price}</Typography>}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <CardMedia className='item-image' component="img" image={selected_menu_item.image} />
                                        <Box>
                                            {selected_menu_item.have_portions && selected_menu_item.portions.length > 0 && (
                                                <RadioGroup aria-label="portion" name="portion" value={payload.portion} onChange={handleChange} row className='portions' defaultValue="full_portion">
                                                    {selected_menu_item.portions.map((portion, i) => {
                                                        return <FormControlLabel key={i} value={portion.slug} className={`${portion.slug}`} control={<Radio icon={<SvgIcon component={portionIcon} viewBox="0 0 180 180" fontSize="large" />} checkedIcon={<SvgIcon component={portionIcon} viewBox="0 0 180 180" fontSize="large" />} />} label={portion.portion} labelPlacement="bottom" />;
                                                    })}
                                                </RadioGroup>
                                            )}
                                        </Box>
                                        <Box>
                                            {selected_menu_item.has_add_ons && selected_menu_item.add_ons.length > 0 && (
                                                <Box>
                                                    <Typography className='add-title' variant="h6">Add Ons</Typography>
                                                    <Box sx={{maxHeight: selected_menu_item.add_ons.length > 3 ? 250 : 'auto', overflowY: selected_menu_item.add_ons.length > 3 ? 'scroll' : 'unset'}}>
                                                        {selected_menu_item.add_ons.map((add_on, i) => {
                                                            return (
                                                                <Box>
                                                                    <MenuAddOns add_on={add_on} selectedAddOns={selectedAddOns} handleSelectedAddOn={handleSelectedAddOn}/>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                        <FormQuantityInput name="item_quantity" value={payload.item_quantity} onChange={handleChange} />
                                        <Box className="item-details-right">
                                            <SubmitButton label={<Fragment>Add Item | <CurrencySymbol /> {payload.price}</Fragment>} color='secondary' loading={add_item_to_cart_loading} fullWidth />
                                        </Box>
                                    </Stack>
                                </form>
                            )}
                        </Box>
                    </Slide>
                    <Slide in={view === 'added_to_cart'} direction="up" >
                        <Box className={`content`}>
                            {view === 'added_to_cart' && (
                                <Box className={`added-to-cart`}>
                                    <Stack display={`flex`} direction={`column`} alignItems={`center`} justifyContent={`center`}>
                                        <SvgIcon component={portionIcon} viewBox="0 0 180 180" fontSize="large" />
                                        <Typography variant="h1">Hooray!</Typography>
                                        <Typography variant="body2">{selected_menu_item.title}  added to your cart</Typography>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    </Slide>
                </MenuDrawerStyled>
            );
    }
};

export { MenuDrawer };