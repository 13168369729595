import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import ErrorImage from './404.svg'
import { NoDataStyled } from '.'

function NoData({ content1, content2, description, image = ErrorImage, showImage = true, children, ...props }) {

    return (
        <NoDataStyled  {...props} >
            <Grid item xs={4}>
                <Stack direction="column" justifyContent="center" alignItems="center"   >
                    {showImage && <img src={image} alt="No data found" />}
                    <Typography variant="h1" className='content-1'>{content1} <span className='content-2'>{content2}</span></Typography>
                    <Typography component="h1" variant="caption" mb={2} align='center'>{description}</Typography>
                    {children}
                </Stack>
            </Grid>
        </NoDataStyled>
    )
}


export { NoData };
