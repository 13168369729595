import { Box, CardContent, Divider, Grid, Skeleton } from '@mui/material'
import React from 'react'

function StoryPromoDetailsLoader() {
    return (
        <Grid container style={{ minHeight: `330px` }}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                <CardContent style={{ width: `100%` }} >
                    <Box style={{ width: `100%` }}>
                        <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 5 }} />
                        <Box style={{ minHeight: `60px` }}>
                            <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                            <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                            <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                        </Box>
                        <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                        <Divider sx={{ mt: 5, }} />
                        <Box>
                            <Skeleton animation="wave" height={20} width="60%" style={{ marginBottom: 5 }} />
                            <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                        </Box>
                    </Box>
                </CardContent>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                <Skeleton variant="rectangular" width={180} height={320} style={{ borderRadius: '0.75rem' }} />
            </Grid>
        </Grid>
    )
}

export { StoryPromoDetailsLoader }