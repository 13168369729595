import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalAction } from '../../../redux/actions';
import { Button, Stack } from '@mui/material';
import { Modal } from '../../../_components';
import { Login, Register, ForgotPassword, VerifyOtp, ResetPassword } from '.';
import { useLocation } from 'react-router-dom';

function Auth() {

    /** Initialize hooks, plugins and constants */
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux reducer actions */
    const showAuthForm = (isDialogOpen) => dispatch(GlobalAction.showAuthDialog(isDialogOpen));

    /** Redux reducer states */
    const { showAuthDialog: isDialogOpen } = useSelector((state) => state.GlobalReducer);

    /** Initialize and declare state */
    const [open, setOpen] = useState(false);
    const [authForm, setAuthForm] = useState('login');
    const [forgotPasswordData, setForgotPasswordData] = useState({ otp: '', email: '' });

    const handleOpen = (type) => {
        setAuthForm(type);
        showAuthForm(true);
    };

    const handleClose = () => {
        setOpen(false);
        showAuthForm(false);
    };

    const backToComponent = (component, data = { otp: '', email: '' }) => {
        setAuthForm(component);
        setForgotPasswordData({ ...forgotPasswordData, email: data.email, otp: data.otp });
    }

    useEffect(() => {

        const refId = searchParams.get('ref_ig') ?? null;
        if (refId) {
            setOpen(true);
            setAuthForm('register');
        } else {
            setOpen(isDialogOpen);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen])

    let view = '';
    if (authForm === 'login') {
        view = <Login backTo={backToComponent} onClose={handleClose} />
    } else if (authForm === 'register') {
        view = <Register backTo={backToComponent} onClose={handleClose} />
    } else if (authForm === 'forgot_password') {
        view = <ForgotPassword backTo={backToComponent} onClose={handleClose} />
    } else if (authForm === 'verify_otp') {
        view = <VerifyOtp backTo={backToComponent} onClose={handleClose} forgotPasswordData={forgotPasswordData} />
    } else if (authForm === 'reset_password') {
        view = <ResetPassword backTo={backToComponent} onClose={handleClose} forgotPasswordData={forgotPasswordData} />
    }

    return (
        <React.Fragment>
            <Modal maxWidth={`sm`} isDialogOpen={open} onClose={handleClose} >{view}</Modal>
            <Stack direction={`row`} spacing={1}>
                <Button variant="outlined" onClick={() => handleOpen('register')}>Register</Button>
                <Button variant="contained" color='secondary' onClick={() => handleOpen('login')}>Login</Button>
            </Stack>
        </React.Fragment>
    );
}

export { Auth };
