import React from 'react';
import { styled, Grid } from '@mui/material';

const MenuCategoryStyled = styled(({ ...props }) => <Grid {...props} />)`
	& .category-title {
		margin-bottom: ${(props) => `${props.theme.spacing(1)}`};
		text-transform: capitalize;
		padding-left: ${(props) => `${props.theme.spacing(1)}`};
	}
`;

export { MenuCategoryStyled };
