import tableContainer from "../../../_root/components/table/tableContainer";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...tableContainer,
  styleOverrides: {
    ...tableContainer.styleOverrides,
    root: {
      ...tableContainer.styleOverrides.root,
      backgroundColor: colors.common.white,
      boxShadow: shadows.xs,
    },
  },
};
