import { Home, Person } from "@mui/icons-material";
import { CouponCodes } from "../app/ad-run";
import { GiveawayDetails, Giveaways } from "../app/ad-run/giveaways";
import { PromoDetails, Promos } from "../app/ad-run/promos";
import { StoriesDetail } from "../app/ad-run/stories";
import { RestaurantCart, RestaurantMenu, RestaurantOrder } from "../app/restaurant";
import { ErrorElement } from "../_components";

const allRoutes = {

    'promos': {
        path: "/promos",
        name: "Promos",
        element: <Promos />,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        isMainElement: true,
        errorElement: <ErrorElement />,
        menuPermission: ''
    },
    'promo': {
        path: "/promos/detail",
        name: "Promo Details",
        element: <PromoDetails />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'giveaways': {
        path: "/giveaways",
        name: "Giveaways",
        element: <Giveaways />,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'giveaway': {
        path: "/giveaways/detail",
        name: "Giveaways Details",
        element: <GiveawayDetails />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'giveaways',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    // 'stories': {
    //     path: "/stories",
    //     name: "My stories",
    //     element: Stories,
    //     layout: "/post-auth",
    //     isMenuItem: true,
    //     menuIcon: Home,
    //     module: 'stories',
    //     isModuleMenuItem: true,
    //     isMainComponent: true
    // },
    'story_detail': {
        path: "/stories/detail",
        name: "Promo Details",
        element: <StoriesDetail />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'promos',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'coupon_codes': {
        path: "/coupon-codes",
        name: "Coupon Codes",
        element: <CouponCodes />,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'stories',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        element: <Home />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,
    },
    /** begin::change-password module */
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        layout: "/post-auth",
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::change-password module */

    /** begin::account status module */
    'account_status': {
        path: "/profile/status",
        name: "Account Status",
        layout: "/post-auth",
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::account status module */


    'restaurant_menu': {
        path: "/restaurant/menu",
        name: "Menu",
        element: <RestaurantMenu />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false,
        module: 'restaurant',
    },

    'restaurant_cart': {
        path: "/restaurant/cart",
        name: "Cart",
        element: <RestaurantCart />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false,
        module: 'restaurant',
    },
    'restaurant_order': {
        path: "/restaurant/order",
        name: "Order",
        element: <RestaurantOrder />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false,
        module: 'restaurant',
    }

};



/**
 * The function returns all routes or a specific set of routes based on the key type provided.
 * @param {String} [keyType=null] The parameter `keyType` is an optional parameter that can be passed to
 * the `postAuthRoutes` function. If a `keyType` is provided, the function will return only the routes associated
 * with that `keyType` from the `allRoutes` object.
 * @return {Object} The function takes an optional parameter `keyType` and returns either all the routes in the
 * `allRoutes` object if `keyType` is not provided or the routes corresponding to the `keyType` if it is provided.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const postAuthRoutes = (keyType = null) => {
    return keyType ? allRoutes[keyType] : allRoutes;
};

/**
 * This function filters all routes based on whether they are menu items or module menu items with a  specific module,
 * and returns the filtered routes with modified paths.
 * @param {String} [module=false] - The `module` parameter is an optional string parameter that specifies the name of
 * a module.  If provided, the function will filter the routes to only include those that have `isModuleMenuItem` set to
 * `true` and whose `module` property matches the provided `module` string.
 * @returns {Object} The function `postAuthMenuRoutes` is returning an array of route objects that have either `isMenuItem`
 * or `isModuleMenuItem` properties set to `true`.If the `module` parameter is provided, it filters the routes to only
 * include those with `isModuleMenuItem` set to `true` and matching the  provided `module` name.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuRoutes = (module = false) => {
    /* The below code is a JavaScript function that filters an array of objects representing routes.
    The function takes an optional argument `module`, which is used to filter the routes based on
    whether they are module menu items or regular menu items. */
    return Object.values(allRoutes).filter(item => {
        return !module
            ? (item.isMenuItem === true)
            : (item.isModuleMenuItem === true && item.module === module)
                ? { ...item, path: item.path.split("/").pop() }
                : null;
    });
};

/**
 * The function creates an array of post-authentication menu bar routes by iterating over an object of routes
 * and organizing them based on their parent routes.
 * @param {String} [module=false] - This is the module name. If you want to get all the menu items, then pass
 * false. If you want to get all the menu items for a specific module, then pass the module name.
 * @return {Array} The function `postAuthMenuBarRoutes` is returning an array of objects that represent the menu bar
 * routes for a post-authentication user.  The objects in the array contain information about each route, such as
 * the route's name, path, and whether it is a child of another route. The function iterates over an input object called
 * `allRoutes`, filters out routes that are not menu items, and organizes the remaining routes into return array.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuBarRoutes = () => {

    const postSignInRoutes = allRoutes;

    let postRoutes = [];

    // Iterating over the object.
    for (const key in postSignInRoutes) {

        // Checking if the object has the property.
        if (postSignInRoutes.hasOwnProperty(key)) {
            if (postSignInRoutes[key].isMenuItem) {

                // Checking if the route has a parent route.
                if (postSignInRoutes[key].parentRoute) {

                    // Finding the index of the parent route in the array of objects.
                    const parentRoute = postSignInRoutes[key].parentRoute;
                    var postRouteIndex = postRoutes.findIndex(function (route) {
                        return route.slug === parentRoute;
                    });

                    // Checking if the parent route has any children. If not, it is creating an empty array
                    // and pushing the child route into it. */
                    if (!postRoutes[postRouteIndex]['children'])
                        postRoutes[postRouteIndex]['children'] = [];

                    postRoutes[postRouteIndex]['children'].push({ ...postSignInRoutes[key], slug: key });

                } else
                    postRoutes.push({ ...postSignInRoutes[key], slug: key });
            }
        }
    }

    return postRoutes;
};

/**
 * This function returns the name of a post element based on its path.
 * @param {String} path - The `path` parameter is a string representing the URL path of a route in a web application.
 * @returns {String} The function `getPostElementNameByPath` returns the name of the element associated with the
 * given path, which is obtained by searching for the path in the `allRoutes` object and returning the
 * corresponding `name` property. If no matching path is found, an empty string is returned.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const getPostElementNameByPath = (path) => {
    if (path) {
        const elementItem = Object.values(allRoutes).find(item => item.path === path);
        return elementItem ? elementItem.name : '';
    }
};

export { postAuthRoutes, postAuthMenuRoutes, postAuthMenuBarRoutes, getPostElementNameByPath };
