import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        '& .MuiList-root': {
            padding: 6,
            minWidth: 175
        },
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiDivider-root': {
            marginTop: 3,
            marginBottom: 3
        }
    }
}))

export { useStyles };

