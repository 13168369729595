import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { RestaurantAction } from '../../../redux/actions';
import { Grid, SpeedDial, SpeedDialAction, SpeedDialIcon, Chip, Stack, styled } from '@mui/material';
import { NoData } from '../../../_components';
import { FormSearchInput, FormToggleSearchInput } from '../../../_components/form';
import { CardHeader } from '../../../_components/card';
import { useLocation } from 'react-router-dom';
import { postAuthRoutes } from '../../../routes';
import queryString from 'query-string';
import { MenuCategory, MenuCategoryLoader } from './';
import { Helmet } from 'react-helmet';
import { APP_NAME, cookieServices } from '../../../_helpers';
import { MenuDrawer } from './_component/menu-drawer';



const SpeedDialStyled = styled(({ open, ...props }) => <SpeedDial {...props} open={open}/>)`
	& .MuiButtonBase-root {
		width: ${(props) => props.theme.spacing(6)};
		height: ${(props) => props.theme.spacing(6)};
		font-size:12px;
	}
	& .MuiSpeedDial-actions {
		& .MuiButtonBase-root {
			width: ${(props) => props.theme.spacing(10)};
			height: ${(props) => props.theme.spacing(0)};
			font-size:12px;
			background:none;
			box-shadow:none;
			justify-content:  right;
		}
	}
`;


function speedDialRenderIcon(menu_select_list, applyFilter, speedDialOpen, setSpeedDialOpen) {
	return <SpeedDialStyled ariaLabel="SpeedDial" sx={{ position: 'fixed', bottom: 20, right: 10 }} icon={<SpeedDialIcon edge="end" direction="up" style={{ fontSize: '22px' }} />} onClose={() => setSpeedDialOpen(false)} onOpen={() => setSpeedDialOpen(true)} open={speedDialOpen} >
		{menu_select_list.map((action) => (
			<SpeedDialAction key={action.value} icon={<span>{action.label}</span>} tooltipTitle={action.label} onClick={() => applyFilter(action.label)} />
		))}
	</SpeedDialStyled>;
}


function RestaurantMenu() {
	/** Declare hooks and plugins and constants */
	let orderId = cookieServices.get('orderId');
	let tableId = cookieServices.get('tableId');
	let brandId = cookieServices.get('brandId');

	const dispatch = useDispatch();
	const { search } = useLocation();
	let navigate = useNavigate();
	const searchParams = new URLSearchParams(search);
	const [speedDialOpen, setSpeedDialOpen] = useState(false);

	/** Redux reducer actions */
	const getMenuList = (params) => dispatch(RestaurantAction.getMenuList(params));
	const menuSelectList = (params) => dispatch(RestaurantAction.menuSelectList(params));

	/** Redux reducer states */
	const { menu_list_loading, menu_list, menu_select_list } = useSelector((state) => state.RestaurantReducer);

	const [payload, setPayload] = useState({
		search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
		filter: { category: searchParams.get('category') ?? '' },
	});
	const [isLoaded, setIsLoaded] = useState(false);
	const [filteredData, setFilteredData] = useState({});

	useEffect(() => {
		/* Read the payload and append the parameters to the url for proper reload */
		let filtered = {};
		Object.keys(payload.filter).forEach(function (item, i) {
			if (payload.filter[item])
				filtered[item] = payload.filter[item];
		});
		setFilteredData({ ...filtered });

		const queryParam = { ...payload.filter };
		Object.keys(queryParam).forEach(element => {
			if (queryParam[element] === "" || queryParam[element] === null)
				delete queryParam[element];
		});

		if (payload.search) queryParam.q = payload.search;
		else delete queryParam.q;
		if (payload.filter.category) queryParam.category = payload.filter.category;
		else delete queryParam.category;

		let redirectPath = postAuthRoutes('restaurant_menu').path;
		navigate({ redirectPath, search: createSearchParams(`?${queryString.stringify(queryParam)}`).toString() });
		getNext(); // Initially load participants list

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload.search, payload.filter, brandId]);

	useEffect(() => {
		setIsLoaded(true);
		menuSelectList({ brand_id: brandId });
	}, []);

	const getNext = async () => {
		const result = await getMenuList({ brand_id: brandId, table_id: tableId, order_id: orderId, ...payload });
		if (result.status) {
			setPayload({ ...payload });
		}
	};

	/**
	 * function to apply the search key words on the promo list
	 * @param {String} text Search keyword used
	 * @return view
	 * @author Akshay N
	 * @created_at 28 May 2022
	 */
	const applySearch = (text) => {
		setPayload({ ...payload, search: text });
	};

	/**
	 * function to apply the selected filter options on the restaurant menu list
	 * @param {Object} filterData
	 * @return view
	 * @author Naveen K
	 * @created_at 15 May 2024
	 */
	const applyFilter = (filterData) => {
		setSpeedDialOpen(false);
		setPayload({ ...payload, filter: { category: filterData } });
	};

	/**
	* function to clear all data entered on influencer filter modal
	* @return view
	* @author Naveen K
	* @created_at 15 May 2024
	*/
	const clearFilterItem = (item) => {
		applyFilter('');
	};

	return (
		<Fragment>
			<CardHeader
				title={postAuthRoutes('restaurant_menu').name}
				action={
					<Stack spacing={1} direction="row" sx={{paddingLeft: {xs: '30px', sm: '30px'}, paddingRight: {xs: '10px', sm: '10px'}}}>
						<FormToggleSearchInput getSearchText={applySearch} searchText={payload.search} />
					</Stack>
				}
			/>
			{
				Object.keys(filteredData).length > 0 &&
				(
					<Grid container mb={2} >
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Stack direction="row" spacing={1}>
								{
									Object.keys(filteredData).map((item, i) => {
										if (item === 'category') {
											const category = menu_select_list.find(function (category) { return category.value === filteredData[item]; });
											if (category && category.label) {
												return <Chip key={i} label={category.label} onDelete={() => clearFilterItem(item)} />;
											}
										} else {
											return <Chip key={i} label={filteredData[item]} onDelete={() => clearFilterItem(item)} style={{ textTransform: `capitalize` }} />;
										}
									})
								}
							</Stack>
						</Grid>
					</Grid>
				)
			}
			<Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
				{
					menu_list.length > 0 && menu_list.map((menu, index) => {
						return <MenuCategory menu={menu} key={index} />;
					})
				}
				{!menu_list_loading && (isLoaded && menu_list.length === 0) && <NoData content1={`Menu`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`80vh`} />}
				{(menu_list_loading || !isLoaded) && <MenuCategoryLoader category={2} item={4} />}
			</Grid>
			{speedDialRenderIcon(menu_select_list, applyFilter, speedDialOpen, setSpeedDialOpen)}
			<MenuDrawer />
		</Fragment>
	);
}

export { RestaurantMenu };
