import select from "../../../_root/components/form/select";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...select,
  styleOverrides: {
    ...select.styleOverrides,
    select: {
      ...select.styleOverrides.select,
      "& .Mui-selected": {
        ...select.styleOverrides.select['& .Mui-selected'],
        backgroundColor: colors.transparent,
      },
    },

  },
};
