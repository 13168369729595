import breadcrumbs from "../../../_root/components/breadcrumbs/breadcrumbs";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...breadcrumbs,
  styleOverrides: {
    ...breadcrumbs.styleOverrides,
    separator: {
      ...breadcrumbs.styleOverrides.separator,
      color: colors.grey[600],
    },
  },
};
