import switchButton from "../../../_root/components/form/switchButton";
import linearGradient from "../../../_root/functions/linearGradient";
import borders from "../../base/borders";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  ...switchButton,

  styleOverrides: {
    ...switchButton.styleOverrides,
    switchBase: {
      ...switchButton.styleOverrides.switchBase,
      color: colors.gradients.dark.main,

      "&:hover": {
        ...switchButton.styleOverrides.switchBase['&:hover'],
        backgroundColor: colors.transparent,
      },

      "&.Mui-checked": {
        ...switchButton.styleOverrides.switchBase['&.Mui-checked'],
        color: colors.gradients.dark.main,

        "&:hover": {
          ...switchButton.styleOverrides.switchBase['&.Mui-checked']['&:hover'],
          backgroundColor: colors.transparent,
        },

        "& .MuiSwitch-thumb": {
          ...switchButton.styleOverrides.switchBase['&.Mui-checked']['& .MuiSwitch-thumb'],
          borderColor: `${colors.gradients.dark.main} !important`,
        },

        "& + .MuiSwitch-track": {
          ...switchButton.styleOverrides.switchBase['&.Mui-checked']['& + .MuiSwitch-track'],
          backgroundColor: `${colors.gradients.dark.main} !important`,
          borderColor: `${colors.gradients.dark.main} !important`,
        },
      },


      "&.Mui-focusVisible .MuiSwitch-thumb": {
        ...switchButton.styleOverrides.switchBase['&.Mui-focusVisible .MuiSwitch-thumb'],
        backgroundImage: linearGradient(colors.gradients.info.main, colors.gradients.info.state),
      },
    },

    thumb: {
      ...switchButton.styleOverrides.thumb,
      backgroundColor: colors.common.white,
      border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
      boxShadow: shadows.xs,
    },

    track: {
      ...switchButton.styleOverrides.track,
      backgroundColor: colors.grey[400],
      border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
    },

  },
};
