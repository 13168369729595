import iconButton from "../../../../_root/components/buttons/icon-button/iconButton";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...iconButton,

  styleOverrides: {
    ...iconButton.styleOverrides,
    root: {
      ...iconButton.styleOverrides.root,
      "&:hover": {
        ...iconButton.styleOverrides.root['&:hover'],
        backgroundColor: colors.transparent,
      },

      "& .MuiSvgIcon-root": {
        color: colors.text.main,

        "&:hover": {
          color: colors.text.light,
        },
      },
    },

    colorPrimary: {
      ...iconButton.styleOverrides.colorPrimary,
      color: colors.primary.main,

      "&:hover": {
        color: colors.primary.light,
      },

      "& .MuiSvgIcon-root": {
        color: colors.primary.main,

        "&:hover": {
          color: colors.primary.light,
        },
      },
    },

    colorSecondary: {
      ...iconButton.styleOverrides.colorSecondary,
      color: colors.secondary.main,

      "&:hover": {
        color: colors.secondary.light,
      },

      "& .MuiSvgIcon-root": {
        color: colors.secondary.main,

        "&:hover": {
          color: colors.secondary.light,
        },
      },

    }
  },
};
