import svgIcon from "../../../_root/components/icon/svgIcon";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...svgIcon,
  styleOverrides: {
    ...svgIcon.styleOverrides,
    root: {
      ...svgIcon.styleOverrides.root,
    },
  }
};
