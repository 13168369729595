import textField from "../../../_root/components/form/textField";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...textField,
  styleOverrides: {
    ...textField.styleOverrides,
    root: {
      ...textField.styleOverrides.root,
      backgroundColor: colors.transparent,
    },
  },
};
