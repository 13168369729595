import React from 'react';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { Banner, InfluencerTutorial, FeaturedPremiumPromos, JoinToday, TrustedBusinesses, BrandSteps, Testimonials } from '.';
import { Stack } from '@mui/material';

function Home() {

    return (
        <PreSignIn>
            <Stack spacing={3}>
                <Banner />
                <InfluencerTutorial />
                <FeaturedPremiumPromos />
                <JoinToday />
                <TrustedBusinesses />
                <BrandSteps />
                <Testimonials />
            </Stack>
        </PreSignIn>
    );
}

export { Home }
