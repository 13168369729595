import colors from "./colors";
import baseTypography from "../../_root/base/typography";

const { text } = colors;

const baseProperties = {
  color: text.main,
};
const baseHeadingProperties = {
  color: text.main,
};

const baseDisplayProperties = {
  color: text.main,
};

const typography = {
  ...baseTypography,

  h1: {
    ...baseTypography.h1,
    ...baseHeadingProperties,
  },

  h2: {
    ...baseTypography.h2,
    ...baseHeadingProperties,
  },

  h3: {
    ...baseTypography.h3,
    ...baseHeadingProperties,
  },

  h4: {
    ...baseTypography.h4,
    ...baseHeadingProperties,
  },

  h5: {
    ...baseTypography.h5,
    ...baseHeadingProperties,
  },

  h6: {
    ...baseTypography.h6,
    ...baseHeadingProperties,
  },

  subtitle1: {
    ...baseTypography.subtitle1,
    ...baseProperties
  },

  subtitle2: {
    ...baseTypography.subtitle2,
    ...baseProperties
  },

  body1: {
    ...baseTypography.body1,
    ...baseProperties
  },

  body2: {
    ...baseTypography.body2,
    ...baseProperties
  },

  button: {
    ...baseTypography.button,
    ...baseProperties
  },

  caption: {
    ...baseTypography.caption,
    ...baseProperties
  },

  overline: {
    ...baseTypography.overline,
    ...baseProperties
  },

  d1: {
    ...baseTypography.d1,
    ...baseDisplayProperties,
  },

  d2: {
    ...baseTypography.d2,
    ...baseDisplayProperties,
  },

  d3: {
    ...baseTypography.d3,
    ...baseDisplayProperties,
  },

  d4: {
    ...baseTypography.d4,
    ...baseDisplayProperties,
  },

  d5: {
    ...baseTypography.d5,
    ...baseDisplayProperties,
  },

  d6: {
    ...baseTypography.d6,
    ...baseDisplayProperties,
  },

  size: {
    ...baseTypography.size,
  },

  lineHeight: {
    ...baseTypography.lineHeight,
  },
};

export default typography;
