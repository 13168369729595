import React from 'react';
import { ShortLogo } from '../../logo/short-logo';
import './initial-loader.scss';

function InitialLoader({ loader }) {

    if (!loader) {
        return '';
    }
    return (
        <div className={`initial-loader`}>
            <div className="initial-loader-inner" >
                <ShortLogo className="blink-image" />
            </div>
        </div>
    )
}

export { InitialLoader };
