import { GlobalAction, failure, request, success } from ".";
import { UserServices } from "../services";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const UserAction = {

    getUserDetails: () => {
        return dispatch => {
            dispatch(request(USER_REQUEST, {}));
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.getUserDetails()
                .then(
                    response => {
                        dispatch(dispatch(GlobalAction.pageLoader(false)));
                        dispatch(GlobalAction.showAuthDialog(false));
                        dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode)));
                        return dispatch(success(USER_SUCCESS, {}, response));
                    },
                    error => { return dispatch(failure(USER_FAILURE, {}, error)); }
                );
        };
    },

    updateTheme: () => {
        return dispatch => {
            dispatch(request(USER_UPDATE_REQUEST, {}));
            return UserServices.updateTheme()
                .then(
                    response => { dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode))); return dispatch(success(USER_UPDATE_SUCCESS, {}, response)); },
                    error => { return dispatch(failure(USER_UPDATE_FAILURE, {}, error)); }
                );
        };
    },

    deleteAccount: () => {
        return dispatch => {
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.deleteAccount()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return response; },
                    error => { return error; }
                );
        };
    },

    changePassword: (params) => {
        return dispatch => {
            dispatch(request(CHANGE_PASSWORD_REQUEST, params));
            return UserServices.changePassword(params)
                .then(
                    response => { return dispatch(success(CHANGE_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CHANGE_PASSWORD_FAILURE, params, error)); }
                );
        };
    }

};
