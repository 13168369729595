import linearProgress from "../../../_root/components/linear-progress/linearProgress";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...linearProgress,
  styleOverrides: {
    ...linearProgress.styleOverrides,
    colorPrimary: {
      ...linearProgress.styleOverrides.colorPrimary,
      backgroundColor: colors.light.main,
    },

    colorSecondary: {
      ...linearProgress.styleOverrides.colorSecondary,
      backgroundColor: colors.light.main,
    },

  },
};
