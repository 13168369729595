import { styled } from '@mui/material';
import PropTypes from 'prop-types'

const LogoBox = styled(({ ...props }) => (
    <svg viewBox="0 0 740 740" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M370.264 109L469.29 283.333H271.238L370.264 109Z" fill="#3483D2" />
        <path d="M270.645 283.333L369.671 109H171.619L270.645 283.333Z" fill="#0AAEF2" />
        <path d="M270.645 632L369.671 457.667H171.619L270.645 632Z" fill="#E97E98" />
        <path d="M468.697 632L567.723 457.667H369.671L468.697 632Z" fill="#2C5285" />
        <path d="M468.697 283.333L567.723 457.667H369.671L468.697 283.333Z" fill="#EA3661" />
        <path d="M568.316 457.667L667.342 632H469.29L568.316 457.667Z" fill="#3483D2" />
        <path d="M171.026 457.667L270.052 632H72L171.026 457.667Z" fill="#EA3661" />
    </svg>


))``;


const ShortLogo = ({ height, ...props }) => {
    return (
        <LogoBox height={height} {...props} />
    )
}

ShortLogo.defaultProps = {
    height: "40",
    className: ""
}


ShortLogo.propTypes = {
    props: PropTypes.object,
    height: PropTypes.string
}


export { ShortLogo }
