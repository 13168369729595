import React from 'react'
import { FormControl, styled } from '@mui/material';

const StyledFormPassword = styled(({ ...props }) => (<FormControl {...props} />))`
        & .MuiFormLabel-root {
            color:  ${props => props.theme.palette.text.main};
        }
        & .MuiFormHelperText-root {
            margin-left: 0;
        }

`;

export { StyledFormPassword }