import React, { useEffect } from 'react';
import { LocalMall, ShoppingCart, Restaurant } from '@mui/icons-material';
import { Badge, Box, CardMedia, Container, IconButton, Toolbar, styled } from '@mui/material';
import { RouteLink } from '../../../../_components';
import { postAuthRoutes } from '../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { cookieServices } from '../../../../_helpers';
import { useNavigate } from 'react-router-dom';
import { BrandAction, RestaurantAction } from '../../../../redux/actions';

const StyledRestaurantHeaderContainer = styled(({ ...props }) => (<Container maxWidth="xl"  {...props} />))`
    padding: 10 !important;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${props => props.theme.palette.dark.main};
`;

function PostRestaurantHeader() {

    /** Declare hooks and plugins and constants */
    let navigate = useNavigate();
    const dispatch = useDispatch();

    /** Declare hooks and plugins and constants */
    let orderId = cookieServices.get('orderId');

    /** Redux reducer states */
    const { brand_loading, brand } = useSelector((state) => state.BrandReducer);
    const { cart, orders } = useSelector((state) => state.RestaurantReducer);

    const getOrderList = (params) => dispatch(RestaurantAction.getOrderList(params));

    useEffect(() => {
        getNext(); // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    const getNext = async () => {
        await getOrderList({ order_id: orderId });
    };

    useEffect(() => {

        if (!cookieServices.get('brandId')) {
            navigate('/');
        } else {
            dispatch(BrandAction.getBrandDetails({ brand_id: cookieServices.get('brandId') }));
        }

    }, [navigate]);

    const viewCart = () => {
        navigate(postAuthRoutes('restaurant_cart').path);
    };

    const viewOrder = () => {
        navigate(postAuthRoutes('restaurant_order').path);
    };

    const viewMenu = () => {
        navigate(postAuthRoutes('restaurant_menu').path);
    };

    return (
        <StyledRestaurantHeaderContainer>
            <Toolbar disableGutters>
                <IconButton onClick={viewMenu}><Restaurant /></IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <RouteLink to={postAuthRoutes('restaurant_menu').path} label={<Box display="flex" justifyContent="center" alignItems="center"> {brand.logo && <CardMedia component="img" image={brand.logo} onError={e => { e.target.src = brand.logo_error; }} title={brand.name} height="35" sx={{ borderRadius: `4px`, margin: `0 !important` }} />} </Box>} />
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={viewOrder}><Badge badgeContent={orders.length} color="secondary"> <LocalMall /></Badge> </IconButton>
                <IconButton onClick={viewCart}><Badge badgeContent={cart.length} color="secondary"> <ShoppingCart /></Badge> </IconButton>
            </Toolbar>
        </StyledRestaurantHeaderContainer>
    );
}

export { PostRestaurantHeader };