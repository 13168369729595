
import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    paper: {
      padding: 30,
      borderRadius: borders.borderRadius.md
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
  variants: []
};
