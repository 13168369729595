import { failure, request, success } from ".";
import { FaqServices } from "../services";

export const GET_FAQ_LIST_REQUEST = 'GET_FAQ_LIST_REQUEST';
export const GET_FAQ_LIST_SUCCESS = 'GET_FAQ_LIST_SUCCESS';
export const GET_FAQ_LIST_FAILURE = 'GET_FAQ_LIST_FAILURE';

export const FaqAction = {

    getFaqList: (params) => {
        return dispatch => {
            dispatch(request(GET_FAQ_LIST_REQUEST, params));
            return FaqServices.getFaqList(params)
                .then(
                    response => { return dispatch(success(GET_FAQ_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_FAQ_LIST_FAILURE, params, error)); }
                );
        };
    }

};