import contained from "../../../../_root/components/buttons/button-fab/contained";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...contained,

  primary: {
    ...contained.primary,
    backgroundColor: colors.transparent,
    borderColor: colors.primary.main,
    color: colors.common.white,
    "&:hover": {
      backgroundColor: colors.primary.main,
      borderColor: colors.primary.dark,
      color: colors.common.white
    },

  },

  secondary: {
    ...contained.secondary,
    backgroundColor: colors.transparent,
    borderColor: colors.secondary.main,

    "&:hover": {
      backgroundColor: colors.secondary.main,
      borderColor: colors.secondary.dark,
      color: colors.text.main,
    },
  },
};
