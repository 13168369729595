import { failure, request, success } from ".";
import { CouponCodesServices } from "../services";

export const COUPON_CODES_REQUEST = 'COUPON_CODES_REQUEST';
export const COUPON_CODES_SUCCESS = 'COUPON_CODES_SUCCESS';
export const COUPON_CODES_FAILURE = 'COUPON_CODES_FAILURE';

export const CouponCodesAction = {

    listCouponCodes: (params) => {
        return dispatch => {
            dispatch(request(COUPON_CODES_REQUEST, params));
            return CouponCodesServices.listCouponCodes(params)
                .then(
                    response => { return dispatch(success(COUPON_CODES_SUCCESS, params, response)); },
                    error => { return dispatch(failure(COUPON_CODES_FAILURE, params, error)); }
                );
        };
    }
}