
import colors from "./colors";
import borders from "../../_root/base/borders";
const { grey } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  /* `borderColor: grey[300],` is setting the default border color to the value of the 300th index of
  the `grey` color object. This is likely part of a larger style object or theme that is being used
  to maintain consistency in the design of a website or application. */
  borderColor: grey[300],

  /* `borderWidth` is an object that defines different values for the width of borders. The keys of the
  object represent different levels of thickness, ranging from 0 to 5. The values associated with
  each key are the corresponding pixel values converted to `rem` units using the `pxToRem` function.
  This object can be used to set the width of borders in CSS styles, allowing for consistent and
  flexible border styling throughout a website or application. */
  borderWidth: { ...borders.borderWidth },

  /* `borderRadius` is an object that defines different values for the radius of rounded corners on
  elements. The keys of the object represent different levels of size, ranging from extra small (xs)
  to extra extra large (xxl) and section. The values associated with each key are the corresponding
  pixel values converted to `rem` units using the `pxToRem` function. This object can be used to set
  the radius of rounded corners in CSS styles, allowing for consistent and flexible border styling
  throughout a website or application. */
  borderRadius: { ...borders.borderRadius },
};
