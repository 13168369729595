import { failure, request, success } from ".";
import { RegisterServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const COMPLETE_PROFILE_REQUEST = 'COMPLETE_PROFILE_REQUEST';
export const COMPLETE_PROFILE_SUCCESS = 'COMPLETE_PROFILE_SUCCESS';
export const COMPLETE_PROFILE_FAILURE = 'COMPLETE_PROFILE_FAILURE';

export const COMPLETED_PROFILE_REQUEST = 'COMPLETED_PROFILE_REQUEST';
export const COMPLETED_PROFILE_SUCCESS = 'COMPLETED_PROFILE_SUCCESS';
export const COMPLETED_PROFILE_FAILURE = 'COMPLETED_PROFILE_FAILURE';

export const RegisterAction = {

    /**
    * Action for register brand
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.name - Name of the brand
    * @param {string} params.insta_handle - Instagram handle of the brand
    * @param {string} params.email - Email of the brand
    * @param {string} params.mobile_number - Mobile number entered by the brand
    * @param {string} params.password - Password entered by the brand
    * @param {string} params.confirm_password - Confirm password entered by the brand
    * @author Akshay
    */
    register: (params) => {
        return dispatch => {
            dispatch(request(REGISTER_REQUEST, params));
            return RegisterServices.register(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(REGISTER_SUCCESS, params, response)); },
                    error => { return dispatch(failure(REGISTER_FAILURE, params, error)); }
                );
        };
    },

    confirmEmail: (verificationString) => {
        return dispatch => {
            return RegisterServices.confirmEmail(verificationString)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };
    },

    submitNewsLetter: (params) => {
        return dispatch => {
            return RegisterServices.submitNewsLetter(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return response; },
                    error => { return error; }
                );
        };

    },

    completeProfile: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.completeProfile(COMPLETE_PROFILE_REQUEST, params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(COMPLETE_PROFILE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(COMPLETE_PROFILE_FAILURE, params, error)); }
                );
        };
    },

    /**
    * Action for complete profile success
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.name - Name of the brand
    * @param {string} params.email - Email of the brand
    * @param {string} params.mobile_number - Mobile number entered by the brand
    * @param {string} params.password - Password entered by the brand
    * @param {string} params.confirm_password - Confirm password entered by the brand
    * @author Akshay
    */
    completeProfileSuccess: (params) => {
        return dispatch => {
            dispatch(request(COMPLETED_PROFILE_REQUEST, params));
            return RegisterServices.completeProfileSuccess(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(COMPLETED_PROFILE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(COMPLETED_PROFILE_FAILURE, params, error)); }
                );
        };
    }

};
