import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { cookieServices } from '../_helpers';
import { PostSignIn } from '../_layouts/post-signin';
import { preAuthRoutes } from './preAuthRoutes';

export const PrivateRoute = ({ ...props }) => {

    // Checking if the user has an access token.
    // If they do, it will render the PostAuth component.
    // If they don't, it will redirect them to the sign in page.
    return cookieServices.get('accessToken') ? <PostSignIn><Outlet /></PostSignIn> : <Navigate to={`${preAuthRoutes('home').path}`} {...props} />
}
