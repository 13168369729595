import menuItem from "../../../_root/components/menu/menuItem";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...menuItem,
  styleOverrides: {
    ...menuItem.styleOverrides,
    root: {
      ...menuItem.styleOverrides.root,
      color: colors.text.main,

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: colors.light.main,
        color: colors.dark.main,
      },
    },
  },
};
