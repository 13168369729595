import {
    GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE,
    GET_NOTIFICATIONS_LIST_REQUEST, GET_NOTIFICATIONS_LIST_SUCCESS, GET_NOTIFICATIONS_LIST_FAILURE,
    MARK_ALL_NOTIFICATIONS_READ_REQUEST, MARK_ALL_NOTIFICATIONS_READ_SUCCESS, MARK_ALL_NOTIFICATIONS_READ_FAILURE,
    MARK_NOTIFICATION_READ_REQUEST, MARK_NOTIFICATION_READ_SUCCESS, MARK_NOTIFICATION_READ_FAILURE,

} from "../actions";

const initialState = {
    notifications_loading: false, notifications: [], pending_notifications: 0, notifications_errors: {},
    notifications_list_loading: false, notifications_list: [], notifications_list_errors: {},
    mark_all_notifications_read_loading: false, mark_all_notifications_read_errors: {},
    mark_notification_read_loading: false, mark_notification_read_errors: {}

};

export function NotificationReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::notifications redux */
        case GET_NOTIFICATIONS_REQUEST: {
            return {
                ...state,
                notifications_loading: true,
                notifications_errors: {}
            };
        }
        case GET_NOTIFICATIONS_SUCCESS: {

            let newArray = [...state.notifications]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.notifications.forEach((payload) => {
                const notificationIndex = newArray.findIndex(notification => notification._id === payload._id);
                if (notificationIndex !== -1) {
                    newArray[notificationIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                notifications_loading: false,
                notifications_errors: {},
                notifications: newArray,
                pending_notifications: action.data.notification_status.pending
            };

        }
        case GET_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                notifications_loading: false,
                notifications_errors: action.errors,
                notifications: []
            };
        }

        /** end::notifications redux */
        /** begin::List notifications redux */
        case GET_NOTIFICATIONS_LIST_REQUEST: {
            return {
                ...state,
                notifications_list_loading: true,
                notifications_list_errors: {}
            };
        }
        case GET_NOTIFICATIONS_LIST_SUCCESS: {

            let newArray = [...state.notifications_list]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.notifications.forEach((payload) => {
                const notificationIndex = newArray.findIndex(notification => notification._id === payload._id);
                if (notificationIndex !== -1) {
                    newArray[notificationIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                notifications_list_loading: false,
                notifications_list_errors: {},
                notifications_list: newArray
            };

        }
        case GET_NOTIFICATIONS_LIST_FAILURE: {
            return {
                ...state,
                notifications_list_loading: false,
                notifications_list_errors: action.errors,
                notifications_list: []
            };
        }

        /** end::List notifications redux */

        
        /** begin::Mark all notifications read redux */
        case MARK_ALL_NOTIFICATIONS_READ_REQUEST: {
            return {
                ...state,
                mark_all_notifications_read_loading: true,
                mark_all_notifications_read_errors: {}
            };
        }
        case MARK_ALL_NOTIFICATIONS_READ_SUCCESS: {
            let newArray = [...state.notifications]; //making a new array
            let pendingNotifications = 0; //making a new array

            action.data.forEach((payload) => {
                const notificationIndex = newArray.findIndex(notification => notification._id === payload._id);
                if (payload.status === 'pending') {
                    pendingNotifications = pendingNotifications + 1;
                }
                if (notificationIndex !== -1) {
                    newArray[notificationIndex].status = payload.status;
                } 
            });
            
            return {
                ...state,
                mark_all_notifications_read_loading: false,
                mark_all_notifications_read_errors: {},
                pending_notifications: pendingNotifications
            };
        }
        case MARK_ALL_NOTIFICATIONS_READ_FAILURE: {
            return {
                ...state,
                mark_all_notifications_read_loading: false,
                mark_all_notifications_read_errors: action.errors
            };
        }
        /** end::Mark all notifications read redux */

        /** begin::Mark notification read redux */
        case MARK_NOTIFICATION_READ_REQUEST: {
            return {
                ...state,
                mark_notification_read_loading: true,
                mark_notification_read_errors: {}
            };
        }
        case MARK_NOTIFICATION_READ_SUCCESS: {

            let notificationArray = [...state.notifications]; //making a new array
            let pendingNotifications = state.pending_notifications; //making a new array

            const notificationId = notificationArray.findIndex(notification => notification._id === action.data._id);
            if (notificationId !== -1) {
                notificationArray[notificationId].status = action.data.status;
                pendingNotifications = pendingNotifications-1;
            }

            return {
                ...state,
                mark_notification_read_loading: false,
                mark_notification_read_errors: {},
                notifications: notificationArray,
                pending_notifications: pendingNotifications
            };
        }
        case MARK_NOTIFICATION_READ_FAILURE: {
            return {
                ...state,
                mark_notification_read_loading: false,
                mark_notification_read_errors: action.errors
            };
        }
        /** end::Mark notification read redux */
        default:
            return state;
    }
}
