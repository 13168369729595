import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box, Button, styled } from '@mui/material';
import { FormInput, FormPassword, AnchorLink, SubmitButton, ModalHeader, ModalBody, ModalFooter } from '../../../../_components';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { validate } from '.';
import { SignInAction } from '../../../../redux/actions/SignInAction';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';

// Initialize form input values to null
const inputs = { name: '', insta_handle: '', email: '', mobile_number: '', password: '', confirm_password: '' };

function Login({ backTo, onClose }) {

    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();

    /** Redux reducer actions */
    const login = (params) => dispatch(SignInAction.signIn(params));

    /** Redux reducer states */
    const { loading: isLoading, errors: signinErrors } = useSelector((state) => state.SignInReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle signin form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchLoginAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    async function dispatchLoginAction() {
        const isSignin = await login(data);
        if (isSignin.status === 1) {
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : postAuthRoutes('promos').path);
            navigate(redirectPath);
        }
    }

    const ForgotPasswordButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        padding: 0,
        fontWeight: 'inherit',
        color: theme.palette.text.main,
        '&:hover': {
            color: theme.palette.text.focus,
        },
    }));

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={preAuthRoutes(`log_in`).name} onClose={onClose} />
                <ModalBody>
                    <Box >
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={3}
                                    label='Email'
                                    name='email'
                                    value={data.email}
                                    error={action.isSubmitted && errors.email ? errors.email : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={4}
                                    label='Password'
                                    name='password'
                                    value={data.password}
                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Stack direction="row" justifyContent="flex-end" ><ForgotPasswordButton size="small" onClick={(e) => { e.preventDefault(); backTo('forgot_password') }} disableRipple >Forgot password</ForgotPasswordButton></Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box>
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label={preAuthRoutes(`log_in`).name} color={`secondary`} fullWidth loading={isLoading} />
                            <Box sx={{ fontSize: `12px` }} >Don't have an account? <Button color="inherit" variant="text" sx={{ fontSize: '14px', cursor: 'pointer', minWidth: 'auto', padding: 0, marginLeft: '4px', textTransform: 'none' }} onClick={(e) => { e.preventDefault(); backTo('register') }} > { preAuthRoutes(`register`).name } </Button> </Box>
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { Login };
