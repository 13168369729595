import React from 'react';
import { Grid } from '@mui/material';
import { StoryDetails, StoryTimeline, StoryPromoDetails } from '.';

function StoriesDetail() {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display="flex" alignItems="center"  >
                <StoryPromoDetails />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display="flex" alignItems="center"  >
                <StoryDetails />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}  >
                <StoryTimeline />
            </Grid>
        </Grid>
    );
}

export { StoriesDetail }