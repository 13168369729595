import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    switchBase: {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },
    },

    track: {
      width: pxToRem(32),
      height: pxToRem(15),
      opacity: 1,
    },

    checked: {},
  },
  variants: []
};
