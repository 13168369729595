import hexToRgb from "./hexToRgb";

/**
 * The function takes a color in hexadecimal format and an opacity value, and returns the color in RGBA
 * format.
 * @param {String} color - The color parameter is a hexadecimal color code (e.g. #FF0000 for red).
 * @param {Float} opacity - Opacity refers to the level of transparency of a color. It is a value between 0 and
 * 1, where 0 is completely transparent and 1 is completely opaque. In the given function, the opacity
 * parameter is used to set the alpha value of the RGBA color.
 * @returns {String} The function `rgba` is returning a string in the format of `rgba(red, green, blue,
 * opacity)` where the values for red, green, and blue are obtained by converting the hexadecimal color
 * code passed as the `color` parameter to its RGB equivalent using the `hexToRgb` function, and the
 * `opacity` value is passed as the second parameter.
 * function description
 * @author Akshay N
 * @created_at 21/05/2023
 */
function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
