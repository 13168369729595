import { Box, CardMedia, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction } from '../../../../redux/actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

function TrustedBusinesses() {

    // const classes = useStyles();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { brands } = useSelector((state) => state.BrandReducer);
    const getBrandList = (params) => dispatch(BrandAction.getBrandList(params));

    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: '', // Search key to check for word anywhere in the record
        filter: {},
    });

    const getNext = async () => {
        const result = await getBrandList(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    useEffect(() => {

        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (brands.length === 0)
        return ''

    return (
        <Box>
            <Stack spacing={1}>
                <Typography variant="h5">Trusted businesses</Typography>
                <Swiper spaceBetween={15} slidesPerView={10} navigation autoplay={{ delay: 3000 }} modules={[Navigation, Pagination, Autoplay]} breakpoints={{ 0: { slidesPerView: 3, }, 576: { slidesPerView: 6, }, 768: { slidesPerView: 10, }, 992: { slidesPerView: 10 }, 1200: { slidesPerView: 10 }, 1400: { slidesPerView: 10 } }}>
                    {brands.map((brand, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <CardMedia component="img" image={brand.insta_profile.profile_picture_url} title={brand.insta_profile.username} height="100" sx={{ borderRadius: `50%`, margin: `0 !important`, border: `2px solid #fff` }} onError={e => { e.target.src = brand.insta_profile.profile_picture_url_error }} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Stack>
        </Box>
    )
}

export { TrustedBusinesses }