import React from 'react';
import { styled, SwipeableDrawer } from '@mui/material';

const MenuDrawerStyled = styled(({ ...props }) => <SwipeableDrawer anchor="bottom" {...props} hysteresis={0.1} />)`
	& .MuiPaper-root {
		border-radius: ${(props) => props.theme.borders.borderRadius.section};
		padding: ${(props) => `${props.theme.spacing(2.5)}`};
		display: flex;
		align-items: center;
		background: ${(props) => (props.view === 'added_to_cart' ? `#005600` : props.theme.palette.background.card)};
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		& .item-image {
			height: ${(props) => `${props.theme.functions.pxToRem(200)}`};
			width: 100%;
			margin: 0;
			border-radius: ${(props) => props.theme.borders.borderRadius.section};
			border-radius: 0;
		}
		& .MuiStack-root {
			width: 100%;
			padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(0)}`};
			& .item-details-left {
				width: 100%;
				& .item-title-wrapper {
					display: flex;
					align-items: center;
					padding-bottom: ${(props) => `${props.theme.spacing(1)}`};
					& .item-title {
						margin-left: ${(props) => `${props.theme.spacing(1)}`};
					}
				}
				& .price-box {
					display: flex;
					align-items: center;
					justify-content: left;
					& .item-price {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					& .item-discount-price {
						display: flex;
						align-items: center;
						justify-content: center;
						text-decoration: line-through;
						font-size: 12px;
						margin-left: 8px;
						& .MuiSvgIcon-root {
							color: ${(props) => props.theme.palette.error.main};
						}
					}
				}
			}
			& .item-details-right {
				display: flex;
				align-items: center;
			}
		}
		& .item-description {
			font-size: ${(props) => `${props.theme.typography.size.sm}`};
			color: ${(props) => props.theme.palette.text.light};
		}

		& .portions {
			display: flex;
			align-items: baseline;
			justify-content: center;
			padding: 20px;
			& .MuiFormControlLabel-root {
				display: flex;
				& :hover {
					background-color: #c72729 !important;
					& .MuiSvgIcon-root {
						& path {
							fill: #f5e1e0;
						}
					}
				}
			}

			& .MuiButtonBase-root {
				background-color: #f5e1e0;
				border-radius: 22px;
				margin-bottom: 10px;
				padding: 20px;

				& .Mui-checked {
					& :after {
						background-image: none;
					}
				}
				& :after {
					background-image: none;
				}
			}

			& .Mui-checked {
				background-color: #c72729 !important;
				& .MuiSvgIcon-root {
					& path {
						fill: #f5e1e0;
					}
				}
			}
			& .MuiSvgIcon-root {
				border-radius: 0;
				border: 0;
				height: 25px;
				width: 25px;
				font-size: 16px; /* Font size for the first child */
			}

			& .full_portion {
				& .MuiSvgIcon-root {
					height: 40px;
					width: 40px;
					font-size: 40px; /* Font size for the first child */
				}
			}
			& .half_portion {
				& .MuiSvgIcon-root {
					font-size: 30px; /* Font size for the first child */
					height: 30px;
					width: 30px;
				}
			}
			& .MuiRadio-root:after {
				background-image: none !important;
			}
		}
		& .added-to-cart {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			min-height: ${(props) => `${props.theme.functions.pxToRem(300)}`};
			& .MuiStack-root {
				& .MuiSvgIcon-root {
					height: 80px;
					width: 80px;
					& path {
						fill: #fff;
					}
				}
			}
		}
	}
`;

export { MenuDrawerStyled };
