import { apiServices } from '../../_helpers';

export const GlobalServices = {

    /**
     * Service for list coupon_codes
     * @author Akshay 
     */
    getBannerImage: () => {

        return apiServices.get(`/influencer/advertisement/find/banner`)
            .then((response) => { return response; })
    },
}