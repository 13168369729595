module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}, orderByVehicle = false, hasUrl = false, addressDiv = false) => {
        const errors = {};
        switch (name) {
            case 'mobile_number': {
                errors.mobile_number = '';
                if (value === 'undefined') {
                    errors.mobile_number = 'Mobile number is invalid. '
                }
                if (value === '')
                    errors.mobile_number = 'Enter mobile number. ';
                break;
            } case 'booking_for': {
                errors.booking_for = '';
                if (hasUrl && value === '') errors.booking_for = 'Enter Pre Booking Date. ';
                break;
            } case 'vehicle_number': {
                errors.vehicle_number = '';
                if (orderByVehicle && value === '') errors.vehicle_number = 'Enter Vehicle Number. ';
                break;
            } case 'house_no': {
                errors.house_no = '';
                if (addressDiv && value === '') errors.house_no = 'Enter House Number. ';
                break;
            } case 'road_name': {
                errors.road_name = '';
                if (addressDiv && value === '') errors.road_name = 'Enter Full Address. ';
                break;
            } case 'landmark': {
                errors.landmark = '';
                if (addressDiv && value === '') errors.landmark = 'Enter Landmark. ';
                break;
            } case 'city': {
                errors.city = '';
                if (addressDiv && value === '') errors.city = 'Enter City Name. ';
                break;
            } case 'state': {
                errors.state = '';
                if (addressDiv && value === '') errors.state = 'Enter State Name. ';
                break;
            } case 'pincode': {
                errors.pincode = '';
                if (addressDiv && value === '') errors.pincode = 'Enter Pincode. ';
                break;
            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }
}