import { alpha, darken } from "@mui/material";
import card from "../../../_root/components/card/card";
import linearGradient from "../../../_root/functions/linearGradient";
import borders from "../../base/borders";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	...card,
	styleOverrides: {
		...card.styleOverrides,
		root: {
			...card.styleOverrides.root,
			background: linearGradient(alpha(colors.background.card, 1), darken(colors.background.card, 0.3)),
			border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
			boxShadow: shadows.xs
		}
	}
};
