module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const validInstaHandleRegex = RegExp(/^[a-z0-9._]{1,30}$/i);
        const errors = {};
        switch (name) {
            case 'name': {
                errors.name = value.length < 3 ? 'Name should have at least 3 characters long.' : '';
                if (value === '')
                    errors.name = 'Enter your brand name.';
                break;
            } case 'insta_handle': {
                errors.insta_handle = validInstaHandleRegex.test(value) ? '' : 'Enter a valid public instagram handle.';
                if (value === '')
                    errors.insta_handle = 'Enter your public instagram handle.';
                break;
            } case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid.';
                if (value === '')
                    errors.email = 'Enter your email address.';
                break;
            } case 'mobile_number': {
                errors.mobile_number = value.length < 10 ? 'Mobile number must be 10 digits.' : '';
                if (value === '')
                    errors.mobile_number = 'Enter your mobile number.';
                break;
            } case 'password': {
                const confirmPassword = data.confirm_password ?? '';
                errors.password = value.length < 6 ? 'Password must be 6 characters long.' : '';
                if (confirmPassword !== '' && confirmPassword === value)
                    errors.confirm_password = '';
                if (value === '')
                    errors.password = 'Enter your password.';
                break;
            } case 'confirm_password': {
                const password = data.password ?? '';
                errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long.' : '';
                if (password !== '' && password !== value)
                    errors.confirm_password = 'Confirm password should match with the password.';
                if (value === '')
                    errors.confirm_password = 'Re-enter your password.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}