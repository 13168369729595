import React from 'react';
import { MenuItemStyled } from '.';
import { Box, Button, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { RestaurantAction } from '../../../../../redux/actions';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';

function MenuItem({ ...props }) {

    const { _id, image, title, short_description, discount, discounted_price, price } = props;
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const selectMenuItem = (params) => dispatch(RestaurantAction.selectMenuItem(params));

    return (
        <MenuItemStyled>
            <CardMedia className='item-image' component="img" image={image} />
            <CardContent>
                <Typography className='item-title' gutterBottom variant="h6">{title}</Typography>
                <Typography className='item-description' variant="body2">{short_description}</Typography>
            </CardContent>
            <CardActions>
                <Box className={`price-box`}>
                    <Typography variant="h6" className='item-price'><CurrencySymbol />{discounted_price}</Typography>
                    {discount > 0 && <Typography variant="h6" className='item-discount-price' color={`error`} ><CurrencySymbol />{price}</Typography>}
                </Box>
                <Button variant='contained' size="large" color='secondary' className='item-add-to-cart-btn' onClick={() => selectMenuItem({ id: _id })}><Add /></Button>
            </CardActions>
        </MenuItemStyled>
    );
}

export { MenuItem };