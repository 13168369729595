
/**
 * The function returns only digits from user input and prevents non-digit characters from being
 * entered.
 * @param {Object} e - The parameter "e" is an event object that is passed to the function when it is triggered
 * by an event, such as a keypress event. It contains information about the event, such as the type of
 * event, the target element, and the key that was pressed.
 * @returns The function `returnDigitOnly` returns a boolean value. It returns `true` if the input
 * event `e` contains only digits (0-9), and `false` otherwise. If the input contains non-digit
 * characters, the function also prevents the default behavior of the event.
 * @author Akshay N
 * @created_at 22/05/2023
 */
const returnDigitOnly = (e) => {
    var regex = new RegExp('^[0-9]*$');
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
}

export { returnDigitOnly }
