import {
    TESTIMONIALS_REQUEST, TESTIMONIALS_SUCCESS, TESTIMONIALS_FAILURE
} from "../actions";

const initialState = {
    testimonials_loading: false, testimonials: [], testimonials_errors: {},
};

export function TestimonialsReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List brand testimonials redux */
        case TESTIMONIALS_REQUEST: {
            return {
                ...state,
                testimonials_loading: true,
                testimonials_errors: {}
            };
        }
        case TESTIMONIALS_SUCCESS: {

            let newArray = [...state.testimonials]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.forEach((payload) => {
                const promoIndex = newArray.findIndex(testimonials => testimonials._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                testimonials_loading: false,
                testimonials: newArray,
                testimonials_errors: {}
            };

        }
        case TESTIMONIALS_FAILURE: {
            return {
                ...state,
                testimonials_loading: false,
                testimonials_errors: action.errors
            };
        }
        /** end::List brand testimonials redux */

        default:
            return state;
    }
}
