import pxToRem from "../../../functions/pxToRem";
import typography from "../../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    boxShadow: 'none',
    "&:hover": {},

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: typography.size.sm,
    },
  },

  small: {
    padding: `${pxToRem(3)} ${pxToRem(9)}`,
    fontSize: typography.size.xs,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${typography.size.xs} !important`,
    },
  },
  medium: {
    padding: `${pxToRem(5)} ${pxToRem(15)}`,
    fontSize: typography.size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${typography.size.sm} !important`,
    },
  },

  large: {
    padding: `${pxToRem(7)} ${pxToRem(21)}`,
    fontSize: typography.size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${typography.size.sm} !important`,
    },
  },

};
