import React from 'react';
import { Box, styled } from '@mui/material';

const RestaurantAuthStyled = styled(({ ...props }) => <Box justifyContent="center" alignItems="center" position="absolute" width="100%" minHeight="100vh" {...props} />)`
	.wrapper {
		height: 100vh;
		.wrapper-grid {
            display:flex;
            align-items: center;
            justify-content: center;
			.wrapper-box {
				min-width: 320px;
				max-width: 680px;
				min-height: 240px;
				padding: 25px;
				.MuiGrid-item {
					width: 100%;
				}
				.mobile-screen{
					.MuiGrid-root {
						width: 100%;
					}
				}
			}
		}
	}
`;

export { RestaurantAuthStyled };
