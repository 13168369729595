import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { postAuthRoutes, preAuthRoutes } from '../../../routes';
import { Box, CardMedia, FormHelperText, Grid, Link, Typography } from '@mui/material';
import { FormTelePhoneInput, GeneralButton, SubmitButton, FormDateTimePicker, FormInput, NoData } from '../../../_components';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BrandAction, RestaurantAction } from '../../../redux/actions';
import { RestaurantAuthLoader, RestaurantAuthStyled, validate } from '.';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';


const inputs = { mobile_number: '', booking_for: '', vehicle_number: '', house_no: '', road_name: '', landmark: '', city: '', state: '', pincode: '' };

function RestaurantAuth() {

    /** Initialize plugins and variables */
    const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

    /** Declare hooks and plugins and constants */
    const { brandId, id } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const hasUrl = (id === 'pre-booking') ? true : false;
    const hasDelivery = (id === 'delivery') ? true : false;
    const orderByVehicle = (id === 'order-by-vehicle') ? true : false;

    /** Redux reducer actions */
    const getBrandDetails = (params) => dispatch(BrandAction.getBrandDetails(params));
    const sendOTP = (params) => dispatch(RestaurantAction.sendOTP(params));
    const verifyOtp = (params) => dispatch(RestaurantAction.verifyOtp(params));
    const getOrderAddress = (params) => dispatch(RestaurantAction.getOrderAddress(params));

    /** Redux reducer states */
    const { brand_loading, brand } = useSelector((state) => state.BrandReducer);
    const { send_otp_loading, send_otp, send_otp_errors, verify_otp_errors, order_address_loading } = useSelector((state) => state.RestaurantReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs, brand_id: brandId, id: id });
    const [errors, setErrors] = useState({ ...inputs });
    const [otpErrors, setOtpErrors] = useState({ otp: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    const [otp, setOtp] = useState("");
    const [otpDiv, setOtpDiv] = useState(false);
    const [addressDiv, setAddressDiv] = useState(false);

	const [timer, setTimer] = useState(120);
	const [showResendLink, setShowResendLink] = useState(false);
    const [hasConfiguration, setHasConfiguration] = useState(true);

    useEffect(() => {

        getBrandDetails({ brand_id: brandId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId]);

    useEffect(() => {
        setErrors({ ...send_otp_errors });
        setOtpErrors({ ...verify_otp_errors });

    }, [send_otp_errors, verify_otp_errors]);

    useEffect(() => {
        if (hasUrl && !brand.has_booking_configuration) {
            setHasConfiguration(false);
        } else if (hasDelivery && !brand.has_delivery_configuration) {
            setHasConfiguration(false);
        } else if (orderByVehicle && !brand.has_vehicle_configuration) {
            setHasConfiguration(false);
        } else {
            setHasConfiguration(true);
        }
    }, [hasUrl, hasDelivery, orderByVehicle, brand]);

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data, orderByVehicle, hasUrl, addressDiv)) });
        data[name] = value;
        setData({ ...data });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data, orderByVehicle, hasUrl, addressDiv);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 14 June 2022
    */
    async function dispatchSignInAction() {
        const isOtpSend = await sendOTP({ ...data, hasDelivery });
        if (isOtpSend.status === 1) {
            let redirectPath = preAuthRoutes('restaurant_auth').path;
            redirectPath = redirectPath.replace(':id?', id);
            redirectPath = redirectPath.replace(':brandId', brandId);
            navigate({ pathname: redirectPath });
            setData({ ...data, ...isOtpSend.data });
            setOtpDiv(isOtpSend.data.order_id ? true : false);

            setTimer(120); // Reset the timer
            setShowResendLink(false); // Hide the resend link
        }
    }

    const handleOtpChange = (newValue) => {
        setOtp(newValue);
    };

    const handleVerifyOtp = async () => {
        const isOtpVerified = await verifyOtp({ ...data, otp: otp });

        if (isOtpVerified.status === 1) {
            if (isOtpVerified.data.brand_category_slug === 'restaurant' && isOtpVerified.data.booking_type !== 'delivery') {
                let redirectPath = postAuthRoutes('restaurant_menu').path;
                navigate({ pathname: redirectPath });
            } else {
                let redirectPath = preAuthRoutes('restaurant_auth').path;
                redirectPath = redirectPath.replace(':id?', 'delivery');
                redirectPath = redirectPath.replace(':brandId', brandId);
                navigate({ pathname: redirectPath });
                setData({ ...data, ...isOtpVerified.data });
                setOtpDiv(false);
                setAddressDiv(isOtpVerified.data.order_id ? true : false);
            }
        }
    };

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data, orderByVehicle, hasUrl, addressDiv);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchAddressSubmit();
    }

    /**
     * function to dispatch address submit
     * @author Naveen
     * @created_at 31 July 2024
    */
    async function dispatchAddressSubmit() {
        const addressAdded = await getOrderAddress({ ...data });

        if (addressAdded.status === 1) {
            if (addressAdded.data.brand_category_slug === 'restaurant') {
                let redirectPath = postAuthRoutes('restaurant_menu').path;
                navigate({ pathname: redirectPath, search: '' });
            }
        }
    }

    useEffect(() => {
		if (timer > 0) {
		const countdown = setInterval(() => {
			setTimer(timer - 1);
		}, 1000);

		return () => clearInterval(countdown);
		} else {
			setShowResendLink(true); // Show the resend link when the timer reaches 0
		}
	}, [timer]);

    // Format the timer as "00:00"
	const minutes = Math.floor(timer / 60);
	const seconds = timer % 60;
	const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

	// Function to start the countdown
	const resendOtp = () => {

		dispatchResendOtp();
	};

    async function dispatchResendOtp() {
        const isOtpSend = await sendOTP({...data, booking_for: hasUrl ? data.booking_for : null, hasDelivery});
        if (isOtpSend.status === 1) {
            setData({ ...data, ...isOtpSend.data });

            setTimer(120); // Reset the timer
            setShowResendLink(false); // Hide the resend link
        }
	}

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('restaurant_auth').name}`}</title>
            </Helmet>
            <RestaurantAuthStyled>
                {brand_loading && hasConfiguration && <RestaurantAuthLoader />}
                {!brand_loading && hasConfiguration &&
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className='wrapper'>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className='wrapper-grid' >
                            <Box display="flex" alignItems="center">
                                <Box className={`wrapper-box`} >
                                    <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" >
                                        <Grid item xs={12} md={12}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {brand.logo && <CardMedia component="img" image={brand.logo} onError={e => { e.target.src = brand.logo_error; }} title={brand.name} height="60" sx={{ borderRadius: `50%`, margin: `0 !important`, border: `2px solid #fff` }} />}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6" display="flex" alignItems="center" justifyContent="center">{brand.name}</Typography>
                                        </Grid>
                                    </Grid>
                                    {!otpDiv && !addressDiv &&
                                        <form onSubmit={handleSubmit} noValidate>
                                            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" mt={1} className='mobile-screen'>
                                                <Grid item xs={12} md={12}>
                                                    <FormTelePhoneInput
                                                        tabIndex={13}
                                                        label='Mobile Number'
                                                        name='mobile_number'
                                                        value={data.mobile_number}
                                                        error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                                        onChange={handleChange}
                                                        maskType={`digit-only`}
                                                        inputProps={{ maxLength: 12 }}
                                                        autoComplete={`mobile_number`}
                                                        placeholder="Mobile number"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                {hasUrl === true &&
                                                    <Grid item xs={12} md={12}>
                                                        <FormDateTimePicker
                                                            tabIndex={8}
                                                            label='Booking For'
                                                            name='booking_for'
                                                            value={data.booking_for}
                                                            error={action.isSubmitted && errors.booking_for ? errors.booking_for : ''}
                                                            onChange={handleChange}
                                                            minDateTime={new Date(new Date().getTime() + 15 * 60000)}
                                                            disablePast={true}
                                                        />
                                                    </Grid>
                                                }

                                                {orderByVehicle === true &&
                                                    <Grid item xs={12} md={12}>
                                                        <FormInput
                                                            tabIndex={2}
                                                            label='Vehicle Number'
                                                            name='vehicle_number'
                                                            value={data.vehicle_number}
                                                            error={action.isSubmitted && errors.vehicle_number ? errors.vehicle_number : ''}
                                                            onChange={handleChange}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={12}>
                                                    <SubmitButton label={'Continue'} loading={send_otp_loading} fullWidth />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    }

                                    {otpDiv &&
                                        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" mt={1} >
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="h5" display="flex" alignItems="center" justifyContent="center">{`Verification Code`}</Typography>
                                                <Typography variant="caption" display="flex" alignItems="center" justifyContent="center">{`Please type the verification code sent to ${data.mobile_number}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <MuiOtpInput autoFocus TextFieldsProps={{ inputProps: {inputMode: 'numeric'}, placeholder: '-' }} value={otp} length={6} onChange={handleOtpChange} />
                                                {(otpErrors && otpErrors.otp) && <FormHelperText id={`error`} error>{otpErrors.otp}</FormHelperText>}

                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                                <Box textAlign="right">
                                                    {showResendLink ? (
                                                        <Link component="button" variant="body2" onClick={resendOtp} underline={'none'}>RESEND OTP</Link>
                                                    ) : (
                                                        <Typography variant="body1">
                                                            Resend OTP in: {formattedTime}
                                                        </Typography>
                                                    )}
                                                </Box>

                                                <Box textAlign="center">
                                                    <GeneralButton label={`Submit`} onClick={handleVerifyOtp}/>
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    }

                                    {addressDiv &&
                                        <form onSubmit={handleAddressSubmit} noValidate>
                                            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" mt={1} className='mobile-screen'>
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="h5" display="flex" alignItems="center" justifyContent="center">{`Delivery Address`}</Typography>
                                                    <Typography variant="caption" display="flex" alignItems="center" justifyContent="center">{`Please add your delivery address`}</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <FormInput
                                                        tabIndex={1}
                                                        label='House No, Building name'
                                                        name='house_no'
                                                        value={data.house_no}
                                                        error={action.isSubmitted && errors.house_no ? errors.house_no : ''}
                                                        onChange={handleChange}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <FormInput
                                                        tabIndex={1}
                                                        label='Road name, Area, Colony'
                                                        name='road_name'
                                                        value={data.road_name}
                                                        error={action.isSubmitted && errors.road_name ? errors.road_name : ''}
                                                        onChange={handleChange}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <FormInput
                                                        tabIndex={1}
                                                        label='Landmark/Famous shop/Mall'
                                                        name='landmark'
                                                        value={data.landmark}
                                                        error={action.isSubmitted && errors.landmark ? errors.landmark : ''}
                                                        onChange={handleChange}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} p="0">
                                                    <FormInput
                                                        tabIndex={2}
                                                        label='City'
                                                        name='city'
                                                        value={data.city}
                                                        error={action.isSubmitted && errors.city ? errors.city : ''}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} p="0">
                                                    <FormInput
                                                        tabIndex={3}
                                                        label='State'
                                                        name='state'
                                                        value={data.state}
                                                        error={action.isSubmitted && errors.state ? errors.state : ''}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} p="0">
                                                    <FormInput
                                                        tabIndex={5}
                                                        label='Pincode'
                                                        name='pincode'
                                                        value={data.pincode}
                                                        error={action.isSubmitted && errors.pincode ? errors.pincode : ''}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={12}>
                                                    <SubmitButton label={'Submit'} loading={order_address_loading} fullWidth />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {!brand_loading && !hasConfiguration && <NoData content1={`Ordering Option`} content2={`Not Available`} description={`We apologize, but this ordering option is currently not available. Please explore our other options for placing an order`} minHeight={`80vh`} />}
            </RestaurantAuthStyled>
        </Fragment>
    );
}

export { RestaurantAuth };