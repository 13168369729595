import { ErrorElement } from "../_components";
import { Home, FeaturedPromos, About, ContactUs, TermsAndConditions, PrivacyPolicy, RefundPolicy, FrequentlyAskedQuestions, CompleteProfile, SharedPromoDetails, SharedGiveawayDetails } from "../app/pre-signin";
import { ConfirmEmail } from "../app/pre-signin/confirm-email/ConfirmEmail";
import { RestaurantAuth, OrderBillDetails } from "../app/restaurant";

const allRoutes = {
    'home': {
        path: "/",
        name: "Home",
        element: <Home />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true,
        menuIcon: '',
        module: 'auth',
        isModuleMenuItem: false,
        isMainElement: true,
        errorElement: <ErrorElement />,
        menuPermission: ''
    },
    'log_in': {
        name: "Log In",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'register': {
        name: "Register",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'confirm_email': {
        path: "/confirm-email/:key",
        name: "Confirm email address",
        element: <ConfirmEmail />,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'forgot_password': {
        name: "Forgot Password",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'verify_otp': {
        name: "Verify OTP",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'reset_password': {
        name: "Reset Password",
        layout: "/pre-auth",
        isMainComponent: false,
    },
    'promos': {
        path: "/featured-promos",
        name: "Promos",
        element: <FeaturedPromos />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'about_us': {
        path: "/about-us",
        name: "About Us",
        element: <About />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'faq': {
        path: "/faq",
        name: "FAQ",
        element: <FrequentlyAskedQuestions />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'contact_us': {
        path: "/contact-us",
        name: "Contact Us",
        element: <ContactUs />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: true
    },
    'terms_and_conditions': {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        element: <TermsAndConditions />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'privacy_policy': {
        path: "/privacy-policy",
        name: "Privacy Policy",
        element: <PrivacyPolicy />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'refund_policy': {
        path: "/refund-policy",
        name: "Refund Policy",
        element: <RefundPolicy />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'complete_profile': {
        path: "/complete-profile/:key",
        name: "Complete Profile",
        element: <CompleteProfile />,
        layout: "/pre-auth",
        isMainComponent: true
    },
    'shared_promo': {
        path: "/share/promo/:id",
        name: "Promo Details",
        element: <SharedPromoDetails />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },
    'shared_giveaway': {
        path: "/share/giveaway/:id",
        name: "Giveaway Details",
        element: <SharedGiveawayDetails />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false
    },

    'restaurant_auth': {
        path: "/restaurant/auth/:brandId/:id?",
        name: "Restaurant",
        element: <RestaurantAuth />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false,
        module: 'restaurant',
    },

    'order_bill_details': {
        path: "/restaurant/order-bill-details/:orderId?",
        name: "Order Bill Details",
        element: <OrderBillDetails />,
        layout: "/pre-auth",
        isMainComponent: true,
        isMenuItem: false,
        module: 'restaurant',
    }
};


/**
 * The function returns all routes or routes of a specific key type based on the input parameter.
 * @param {String} [keyType=null] - The `keyType` parameter is an optional parameter that specifies the type of
 * key to be used for authentication. If a `keyType` is provided, the function returns only the routes
 * that require that specific type of key for authentication. If `keyType` is not provided, the
 * function returns all
 * @returns {Object} The function `preAuthRoutes` is being returned. The function takes an optional parameter
 * `keyType` and returns either all routes or routes specific to the `keyType` provided. The
 * `allRoutes` variable is likely an object containing different routes for different types of users or
 * access levels.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const preAuthRoutes = (keyType = null) => {
    return keyType ? allRoutes[keyType] : allRoutes;
};

/**
 * This function returns the name of a post element based on its path.
 * @param {String} path - The `path` parameter is a string representing the URL path of a route in a web application.
 * @returns {String} The function `getPostElementNameByPath` returns the name of the element associated with the
 * given path, which is obtained by searching for the path in the `allRoutes` object and returning the
 * corresponding `name` property. If no matching path is found, an empty string is returned.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const getPreElementNameByPath = (path) => {
    if (path) {
        const elementItem = Object.values(allRoutes).find(item => item.path === path);
        return elementItem ? elementItem.name : '';
    }
};

const preAuthMenuRoutes = (module = false) => {
    /* The below code is a JavaScript function that filters an array of objects representing routes.
    The function takes an optional argument `module`, which is used to filter the routes based on
    whether they are module menu items or regular menu items. */
    return Object.values(allRoutes).filter(item => {
        return !module
            ? (item.isMenuItem === true)
            : (item.isModuleMenuItem === true && item.module === module)
                ? { ...item, path: item.path.split("/").pop() }
                : null;
    });
};

export { preAuthRoutes, preAuthMenuRoutes, getPreElementNameByPath };