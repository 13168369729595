import radio from "../../../_root/components/form/radio";
import linearGradient from "../../../_root/functions/linearGradient";
import borders from "../../base/borders";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...radio,
  styleOverrides: {
    ...radio.styleOverrides,
    root: {
      ...radio.styleOverrides.root,
      "& .MuiSvgIcon-root": {
        ...radio.styleOverrides.root['& .MuiSvgIcon-root'],
        color: colors.transparent,
        border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
        borderRadius: "50%",
      },

      "&:after": {
        ...radio.styleOverrides.root['&:after'],
        backgroundImage: linearGradient(colors.primary.main, colors.primary.main),
      },

      "&:hover": {
        ...radio.styleOverrides.root['&:hover'],
        backgroundColor: colors.transparent,
      },

      "&.Mui-focusVisible": {
        ...radio.styleOverrides.root['&.Mui-focusVisible'],
        border: `${borders.borderWidth[2]} solid ${borders.borderColor} !important`,
      },
    },

    colorPrimary: {
      ...radio.styleOverrides.colorPrimary,
      color: colors.primary.main,

      "&.Mui-checked": {
        ...radio.styleOverrides.colorPrimary['&.Mui-checked'],
        color: colors.primary.main,

        "& .MuiSvgIcon-root": {
          ...radio.styleOverrides.colorPrimary['&.Mui-checked']['& .MuiSvgIcon-root'],
          borderColor: colors.primary.main,
        },

      },
    },

    colorSecondary: {
      ...radio.styleOverrides.colorSecondary,
      color: colors.secondary.main,

      "&.Mui-checked": {
        ...radio.styleOverrides.colorSecondary['&.Mui-checked'],
        color: colors.secondary.main,

        "& .MuiSvgIcon-root": {
          ...radio.styleOverrides.colorSecondary['&.Mui-checked']['& .MuiSvgIcon-root'],
          borderColor: colors.secondary.main,
        },

      },
    },
  },
};
