import { Card, CardContent, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GiveawayAction } from '../../../../redux/actions';
import { NoData } from '../../../../_components/layout';
import { GiveawayDetailsLoader } from './GiveawayDetailsLoader';


function GiveawayDetails() {

    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const giveawayId = params.get('gId'); // giveaway id from url

    /** Redux actions and state */
    const { giveaway_loading: isLoading, giveaway } = useSelector((state) => state.GiveawayReducer);
    const getGiveawayDetails = (params) => dispatch(GiveawayAction.getGiveawayDetails(params));

    useEffect(() => {
        getGiveawayDetails({ giveaway_id: giveawayId });
        //eslint-disable-next-line
    }, [giveawayId])


    return (
        <React.Fragment>
            {(isLoading || Object.keys(giveaway).length > 0) &&
                <Card sx={{ padding: 8 }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                            {isLoading && (<GiveawayDetailsLoader />)}
                            {!isLoading && Object.keys(giveaway).length > 0 && (
                                <CardContent>
                                    <React.Fragment>
                                        <Box>
                                            <Typography variant={`h5`} sx={{ mb: 1 }}>{giveaway.name}</Typography>
                                            <Box style={{ minHeight: `60px` }}>
                                                <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{giveaway.description}</Typography>
                                            </Box>
                                            <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${giveaway.insta_handle}`}</Typography>
                                        </Box>
                                    </React.Fragment>
                                </CardContent>
                            )
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                            {isLoading ? (
                                <Skeleton variant="rectangular" width={381} height={678} />
                            ) : (
                                <CardMedia component="img" image={giveaway.sample_story} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={giveaway.name} onError={e => { e.target.src = giveaway.sample_story_error }} />
                            )}
                        </Grid>
                    </Grid>
                </Card>
            }
            {!isLoading && Object.keys(giveaway).length === 0 && giveaway.type === 'NOT_FOUND' && <NoData content1={`Giveaway`} content2={`Not Found`} description={`If using a custom view, please go back and choose a proper giveaway.`} minHeight={`55vh`} > </NoData>}
            {!isLoading && Object.keys(giveaway).length === 0 && giveaway.type === 'NOT_AVAILABLE' && <NoData content1={`This Giveaway is`} content2={`Not Available`} description={`If using a custom view, please go back and choose a proper giveaway.You dont have the required followers for this giveaway.`} minHeight={`55vh`} > </NoData>}
        </React.Fragment>
    );
}

export { GiveawayDetails };
