import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions, Stack } from '@mui/material'

function ModalFooter({ className, children, justifyContent = 'flex-start' }) {
    return (
        <DialogActions className={`${className}`} sx={{ justifyContent: justifyContent }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                {children}
            </Stack>
        </DialogActions>
    )
}


ModalFooter.defaultProps = {
    className: ""
}


ModalFooter.propTypes = {
    className: PropTypes.string,
}

export { ModalFooter }
