import { failure, request, success } from ".";
import { PromoServices } from "../services";

export const FEATURED_PROMOS_REQUEST = 'FEATURED_PROMOS_REQUEST';
export const FEATURED_PROMOS_SUCCESS = 'FEATURED_PROMOS_SUCCESS';
export const FEATURED_PROMOS_FAILURE = 'FEATURED_PROMOS_FAILURE';

export const PROMOS_REQUEST = 'PROMOS_REQUEST';
export const PROMOS_SUCCESS = 'PROMOS_SUCCESS';
export const PROMOS_FAILURE = 'PROMOS_FAILURE';

export const PROMO_DETAIL_REQUEST = 'PROMO_DETAIL_REQUEST';
export const PROMO_DETAIL_SUCCESS = 'PROMO_DETAIL_SUCCESS';
export const PROMO_DETAIL_FAILURE = 'PROMO_DETAIL_FAILURE';

export const PROMO_INFO_REQUEST = 'PROMO_INFO_REQUEST';
export const PROMO_INFO_SUCCESS = 'PROMO_INFO_SUCCESS';
export const PROMO_INFO_FAILURE = 'PROMO_INFO_FAILURE';

export const PromoAction = {

    listFeaturedPromos: (params) => {
        return dispatch => {
            dispatch(request(FEATURED_PROMOS_REQUEST, params));
            return PromoServices.listFeaturedPromos(params)
                .then(
                    response => { return dispatch(success(FEATURED_PROMOS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(FEATURED_PROMOS_FAILURE, params, error)); }
                );
        };
    },

    listPromos: (params) => {
        return dispatch => {
            dispatch(request(PROMOS_REQUEST, params));
            return PromoServices.listPromos(params)
                .then(
                    response => { return dispatch(success(PROMOS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PROMOS_FAILURE, params, error)); }
                );
        };
    },

    getPromoDetails: (params) => {
        return dispatch => {
            dispatch(request(PROMO_DETAIL_REQUEST, params));
            return PromoServices.getPromoDetails(params)
                .then(
                    response => { return dispatch(success(PROMO_DETAIL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PROMO_DETAIL_FAILURE, params, error)); }
                );
        };
    },

    getPromoInfo: (params) => {
        return dispatch => {
            dispatch(request(PROMO_INFO_REQUEST, params));
            return PromoServices.getPromoInfo(params)
                .then(
                    response => { return dispatch(success(PROMO_INFO_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PROMO_INFO_FAILURE, params, error)); }
                );
        };
    }

};
