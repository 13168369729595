import React from 'react'
import { styled, Grid } from '@mui/material';

const NoDataStyled = styled(({ ...props }) => (<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" {...props} />))`
	& img {
		width:  ${props => `${props.theme.functions.pxToRem(250)}`};
		margin-bottom:${props => props.theme.spacing(2)};
		display : flex;
		align-items:center;
		justify-content:center;
	}

	& .content-1 {
		color: ${props => props.theme.palette.primary.main} !important;
	}

	& .content-2 {
		color: ${props => props.theme.palette.error.main} !important;
	}

`;

export { NoDataStyled }

