
import colors from "./colors";
import boxshadows from "../../_root/base/boxshadows";
import boxShadow from "../../_root/functions/boxShadow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  xs: `${boxShadow([boxshadows.boxShadowPalette.xs.hOffset, boxshadows.boxShadowPalette.xs.vOffset], [boxshadows.boxShadowPalette.xs.blur, boxshadows.boxShadowPalette.xs.spread], colors.common.black, boxshadows.boxShadowPalette.xs.opacity)},${boxShadow([boxshadows.boxShadowPalette.xs.hOffsetOne, boxshadows.boxShadowPalette.xs.vOffsetOne], [boxshadows.boxShadowPalette.xs.blurOne, boxshadows.boxShadowPalette.xs.spreadOne], colors.common.black, boxshadows.boxShadowPalette.xs.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.xs.hOffsetTwo, boxshadows.boxShadowPalette.xs.vOffsetTwo], [boxshadows.boxShadowPalette.xs.blurTwo, boxshadows.boxShadowPalette.xs.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.xs.opacityTwo)}`,
  sm: `${boxShadow([boxshadows.boxShadowPalette.sm.hOffset, boxshadows.boxShadowPalette.sm.vOffset], [boxshadows.boxShadowPalette.sm.blur, boxshadows.boxShadowPalette.sm.spread], colors.common.black, boxshadows.boxShadowPalette.sm.opacity)},${boxShadow([boxshadows.boxShadowPalette.sm.hOffsetOne, boxshadows.boxShadowPalette.sm.vOffsetOne], [boxshadows.boxShadowPalette.sm.blurOne, boxshadows.boxShadowPalette.sm.spreadOne], colors.common.black, boxshadows.boxShadowPalette.sm.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.sm.hOffsetTwo, boxshadows.boxShadowPalette.sm.vOffsetTwo], [boxshadows.boxShadowPalette.sm.blurTwo, boxshadows.boxShadowPalette.sm.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.sm.opacityTwo)}`,
  md: `${boxShadow([boxshadows.boxShadowPalette.md.hOffset, boxshadows.boxShadowPalette.md.vOffset], [boxshadows.boxShadowPalette.md.blur, boxshadows.boxShadowPalette.md.spread], colors.common.black, boxshadows.boxShadowPalette.md.opacity)},${boxShadow([boxshadows.boxShadowPalette.md.hOffsetOne, boxshadows.boxShadowPalette.md.vOffsetOne], [boxshadows.boxShadowPalette.md.blurOne, boxshadows.boxShadowPalette.md.spreadOne], colors.common.black, boxshadows.boxShadowPalette.md.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.md.hOffsetTwo, boxshadows.boxShadowPalette.md.vOffsetTwo], [boxshadows.boxShadowPalette.md.blurTwo, boxshadows.boxShadowPalette.md.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.md.opacityTwo)}`,
  lg: `${boxShadow([boxshadows.boxShadowPalette.lg.hOffset, boxshadows.boxShadowPalette.lg.vOffset], [boxshadows.boxShadowPalette.lg.blur, boxshadows.boxShadowPalette.lg.spread], colors.common.black, boxshadows.boxShadowPalette.lg.opacity)},${boxShadow([boxshadows.boxShadowPalette.lg.hOffsetOne, boxshadows.boxShadowPalette.lg.vOffsetOne], [boxshadows.boxShadowPalette.lg.blurOne, boxshadows.boxShadowPalette.lg.spreadOne], colors.common.black, boxshadows.boxShadowPalette.lg.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.lg.hOffsetTwo, boxshadows.boxShadowPalette.lg.vOffsetTwo], [boxshadows.boxShadowPalette.lg.blurTwo, boxshadows.boxShadowPalette.lg.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.lg.opacityTwo)}`,
  xl: `${boxShadow([boxshadows.boxShadowPalette.xl.hOffset, boxshadows.boxShadowPalette.xl.vOffset], [boxshadows.boxShadowPalette.xl.blur, boxshadows.boxShadowPalette.xl.spread], colors.common.black, boxshadows.boxShadowPalette.xl.opacity)},${boxShadow([boxshadows.boxShadowPalette.xl.hOffsetOne, boxshadows.boxShadowPalette.xl.vOffsetOne], [boxshadows.boxShadowPalette.xl.blurOne, boxshadows.boxShadowPalette.xl.spreadOne], colors.common.black, boxshadows.boxShadowPalette.xl.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.xl.hOffsetTwo, boxshadows.boxShadowPalette.xl.vOffsetTwo], [boxshadows.boxShadowPalette.xl.blurTwo, boxshadows.boxShadowPalette.xl.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.xl.opacityTwo)}`,
  xxl: `${boxShadow([boxshadows.boxShadowPalette.xxl.hOffset, boxshadows.boxShadowPalette.xxl.vOffset], [boxshadows.boxShadowPalette.xxl.blur, boxshadows.boxShadowPalette.xxl.spread], colors.common.black, boxshadows.boxShadowPalette.xxl.opacity)},${boxShadow([boxshadows.boxShadowPalette.xxl.hOffsetOne, boxshadows.boxShadowPalette.xxl.vOffsetOne], [boxshadows.boxShadowPalette.xxl.blurOne, boxshadows.boxShadowPalette.xxl.spreadOne], colors.common.black, boxshadows.boxShadowPalette.xxl.opacityOne)},${boxShadow([boxshadows.boxShadowPalette.xxl.hOffsetTwo, boxshadows.boxShadowPalette.xxl.vOffsetTwo], [boxshadows.boxShadowPalette.xxl.blurTwo, boxshadows.boxShadowPalette.xxl.spreadTwo], colors.common.black, boxshadows.boxShadowPalette.xxl.opacityTwo)}`,
  inset: `${boxShadow([boxshadows.boxShadowPalette.inset.hOffset, boxshadows.boxShadowPalette.inset.vOffset], [boxshadows.boxShadowPalette.inset.blur, boxshadows.boxShadowPalette.inset.spread], colors.common.black, boxshadows.boxShadowPalette.inset.opacity)}`,

  colored: {
    primary: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.primary, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.primary, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.primary, 0.12)}`,
    secondary: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.secondary, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.secondary, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.secondary, 0.15)}`,
    info: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.info, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.info, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.info, 0.12)}`,
    success: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.success, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.success, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.success, 0.12)}`,
    warning: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.warning, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.warning, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.warning, 0.12)}`,
    error: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.error, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.error, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.error, 0.12)}`,
    light: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.light, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.light, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.light, 0.12)}`,
    dark: `${boxShadow([0, 2], [1, -1], colors.coloredShadows.dark, 0.2)}, ${boxShadow([0, 1], [1, 0], colors.coloredShadows.dark, 0.14)}, ${boxShadow([0, 1], [3, 0], colors.coloredShadows.dark, 0.12)}`,
  },

  navbarBoxShadow: `${boxShadow([0, 0], [1, 1], colors.common.white, 0.9, "inset")}, ${boxShadow([0, 20], [27, 0], colors.common.black, 0.05)}`,
  sliderBoxShadow: { thumb: boxShadow([0, 1], [13, 0], colors.common.black, 0.2), },
};
