import React from 'react';
import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';


const GiveawayPaper = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
        & .MuiSvgIcon-root {
            color: ${props => props.theme.palette.secondary.main};
            background: rgba(255, 255, 255, 0.64);
            border-radius: 50%;
            padding: 3px;
        }
        & .MuiTypography-root {
            color: ${props => props.theme.palette.white.main};
        }
` ;

function GiveawayCardLoader({ ...props }) {



    var rows = [];
    for (var i = 0; i < 12; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={i}  {...props}>
                <GiveawayPaper {...props} >
                    <CardContent style={{ height: `544px`, top: 0, right: 0, left: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                        <Box style={{ position: `absolute`, bottom: 0, left: 0, right: 0, padding: 30, width: `100%`, backgroundImage: "linear-gradient(360deg, rgba(0, 0, 0, 0.8) 40%, rgba(51, 51, 53, 0) 100%)" }}>
                            <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 5 }} />
                            <Box style={{ minHeight: `120px` }}>
                                <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="85%" style={{ marginBottom: 5 }} />
                                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                            </Box>
                            <Skeleton animation="wave" height={10} width="40%" />
                        </Box>
                    </CardContent>
                </GiveawayPaper>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { GiveawayCardLoader };
