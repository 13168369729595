import styled from '@emotion/styled';
import PropTypes from 'prop-types'

const LogoBox = styled(({ ...props }) => (
    <svg viewBox="0 0 1344 569" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
        <path d="M962.434 303.45V273H993.784V303.45H962.434Z" fill="#3483D2" />
        <path d="M718.52 303.45V273H749.87V303.45H718.52Z" fill="#EA3661" />
        <path d="M606.35 496.5C591.25 496.5 578.2 492.75 567.2 485.25C556.3 477.75 547.85 467.55 541.85 454.65C535.95 441.65 533 427.05 533 410.85C533 394.55 536 380 542 367.2C548 354.3 556.5 344.15 567.5 336.75C578.6 329.25 591.75 325.5 606.95 325.5C622.25 325.5 635.1 329.25 645.5 336.75C656 344.15 663.9 354.3 669.2 367.2C674.6 380.1 677.3 394.65 677.3 410.85C677.3 427.05 674.6 441.6 669.2 454.5C663.8 467.4 655.85 477.65 645.35 485.25C634.85 492.75 621.85 496.5 606.35 496.5ZM610.7 468.15C621 468.15 629.4 465.7 635.9 460.8C642.4 455.9 647.15 449.15 650.15 440.55C653.15 431.95 654.65 422.05 654.65 410.85C654.65 399.65 653.1 389.75 650 381.15C647 372.55 642.3 365.85 635.9 361.05C629.6 356.25 621.55 353.85 611.75 353.85C601.35 353.85 592.8 356.4 586.1 361.5C579.4 366.6 574.4 373.45 571.1 382.05C567.9 390.65 566.3 400.25 566.3 410.85C566.3 421.55 567.9 431.25 571.1 439.95C574.4 448.55 579.3 455.4 585.8 460.5C592.4 465.6 600.7 468.15 610.7 468.15ZM654.65 492V375.45H651.05V276H682.55V492H654.65Z" fill="#2C5285" />
        <path d="M718.52 492V330H749.87V492H718.52Z" fill="#2C5285" />
        <path d="M852.664 568.5C843.964 568.5 835.514 567.15 827.314 564.45C819.214 561.75 811.814 557.75 805.114 552.45C798.414 547.25 792.864 540.8 788.464 533.1L817.414 518.4C820.914 525.3 825.914 530.35 832.414 533.55C838.914 536.75 845.764 538.35 852.964 538.35C861.864 538.35 869.464 536.75 875.764 533.55C882.064 530.45 886.814 525.75 890.014 519.45C893.314 513.15 894.914 505.35 894.814 496.05V450.75H898.564V330H926.314V496.35C926.314 500.65 926.164 504.75 925.864 508.65C925.564 512.55 925.014 516.45 924.214 520.35C921.914 531.25 917.514 540.25 911.014 547.35C904.614 554.45 896.414 559.75 886.414 563.25C876.514 566.75 865.264 568.5 852.664 568.5ZM850.264 496.5C835.164 496.5 822.114 492.75 811.114 485.25C800.214 477.75 791.764 467.55 785.764 454.65C779.864 441.65 776.914 427.05 776.914 410.85C776.914 394.55 779.914 380 785.914 367.2C791.914 354.3 800.414 344.15 811.414 336.75C822.514 329.25 835.664 325.5 850.864 325.5C866.164 325.5 879.014 329.25 889.414 336.75C899.914 344.15 907.814 354.3 913.114 367.2C918.514 380.1 921.214 394.65 921.214 410.85C921.214 427.05 918.514 441.6 913.114 454.5C907.714 467.4 899.764 477.65 889.264 485.25C878.764 492.75 865.764 496.5 850.264 496.5ZM854.614 468.15C864.914 468.15 873.314 465.7 879.814 460.8C886.314 455.9 891.064 449.15 894.064 440.55C897.064 431.95 898.564 422.05 898.564 410.85C898.564 399.65 897.014 389.75 893.914 381.15C890.914 372.55 886.214 365.85 879.814 361.05C873.514 356.25 865.464 353.85 855.664 353.85C845.264 353.85 836.714 356.4 830.014 361.5C823.314 366.6 818.314 373.45 815.014 382.05C811.814 390.65 810.214 400.25 810.214 410.85C810.214 421.55 811.814 431.25 815.014 439.95C818.314 448.55 823.214 455.4 829.714 460.5C836.314 465.6 844.614 468.15 854.614 468.15Z" fill="#2C5285" />
        <path d="M962.434 492V330H993.784V492H962.434Z" fill="#2C5285" />
        <path d="M1122.53 492C1112.33 494 1102.33 494.85 1092.53 494.55C1082.73 494.25 1073.98 492.35 1066.28 488.85C1058.58 485.35 1052.78 479.85 1048.88 472.35C1045.38 465.65 1043.48 458.85 1043.18 451.95C1042.98 444.95 1042.88 437.05 1042.88 428.25V285H1074.38V426.75C1074.38 433.25 1074.43 438.9 1074.53 443.7C1074.73 448.5 1075.78 452.55 1077.68 455.85C1081.28 462.05 1086.98 465.6 1094.78 466.5C1102.68 467.3 1111.93 466.95 1122.53 465.45V492ZM1011.83 355.2V330H1122.53V355.2H1011.83Z" fill="#2C5285" />
        <path d="M1193.43 496.5C1181.43 496.5 1171.38 494.3 1163.28 489.9C1155.18 485.4 1149.03 479.5 1144.83 472.2C1140.73 464.8 1138.68 456.7 1138.68 447.9C1138.68 439.7 1140.13 432.5 1143.03 426.3C1145.93 420.1 1150.23 414.85 1155.93 410.55C1161.63 406.15 1168.63 402.6 1176.93 399.9C1184.13 397.8 1192.28 395.95 1201.38 394.35C1210.48 392.75 1220.03 391.25 1230.03 389.85C1240.13 388.45 1250.13 387.05 1260.03 385.65L1248.63 391.95C1248.83 379.25 1246.13 369.85 1240.53 363.75C1235.03 357.55 1225.53 354.45 1212.03 354.45C1203.53 354.45 1195.73 356.45 1188.63 360.45C1181.53 364.35 1176.58 370.85 1173.78 379.95L1144.53 370.95C1148.53 357.05 1156.13 346 1167.33 337.8C1178.63 329.6 1193.63 325.5 1212.33 325.5C1226.83 325.5 1239.43 328 1250.13 333C1260.93 337.9 1268.83 345.7 1273.83 356.4C1276.43 361.7 1278.03 367.3 1278.63 373.2C1279.23 379.1 1279.53 385.45 1279.53 392.25V492H1251.78V454.95L1257.18 459.75C1250.48 472.15 1241.93 481.4 1231.53 487.5C1221.23 493.5 1208.53 496.5 1193.43 496.5ZM1198.98 470.85C1207.88 470.85 1215.53 469.3 1221.93 466.2C1228.33 463 1233.48 458.95 1237.38 454.05C1241.28 449.15 1243.83 444.05 1245.03 438.75C1246.73 433.95 1247.68 428.55 1247.88 422.55C1248.18 416.55 1248.33 411.75 1248.33 408.15L1258.53 411.9C1248.63 413.4 1239.63 414.75 1231.53 415.95C1223.43 417.15 1216.08 418.35 1209.48 419.55C1202.98 420.65 1197.18 422 1192.08 423.6C1187.78 425.1 1183.93 426.9 1180.53 429C1177.23 431.1 1174.58 433.65 1172.58 436.65C1170.68 439.65 1169.73 443.3 1169.73 447.6C1169.73 451.8 1170.78 455.7 1172.88 459.3C1174.98 462.8 1178.18 465.6 1182.48 467.7C1186.78 469.8 1192.28 470.85 1198.98 470.85Z" fill="#2C5285" />
        <path d="M1312.48 492V271.5H1343.83V492H1312.48Z" fill="#2C5285" />
        <path d="M895.32 216V53.9999H923.22V93.2999L919.32 88.1999C921.32 82.9999 923.92 78.2499 927.12 73.9499C930.32 69.5499 934.02 65.9499 938.22 63.1499C942.32 60.1499 946.87 57.8499 951.87 56.2499C956.97 54.5499 962.17 53.5499 967.47 53.2499C972.77 52.8499 977.87 53.0999 982.77 53.9999V83.3999C977.47 81.9999 971.57 81.5999 965.07 82.1999C958.67 82.7999 952.77 84.8499 947.37 88.3499C942.27 91.6499 938.22 95.6499 935.22 100.35C932.32 105.05 930.22 110.3 928.92 116.1C927.62 121.8 926.97 127.85 926.97 134.25V216H895.32Z" fill="#EA3661" />
        <path d="M1072.39 220.35C1061.19 220.35 1051.79 218.55 1044.19 214.95C1036.59 211.35 1030.39 206.65 1025.59 200.85C1020.89 194.95 1017.29 188.55 1014.79 181.65C1012.29 174.75 1010.59 168 1009.69 161.4C1008.79 154.8 1008.34 149 1008.34 144V53.9999H1040.14V133.65C1040.14 139.95 1040.64 146.45 1041.64 153.15C1042.74 159.75 1044.74 165.9 1047.64 171.6C1050.64 177.3 1054.79 181.9 1060.09 185.4C1065.49 188.9 1072.49 190.65 1081.09 190.65C1086.69 190.65 1091.99 189.75 1096.99 187.95C1101.99 186.05 1106.34 183.05 1110.04 178.95C1113.84 174.85 1116.79 169.45 1118.89 162.75C1121.09 156.05 1122.19 147.9 1122.19 138.3L1141.69 145.65C1141.69 160.35 1138.94 173.35 1133.44 184.65C1127.94 195.85 1120.04 204.6 1109.74 210.9C1099.44 217.2 1086.99 220.35 1072.39 220.35ZM1125.94 216V169.2H1122.19V53.9999H1153.84V216H1125.94Z" fill="#EA3661" />
        <path d="M1309.61 216V136.35C1309.61 130.05 1309.06 123.6 1307.96 117C1306.96 110.3 1304.96 104.1 1301.96 98.3999C1299.06 92.6999 1294.91 88.0999 1289.51 84.5999C1284.21 81.0999 1277.26 79.3499 1268.66 79.3499C1263.06 79.3499 1257.76 80.2999 1252.76 82.1999C1247.76 83.9999 1243.36 86.9499 1239.56 91.0499C1235.86 95.1499 1232.91 100.55 1230.71 107.25C1228.61 113.95 1227.56 122.1 1227.56 131.7L1208.06 124.35C1208.06 109.65 1210.81 96.6999 1216.31 85.4999C1221.81 74.1999 1229.71 65.3999 1240.01 59.0999C1250.31 52.7999 1262.76 49.6499 1277.36 49.6499C1288.56 49.6499 1297.96 51.4499 1305.56 55.0499C1313.16 58.6499 1319.31 63.3999 1324.01 69.2999C1328.81 75.0999 1332.46 81.4499 1334.96 88.3499C1337.46 95.2499 1339.16 102 1340.06 108.6C1340.96 115.2 1341.41 121 1341.41 126V216H1309.61ZM1195.76 216V53.9999H1223.81V100.8H1227.56V216H1195.76Z" fill="#EA3661" />
        <path d="M587.75 220.5C575.75 220.5 565.7 218.3 557.6 213.9C549.5 209.4 543.35 203.5 539.15 196.2C535.05 188.8 533 180.7 533 171.9C533 163.7 534.45 156.5 537.35 150.3C540.25 144.1 544.55 138.85 550.25 134.55C555.95 130.15 562.95 126.6 571.25 123.9C578.45 121.8 586.6 119.95 595.7 118.35C604.8 116.75 614.35 115.25 624.35 113.85C634.45 112.45 644.45 111.05 654.35 109.65L642.95 115.95C643.15 103.25 640.45 93.85 634.85 87.75C629.35 81.55 619.85 78.45 606.35 78.45C597.85 78.45 590.05 80.45 582.95 84.45C575.85 88.35 570.9 94.85 568.1 103.95L538.85 94.95C542.85 81.05 550.45 70 561.65 61.8C572.95 53.6 587.95 49.5 606.65 49.5C621.15 49.5 633.75 52 644.45 57C655.25 61.9 663.15 69.7 668.15 80.4C670.75 85.7 672.35 91.3 672.95 97.2C673.55 103.1 673.85 109.45 673.85 116.25V216H646.1V178.95L651.5 183.75C644.8 196.15 636.25 205.4 625.85 211.5C615.55 217.5 602.85 220.5 587.75 220.5ZM593.3 194.85C602.2 194.85 609.85 193.3 616.25 190.2C622.65 187 627.8 182.95 631.7 178.05C635.6 173.15 638.15 168.05 639.35 162.75C641.05 157.95 642 152.55 642.2 146.55C642.5 140.55 642.65 135.75 642.65 132.15L652.85 135.9C642.95 137.4 633.95 138.75 625.85 139.95C617.75 141.15 610.4 142.35 603.8 143.55C597.3 144.65 591.5 146 586.4 147.6C582.1 149.1 578.25 150.9 574.85 153C571.55 155.1 568.9 157.65 566.9 160.65C565 163.65 564.05 167.3 564.05 171.6C564.05 175.8 565.1 179.7 567.2 183.3C569.3 186.8 572.5 189.6 576.8 191.7C581.1 193.8 586.6 194.85 593.3 194.85Z" fill="#3483D2" />
        <path d="M777.151 220.5C762.051 220.5 749.001 216.75 738.001 209.25C727.101 201.75 718.651 191.55 712.651 178.65C706.751 165.65 703.801 151.05 703.801 134.85C703.801 118.55 706.801 104 712.801 91.2C718.801 78.3 727.301 68.15 738.301 60.75C749.401 53.25 762.551 49.5 777.751 49.5C793.051 49.5 805.901 53.25 816.301 60.75C826.801 68.15 834.701 78.3 840.001 91.2C845.401 104.1 848.101 118.65 848.101 134.85C848.101 151.05 845.401 165.6 840.001 178.5C834.601 191.4 826.651 201.65 816.151 209.25C805.651 216.75 792.651 220.5 777.151 220.5ZM781.501 192.15C791.801 192.15 800.201 189.7 806.701 184.8C813.201 179.9 817.951 173.15 820.951 164.55C823.951 155.95 825.451 146.05 825.451 134.85C825.451 123.65 823.901 113.75 820.801 105.15C817.801 96.55 813.101 89.85 806.701 85.05C800.401 80.25 792.351 77.85 782.551 77.85C772.151 77.85 763.601 80.4 756.901 85.5C750.201 90.6 745.201 97.45 741.901 106.05C738.701 114.65 737.101 124.25 737.101 134.85C737.101 145.55 738.701 155.25 741.901 163.95C745.201 172.55 750.101 179.4 756.601 184.5C763.201 189.6 771.501 192.15 781.501 192.15ZM825.451 216V99.45H821.851V0H853.351V216H825.451Z" fill="#3483D2" />
        <path d="M251.5 64L335 211H168L251.5 64Z" fill="#3483D2" />
        <path d="M167.5 211L251 64H84L167.5 211Z" fill="#0AAEF2" />
        <path d="M167.5 505L251 358H84L167.5 505Z" fill="#E97E98" />
        <path d="M334.5 505L418 358H251L334.5 505Z" fill="#2C5285" />
        <path d="M334.5 211L418 358H251L334.5 211Z" fill="#EA3661" />
        <path d="M418.5 358L502 505H335L418.5 358Z" fill="#3483D2" />
        <path d="M83.5 358L167 505H0L83.5 358Z" fill="#EA3661" />
    </svg>
))`
        & .light {
            fill: ${props => (props.fill) ? (props.fill) : (props.theme.palette.mode === 'dark') ? (props.theme.palette.light.main) : (props.theme.palette.dark.main)};
        }
` ;


const Logo = ({ height, ...props }) => {
    return (
        <LogoBox height={height} {...props} />
    )
}

Logo.defaultProps = {
    height: "40",
}


Logo.propTypes = {
    props: PropTypes.object,
    height: PropTypes.string,
}


export { Logo }
