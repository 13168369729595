import { Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { CouponCodesCard, CouponCodesCardLoader } from '.'
import { CouponCodesAction } from '../../../redux/actions/CouponCodesAction';
import { postAuthRoutes } from '../../../routes';
import { CardHeader, FormSearchInput, NoData } from '../../../_components';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

function CouponCodes() {

    // const classes = useStyles();
    const dispatch = useDispatch();
    const { search } = useLocation();
    let navigate = useNavigate();
    const searchParams = new URLSearchParams(search);

    /** Redux actions and state */
    const { promos_loading: isLoading, coupon_codes } = useSelector((state) => state.CouponCodesReducer);
    const listCouponCodes = (params) => dispatch(CouponCodesAction.listCouponCodes(params));


    const [payload, setPayload] = useState({
        limit: 100, // Number of records that are displayed when a page loads
        page: 1, // Page number to load
        search: searchParams.get('q') ?? '', // Search key to check for word anywhere in the record
    });

    useEffect(() => {

        /* Read the payload and append the parameters to the url for proper reload */
        const queryParam = {};
        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;

        navigate({
            pathname: postAuthRoutes('coupon_codes').path,
            search: `?${queryString.stringify(queryParam)}`
        });

        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.search])


    const getNext = async () => {
        const result = await listCouponCodes({ ...payload });
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
    * function to apply the search key words on the promo list
    * @param {String} text Search keyword used
    * @return view
    * @author Akshay N
    * @created_at 28 May 2022
    */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }


    return (
        <React.Fragment>
            <CardHeader
                title={postAuthRoutes('coupon_codes').name}
                action={
                    <Stack spacing={1} direction="row" alignItems={`center`} >
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                    </Stack>
                } />
            <InfiniteScroll dataLength={coupon_codes.length} next={getNext} hasMore={true}>
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        coupon_codes.map((coupon_code, index) => {
                            return <Grid item xs={6} sm={6} md={3} lg={3} xl={3}><CouponCodesCard key={index} {...coupon_code} /></Grid>
                        })
                    }
                    {isLoading && <CouponCodesCardLoader />}
                    {!isLoading && coupon_codes.length === 0 && <NoData content1={`Coupon Codes`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`80vh`} />}
                </Grid>
            </InfiniteScroll>
        </React.Fragment>
    )
}

export { CouponCodes }