import React from 'react'
import { Box, CardContent, Divider, Skeleton } from '@mui/material'

function PromoDetailsLoader() {
    return (
        <CardContent style={{ width: `100%` }}>
            <Box>
                <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 5 }} />
                <Box style={{ minHeight: `60px` }}>
                    <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                    <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                    <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
                <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                <Box>
                    <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 5 }} />
                    <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                    <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 5 }} />
                </Box>
            </Box>
        </CardContent>
    )
}

export { PromoDetailsLoader }