import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      height: pxToRem(6),
      borderRadius: borders.borderRadius.md,
      overflow: "visible",
      position: "relative",
    },

    bar: {
      height: pxToRem(6),
      borderRadius: borders.borderRadius.md,
      position: "absolute",
      transform: `translate(0, 0) !important`,
      transition: "width 0.6s ease !important",
    },
  },
  variants: []
};
