import { apiServices } from '../../_helpers';
import queryString from 'query-string';


export const CouponCodesServices = {

    /**
     * Service for list coupon_codes
     * @author Akshay 
     */
    listCouponCodes: (params) => {

        let queryParam = { ...params };
        queryParam.page = params.page;
        if (params.search) queryParam.search = params.search; else delete queryParam.search;

        return apiServices.get(`/influencer/coupon-codes?${queryString.stringify(queryParam)}`)
            .then((response) => { return response; })
    },
}