import stepIcon from "../../../_root/components/stepper/stepIcon";
import boxShadow from "../../../_root/functions/boxShadow";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...stepIcon,
  styleOverrides: {
    ...stepIcon.styleOverrides,
    root: {
      ...stepIcon.styleOverrides.root,
      background: colors.primary.main,
      fill: colors.primary.main,
      stroke: colors.primary.main,

      "&.Mui-active": {
        ...stepIcon.styleOverrides.root['&.Mui-active'],
        background: colors.common.white,
        fill: colors.common.white,
        stroke: colors.common.white,
        borderColor: colors.common.white,
        boxShadow: boxShadow([0, 0], [0, 2], colors.common.white, 1),
      },

      "&.Mui-completed": {
        ...stepIcon.styleOverrides.root['&.Mui-completed'],
        background: colors.common.white,
        fill: colors.common.white,
        stroke: colors.common.white,
        borderColor: colors.common.white,
        boxShadow: boxShadow([0, 0], [0, 2], colors.common.white, 1),
      },
    },
  },
};
