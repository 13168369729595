import { apiServices, cookieServices } from '../../_helpers';


export const RestaurantServices = {

    sendOTP: (params) => {
        return apiServices.post('/influencer/restaurant/send-otp', params)
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            });
    },

    verifyOtp: (params) => {
        return apiServices.post('/influencer/restaurant/verify-otp', params)
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (0.5 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('accessToken', response.data.token, options);
                cookieServices.set('brandId', response.data.brand_id, options);
                cookieServices.set('orderId', response.data.order_id, options);
                cookieServices.set('tableId', response.data.table_id, options);
                cookieServices.set('currencySymbol', response.data.currency.html, options);
                return response;
            });
    },

    getMenuList: (params) => {
        return apiServices.post('/influencer/restaurant/menu/list', params)
            .then((response) => { return response; });
    },

    getItemDetails: (params) => {
        return apiServices.post('/influencer/restaurant/menu/item', params)
            .then((response) => { return response; });
    },

    addToCart: (params) => {
        return apiServices.post('/influencer/restaurant/cart/add', params)
            .then((response) => { return response; });
    },

    updateCartItemQuantity: (params) => {
        return apiServices.post('/influencer/restaurant/cart/update-quantity', params)
            .then((response) => { return response; });
    },

    removeItemFromCart: (params) => {
        return apiServices.post('/influencer/restaurant/cart/remove', params)
            .then((response) => { return response; });
    },

    getCartList: (params) => {
        return apiServices.post('/influencer/restaurant/cart/list', params)
            .then((response) => { return response; });
    },

    placeOrder: (params) => {
        return apiServices.post('/influencer/restaurant/order/place', params)
            .then((response) => { return response; });
    },

    getOrderList: (params) => {
        return apiServices.post('/influencer/restaurant/order/list', params)
            .then((response) => { return response; });
    },

    orderBill: (params) => {
        return apiServices.post('/influencer/restaurant/order/bill/request', params)
            .then((response) => { return response; });
    },

    /**
	 * Service for Category select list
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 15 May 2024
	 */
	menuSelectList: (params) => {
		return apiServices.post('/influencer/restaurant/menu/select-list', params).then((response) => {
			return response;
		});
	},

    /**
	 * Service for add order address details
	 * @param {Object} params - The params which are used for the api
	 * @author Naveen K
	 * @created_at 03 Jun 2024
	 */
    getOrderAddress: (params) => {
        return apiServices.post('/influencer/restaurant/get-order-address', params)
            .then((response) => { return response; });
    },

    /**
     * Service to get order bill details
     * @param {Object} params - The params which are used for the api
     * @author Naveen
     * @created_at 06 Jun 2024
    */
    getOrderBillDetails: (params) => {
        return apiServices.post('/influencer/restaurant/order/order-bill-details', params)
            .then((response) => { return response; });

    },

};
