import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: `${pxToRem(32)} ${pxToRem(32)} ${pxToRem(16)}`,
    },
  },
  variants: []
};
