import slider from "../../../_root/components/slider/slider";
import borders from "../../base/borders";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...slider,
  styleOverrides: {
    ...slider.styleOverrides,
    root: {
      ...slider.styleOverrides.root,
      "& .MuiSlider-valueLabel": {
        ...slider.styleOverrides.root['& .MuiSlider-valueLabel'],
        color: colors.black,
      },
    },

    rail: {
      ...slider.styleOverrides.rail,
      background: colors.grey[200],
    },

    track: {
      ...slider.styleOverrides.track,
      background: colors.primary.main,
    },

    thumb: {
      ...slider.styleOverrides.thumb,
      backgroundColor: colors.common.white,
      border: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
      boxShadow: shadows.sliderBoxShadow.thumb,


    },

  },
};
