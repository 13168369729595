// @mui material components
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default styled(Box)(({ theme, ownerState }) => {
	const { palette, functions, borders, shadows } = theme;
	const { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow } = ownerState;
	const { gradients, grey, white } = palette;
	const { linearGradient } = functions;
	const { borderRadius: radius } = borders;
	const { colored } = shadows;

	/* `greyColors` is an object that maps the different shades of grey in the theme's `palette.grey`
	object to their corresponding keys. This allows the styled component to easily access the
	different shades of grey by their key names, such as `greyColors["grey-500"]` to get the value of
	the `palette.grey[500]` color. This is used in the component's logic to determine the background
	and color values based on the `bgColor` and `color` props passed to the component. */
	const greyColors = {
		'grey-100': grey[100],
		'grey-200': grey[200],
		'grey-300': grey[300],
		'grey-400': grey[400],
		'grey-500': grey[500],
		'grey-600': grey[600],
		'grey-700': grey[700],
		'grey-800': grey[800],
		'grey-900': grey[900],
	};

	/* `const validGradients` is an array that contains the valid gradient values that can be used as
	the `bgColor` property in the styled component. These values are used to determine whether the
	`bgColor` value is a valid gradient or not, and if it is, the corresponding gradient is applied
	to the background of the component. */
	const validGradients = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light'];

	/* `const validColors` is an array that contains the valid color values that can be used as the
	`bgColor` and `color` properties in the styled component. These values are used to determine
	whether the `bgColor` and `color` values passed to the component are valid or not. If they are
	valid, the corresponding color is applied to the background or text color of the component. If
	they are not valid, the `bgColor` or `color` value passed to the component is used as is. */
	const validColors = ['transparent', 'white', 'black', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark', 'text', 'grey-100', 'grey-200', 'grey-300', 'grey-400', 'grey-500', 'grey-600', 'grey-700', 'grey-800', 'grey-900'];

	/* `const validBorderRadius` is an array that contains the valid values that can be used as the
	`borderRadius` property in the styled component. These values are used to determine whether the
	`borderRadius` value passed to the component is valid or not. If it is valid, the corresponding
	border radius value is applied to the component. If it is not valid, the `borderRadius` value
	passed to the component is used as is. The values in the array correspond to the different
	predefined border radius values in the theme's `borders` object. */
	const validBorderRadius = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'section'];

	/* `const validshadows` is an array that contains the valid values that can be used as the
	`shadow` property in the styled component. These values are used to determine whether the
	`shadow` value passed to the component is valid or not. If it is valid, the corresponding box
	shadow value is applied to the component. If it is not valid, no box shadow is applied to the
	component. The values in the array correspond to the different predefined box shadow values in
	the theme's `shadows` object. */
	const validshadows = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset'];

	/* This code block is determining the background color value for the styled component based on the
	`bgColor` and `variant` props passed to the component. */
	let backgroundValue = bgColor;
	if (variant === 'gradient') {
		backgroundValue = validGradients.find((el) => el === bgColor) ? linearGradient(gradients[bgColor].main, gradients[bgColor].state) : white;
	} else if (validColors.find((el) => el === bgColor)) {
		backgroundValue = palette[bgColor] ? palette[bgColor].main : greyColors[bgColor];
	} else {
		backgroundValue = bgColor;
	}

	/* This code block is determining the text color value for the styled component based on the
	`color` prop passed to the component. It first initializes a variable `colorValue` with the
	value of the `color` prop. It then checks if the `color` prop is a valid value (found in the
	`validColors` array). If it is a valid value, it sets the `colorValue` variable to the
	corresponding color value from the theme's `palette` object (if it exists) or the `greyColors`
	object. If the `color` prop is not a valid value, the `colorValue` variable remains unchanged
	and will be used as is for the text color of the component. */
	let colorValue = color;
	if (validColors.find((el) => el === color)) {
		colorValue = palette[color] ? palette[color].main : greyColors[color];
	}

	/* This code block is determining the value of the `borderRadiusValue` variable for the styled
	component based on the `borderRadius` prop passed to the component. */
	let borderRadiusValue = borderRadius;
	if (validBorderRadius.find((el) => el === borderRadius)) {
		borderRadiusValue = radius[borderRadius];
	}

	/* This code block is determining the value of the `boxShadowValue` variable for the styled component
	based on the `shadow` and `coloredShadow` props passed to the component. */
	let boxShadowValue = 'none';
	if (validshadows.find((el) => el === shadow)) {
		boxShadowValue = shadows[shadow];
	} else if (coloredShadow) {
		boxShadowValue = colored[coloredShadow] ? colored[coloredShadow] : 'none';
	}

	return { opacity, background: backgroundValue, color: colorValue, borderRadius: borderRadiusValue, boxShadow: boxShadowValue };
});
