
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, Container, CssBaseline } from '@mui/material';
import { cookieServices } from '../../_helpers';
import { Header, Footer } from '.'
import { postAuthRoutes } from '../../routes';

const PreSignIn = ({ children, ...props }) => {

    /** Declare hooks and plugins */
    let navigate = useNavigate();

    useEffect(() => {
        if (cookieServices.get('accessToken'))
            navigate(postAuthRoutes('promos').path);

    }, [navigate])

    return (
        <Box>
            <Header />
            <CssBaseline />
            <Container component="main" maxWidth={false} sx={{ padding: { xs: '0 15px' }, marginBottom: `30px`, minHeight: `calc(100vh - 380px);` }}>
                {children}
            </Container>
            <Footer />
        </Box>
    )
}

export { PreSignIn }
