import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box } from '@mui/material';
import { FormInput, SubmitButton, ModalHeader, ModalBody, ModalFooter } from '../../../../_components';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { validate } from '.';
import { preAuthRoutes } from '../../../../routes';
import { ForgotPasswordAction } from '../../../../redux/actions';

// Initialize form input values to null
const inputs = { email: '' };

function ForgotPassword({ backTo, onClose }) {

    const dispatch = useDispatch();

    /** Redux actions and state */
    const { send_pwd_rst_link_loading: isLoading, send_pwd_rst_link_errors: sendPwdRstLinkErrors } = useSelector((state) => state.ForgotPasswordReducer);
    const sendPasswordResetLink = (params) => dispatch(ForgotPasswordAction.sendPasswordResetLink(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...sendPwdRstLinkErrors });
    }, [sendPwdRstLinkErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSendPasswordResetLinkAction();
    }

    /**
     * function to dispatch send password reset link
     * @author Akshay N
     */
    async function dispatchSendPasswordResetLinkAction() {
        const isOtpSent = await sendPasswordResetLink(data);
        if (isOtpSent.status === 1) {
            backTo('verify_otp', data)
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={preAuthRoutes(`forgot_password`).name} onClose={onClose} />
                <ModalBody>
                    <Box style={{ paddingLeft: `50px`, paddingRight: `50px` }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Email'
                                    name='email'
                                    value={data.email}
                                    error={action.isSubmitted && errors.email ? errors.email : ''}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box style={{ paddingLeft: `50px`, paddingRight: `50px`, width: `100%` }}>
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label="Send OTP" color={`secondary`} fullWidth loading={isLoading} />
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { ForgotPassword };
