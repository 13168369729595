import { StyledCardHeader } from '.';
import PropTypes from 'prop-types';

function CardHeader({ title, action, ...props }) {
	return <StyledCardHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5' }} action={action} {...props} />;
}

/* `CardHeader.defaultProps` is setting a default value for the `action` prop in the `CardHeader`
component. If the `action` prop is not passed to the component when it is used, it will default to
`null`. */
CardHeader.defaultProps = {
	action: null,
};

/* `CardHeader.propTypes` is defining the expected data types for the `title` and `action` props that
can be passed to the `CardHeader` component. In this case, `title` is expected to be a string and
`action` is expected to be a React element. This helps to ensure that the component is used
correctly and can help catch errors during development. */
CardHeader.propTypes = {
	title: PropTypes.string,
	action: PropTypes.element,
};

export { CardHeader };
