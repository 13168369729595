
import colors from "../../base/colors";
import dialogContentText from "../../../_root/components/dialog/dialogContentText";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...dialogContentText,
  styleOverrides: {
    ...dialogContentText.styleOverrides,
    root: {
      ...dialogContentText.styleOverrides.root,
      color: colors.text.main,
    },
  },
};
