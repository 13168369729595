import stepLabel from "../../../_root/components/stepper/stepLabel";
import rgba from "../../../_root/functions/rgba";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...stepLabel,
  styleOverrides: {
    ...stepLabel.styleOverrides,
    label: {
      ...stepLabel.styleOverrides.label,
      color: colors.primary.main,

      "&.Mui-active": {
        ...stepLabel.styleOverrides.label['&.Mui-active'],
        color: `${rgba(colors.common.white, 0.8)} !important`,
      },

      "&.Mui-completed": {
        ...stepLabel.styleOverrides.label['&.Mui-completed'],
        color: `${rgba(colors.common.white, 0.8)} !important`,
      },
    },
  },
};
