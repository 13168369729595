import borders from "../../base/borders";
import typography from "../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
      fontSize: typography.size.sm
    },
    rounded: {
      borderRadius: borders.borderRadius.md,
    },
    img: {
      height: "auto",
    }
  },
  variants: []
};
