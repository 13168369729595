module.exports = {
    /**
     * function to validate the forgot password api
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     */
    validate: (name, value, data = {}) => {
        const errors = { otp: '' };
        switch (name) {
            case 'otp': {
                errors.otp = '';
                if (value === '')
                    errors.otp = 'Enter otp.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
}