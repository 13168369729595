import { ContactUsServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const ContactUsAction = {

    submitContactUsInfo: (params) => {
        return dispatch => {
            return ContactUsServices.submitContactUsInfo(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return response; },
                    error => { return error; }
                );
        };
    }

};
