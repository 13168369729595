import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { GeneralButton } from '../../../../../_components/controls/general-button/GeneralButton';
import { UserAction } from '../../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { preAuthRoutes } from '../../../../../routes';
import { CardHeader, SweetAlert } from '../../../../../_components';


function AccountStatus({ cancelButton }) {

    const dispatch = useDispatch();
    let navigate = useNavigate();


    /** Redux actions and state */
    const deleteInfluencerAccount = () => dispatch(UserAction.deleteAccount());


    /**
     * function to delete account
     * @author Akshay N
     * @created_at
     */
    function deleteAccount(event) {
        event.stopPropagation();

        SweetAlert.fire({
            title: 'Are you sure?',
            text: "When you delete your account, you won't be able to retrieve the content or information you've shared.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                deleteInfluencerAccount().then(() => {
                    let timerInterval;
                    SweetAlert.fire({
                        title: 'Deleted!',
                        html: "Your account has been deleted.You will be redirected soon!<br/><br/>I will close in <b></b> milliseconds.",
                        icon: 'success',
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            SweetAlert.showLoading()
                            const b = SweetAlert.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft() }, 200)
                        },
                        willClose: () => {
                            setTimeout(() => {
                                navigate({
                                    pathname: preAuthRoutes('home').path
                                });
                            }, 4000);
                            clearInterval(timerInterval)
                        }
                    })
                });
            }
        })

    }

    const cancelDeleteAccount = () => (event) => {
        cancelButton(event);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CardHeader title='Deleting your account' />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="body2" mb={1}>This is permanent.When you delete your account, you won't be able to retrieve the content or information you've shared.If you want to delete your account please click on the below button.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction={`row`} spacing={1}>
                    <GeneralButton variant="contained" color={`error`} label="Delete" onClick={deleteAccount} fullWidth />
                    <GeneralButton variant='contained' color={`secondary`} label={`Cancel`} onClick={cancelDeleteAccount()} fullWidth />
                </Stack>
            </Grid>
        </Grid>

    )
}

export { AccountStatus };
