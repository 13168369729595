import pxToRem from '../../functions/pxToRem';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	defaultProps: {},
	styleOverrides: {
		root: {
			'& .MuiSvgIcon-root': {
				backgroundPosition: 'center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				width: pxToRem(20),
				height: pxToRem(20),
				borderRadius: pxToRem(5.6)
			}
		}
	},
	variants: []
};
