import { Brightness4, Brightness7 } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { UserAction } from '../../../../redux/actions';

function ThemeMode() {


    const dispatch = useDispatch();
    const theme = useTheme();
    const updateTheme = (params) => dispatch(UserAction.updateTheme(params));

    const changeThemeMode = () => {
        updateTheme();
    }

    return (
        <IconButton sx={{ ml: 1 }} color="inherit" onClick={changeThemeMode}>
            {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
    )
}

export { ThemeMode }