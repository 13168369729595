import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box, Button } from '@mui/material';
import { FormInput } from '../../../../_components/form/form-input';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { FormPassword } from '../../../../_components/form/form-password';
import { validate } from '.'
import { RegisterAction } from '../../../../redux/actions/RegisterAction';
import { SubmitButton } from '../../../../_components/controls';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';
import { ModalBody, ModalFooter, ModalHeader, SweetAlert } from '../../../../_components';

// Initialize form input values to null
const inputs = { name: '', insta_handle: '', email: '', mobile_number: '', password: '', confirm_password: '', referred_by: '' };

function Register({ backTo, onClose }) {

    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux reducer actions */
    const register = (params) => dispatch(RegisterAction.register(params));

    /** Redux reducer states */
    const { loading: isLoading, errors: registrationErrors } = useSelector((state) => state.RegisterReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs, referred_by: (searchParams.get('ref_ig') ?? '') });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {

        setErrors({ ...registrationErrors });
    }, [registrationErrors])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle registration form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @author Akshay N
     */
    async function dispatchRegisterAction() {
        const registered = await register(data);
        if (registered && registered.status === 1) {
            setData({ name: '', insta_handle: '', email: '', mobile_number: '', password: '', confirm_password: '', referred_by: '' });
            onClose();
            SweetAlert.fire({
                title: 'Please verify your email',
                text: "Check on your registered email to complete the registration process",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = postAuthRoutes('promos').path;
                    navigate(redirectPath);
                }
            })
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={preAuthRoutes(`register`).name} onClose={onClose} />
                <ModalBody  >
                    <Box>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Name'
                                    name='name'
                                    value={data.name}
                                    error={action.isSubmitted && errors.name ? errors.name : ''}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={2}
                                    label='Instagram Handle'
                                    name='insta_handle'
                                    value={data.insta_handle}
                                    error={action.isSubmitted && errors.insta_handle ? errors.insta_handle : ''}
                                    onChange={handleChange}
                                    maskType={`insta_handle`}
                                    inputProps={{ "autoCapitalize": "none" }}
                                    startIcon={'@'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={3}
                                    label='Email'
                                    name='email'
                                    value={data.email}
                                    error={action.isSubmitted && errors.email ? errors.email : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={3}
                                    label='Mobile Number'
                                    name='mobile_number'
                                    value={data.mobile_number}
                                    error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                    onChange={handleChange}
                                    maskType={`digit-only`}
                                    inputProps={{ maxLength: 12 }}
                                    autoComplete={`mobile_number`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={4}
                                    label='Password'
                                    name='password'
                                    value={data.password}
                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={5}
                                    label='Re-enter Password'
                                    name='confirm_password'
                                    value={data.confirm_password}
                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={2}
                                    label='Referral Code (Optional)'
                                    name='referred_by'
                                    value={data.referred_by}
                                    error={action.isSubmitted && errors.referred_by ? errors.referred_by : ''}
                                    onChange={handleChange}
                                    maskType={`insta_handle`}
                                    inputProps={{ "autoCapitalize": "none" }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box >
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label={preAuthRoutes(`register`).name} color={`secondary`} fullWidth loading={isLoading} />
                            <Box sx={{ fontSize: `12px` }}>Back to <Button color="inherit" variant="text" sx={{ fontSize: '14px', cursor: 'pointer', minWidth: 'auto', padding: 0, marginLeft: '4px', textTransform: 'none' }} onClick={(e) => { e.preventDefault(); backTo('login') }} > { preAuthRoutes(`log_in`).name } </Button></Box>
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { Register };
