// import { formatPhoneNumberIntl } from "react-phone-number-input";

const handleInputChange = (e) => {
    if (e.target.type === 'checkbox') {
        return { name: e.target.name, value: e.target.checked };
    } else if (e.target.type === 'radio') {
        return { name: e.target.name, value: e.target.value };
    } else if (e.target.type === 'file') {
        return { name: e.target.name, value: e.target.files[0] };
    } else if (e.target.type === 'color') {
        return { name: e.target.name, value: e.target.value };
    } else if (e.target.type === 'date_time') {
        return { name: e.target.name, value: e.target.value };
    } else if (e.target.type === 'quantity') {
        return { name: e.target.name, value: e.target.value };
    } else {
        return e.target;
    }
};

export { handleInputChange }
