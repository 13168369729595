export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_REQUEST_TIMEOUT = 10000;

export const URL_PRIVACY_POLICY = 'https://google.com';
export const URL_TERMS_OF_SERVICE = 'https://google.com';

export const BRAND_APP_URL = process.env.REACT_APP_BRAND_APP_URL ? process.env.REACT_APP_BRAND_APP_URL : 'https://manage.adrundigital.com/';

export const DRAWER_WIDTH = 240;

export const IG_APP_ID = process.env.REACT_APP_IG_APP_ID;
export const IG_APP_REDIRECT_URI = process.env.REACT_APP_IG_APP_REDIRECT_URI;