import { Box, Typography, Grid, Card, CardContent, Stack, styled } from '@mui/material'
import React from 'react'

const BrandStepsBox = styled(({ ...props }) => (<Box mt={3} {...props} />))`
    & .MuiCardContent-root{
        padding: 2.5rem !important;
        & .MuiTypography-h1{
            color: #204B69;
            font-size : 40px;
            min-width : 60px;
        }
    }
`;

const steps = [
    { step: `01`, description: `Choose the type and number of Instagram influencers from those registered with us.` },
    { step: `02`, description: `Place your ad through their story.` },
    { step: `03`, description: `From then, we will inspect all their moves related to your ad.` },
    { step: `04`, description: `The app automatically checks it and informs you if the story was alive for the expected time in the account of those influencers.` },
]

function BrandSteps() {
    return (
        <BrandStepsBox>
            <Stack spacing={1}>
                <Typography variant="h5">Easy steps to start running your ad through us</Typography>
                <Box>
                    <Grid container spacing={1}>
                        {steps.map((step, i) => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={i}>
                                    <Card>
                                        <CardContent>
                                            <Stack direction={`row`} spacing={2} alignItems={`center`}>
                                                <Typography variant="h1" >{step.step}</Typography>
                                                <Typography variant="body2">{step.description}</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Stack>
        </BrandStepsBox>
    )
}

export { BrandSteps }