/**
 * The function returns a CSS linear gradient with a specified angle and two colors.
 * @param {String} color - The starting color of the gradient.
 * @param {String} colorState - `colorState` is another color that will be used in the linear gradient along
 * with the `color` parameter. Together, they will create a gradient effect between the two colors.
 * @param {Number} [angle=195] - The angle parameter is the direction of the gradient. It specifies the angle at
 * which the gradient is applied. The default value is 195 degrees.
 * @returns {String} A string representing a CSS linear gradient with the specified angle and colors. The first
 * color is the starting color and the second color is the ending color.
 * @author Akshay N
 * @created_at 21/05/2023
 */
function linearGradient(color, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

export default linearGradient;
