import React, { Fragment, useEffect, useState } from 'react';
import { RestaurantOrderStyled } from './RestaurantOrderStyled';
import { postAuthRoutes } from '../../../routes';
import { APP_NAME, cookieServices } from '../../../_helpers';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantAction } from '../../../redux/actions';
import { NoData } from '../../../_components';
import { CurrencySymbol } from '../../../_components/currency-symbol';
import { OrderItem, OrderItemLoader } from './_component/order-item';
import { GeneralButton } from "../../../_components/controls";
import { CachedRounded } from '@mui/icons-material';
import { OrderDrawer } from './_component';

const RestaurantOrder = () => {

    let orderId = cookieServices.get('orderId');

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { order, orders_loading, orders, orders_errors, orders_total_discounted_price, orders_total_gst_price } = useSelector((state) => state.RestaurantReducer);
    const getOrderList = (params) => dispatch(RestaurantAction.getOrderList(params));

    const [drawer, setDrawer] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);


    useEffect(() => {
        getNext(); // Initially load participants list
        setInterval(() => {
            getOrderList({ order_id: orderId });
        }, 120000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, refreshPage]);

    const getNext = async () => {
        getOrderList({ order_id: orderId });
    };

    const verifyOrder = async () => {
        let orderDataUpdated = await getOrderList({ order_id: orderId });
        if (orderDataUpdated.status === 1)
            setDrawer(true);
    };


    return (
        <RestaurantOrderStyled>
            <Helmet>
                <title>{`${APP_NAME} | ${postAuthRoutes('restaurant_order').name}`}</title>
            </Helmet>
            <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center">
                {(orders.length > 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack display={`flex`} direction={`row`} alignItems={'center'} justifyContent={`space-between`}>
                            <Stack>
                                <Typography variant="h5">{`${postAuthRoutes('restaurant_order').name}`}</Typography>
                                <Typography variant="caption">{`You have ordered ${orders.length} items`}</Typography>
                            </Stack>
                            <GeneralButton label={<CachedRounded />} onClick={() => setRefreshPage(pageRefresh => !pageRefresh)} />
                        </Stack>

                    </Grid>
                }
                {orders.length > 0 && orders.map((item, index) => {
                    return <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}><OrderItem item={item} /></Grid>;
                })}
                {orders_loading && <OrderItemLoader category={2} item={4} />}
                {!orders_loading && orders.length === 0 && <NoData content1={`Order`} content2={`is Empty`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`80vh`} />}
            </Grid>
            {(orders.length > 0 && ['order_placed', 'cooking_in_progress', 'ready_to_deliver', 'delivered'].includes(order.status)) &&
                <Fragment>
                    <Box className="place-order">
                        <Button variant='contained' fullWidth size="large" color='secondary' onClick={() => verifyOrder()}>Request Bill |  <CurrencySymbol />{orders_total_gst_price} </Button>
                    </Box>
                    <OrderDrawer drawer={drawer} setDrawer={setDrawer} />
                </Fragment>
            }
        </RestaurantOrderStyled>
    );
};

export { RestaurantOrder };