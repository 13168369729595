import borders from "../../base/borders";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: typography.size.sm,
      textAlign: "left",
      borderRadius: borders.borderRadius.md,
    },
  },

  variants: []
};
