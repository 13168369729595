import formControlLabel from "../../../_root/components/form/formControlLabel";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	...formControlLabel,
	styleOverrides: {
		...formControlLabel.styleOverrides,
		label: {
			...formControlLabel.styleOverrides.label,
			color: colors.light.main,
			'&.Mui-disabled': {
				...formControlLabel.styleOverrides.label['&.Mui-disabled'],
				color: colors.light.light
			}
		}
	}
};
