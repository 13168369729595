import { apiServices, cookieServices } from '../../_helpers';


export const SignInServices = {

	/**
	 * Service for signin
	 * @param {Object} params - The params which are used for signin api.
	 * @param {string} params.email - Email of the brand user
	 * @param {string} params.password - Password the brand user
	 * @author Akshay
	 */
	signIn: (params) => {

		return apiServices.post('/influencer/login', params)
			.then((response) => {
				let date = new Date();
				date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
				const options = { path: '/', expires: date };
				cookieServices.set('accessToken', response.data.token, options);
				return response;
			})
	},

	/**
	 * Service for sign out
	 *
	 * @author Akshay
	 */
	signOut: (params) => {

		return apiServices.get('/influencer/logout')
			.then((response) => {
				cookieServices.remove('accessToken');
				return response;
			})
	},


}
