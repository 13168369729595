import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: typography.size.sm,
    },
    input: {
      padding: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(5)}`,
    },
  },
  variants: []
};
