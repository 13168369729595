import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { MenuItemLoader, MenuCategoryStyled } from '../';

function MenuCategoryLoader({ category = 1, item = 2 }) {

    return (
        [...new Array(category)].map((x, categoryIndex) => (
            <MenuCategoryStyled item xs={12} sm={12} md={12} lg={12} xl={12} key={categoryIndex} >
                <Skeleton variant='text' className='category-title' animation="wave" sx={{ fontSize: '1rem' }} width="70%" />
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        [...new Array(item)].map((y, itemIndex) => <Grid item xs={6} sm={6} md={4} lg={3} xl={2} key={itemIndex}><MenuItemLoader /></Grid>)
                    }
                </Grid>
            </MenuCategoryStyled>
        ))
    );
}

export { MenuCategoryLoader };