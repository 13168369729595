import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      transition: "all 200ms linear",
    },

    alternativeLabel: {
      top: "14%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borders.borderWidth[2]} !important`,
      borderColor: "currentColor",
      opacity: 0.5,
    },
  },
  variants: []
};
