import { CardHeader, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@mui/material';
import { Print } from '@mui/icons-material';
import React, { Fragment, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { APP_NAME } from '../../../../_helpers';
import { preAuthRoutes } from '../../../../routes';
import { CurrencySymbol } from '../../../../_components';
import { GeneralButton } from '../../../../_components/controls';
import { NoData } from "../../../../_components/layout";
import { RestaurantAction } from '../../../../redux/actions';
import { useReactToPrint } from 'react-to-print';

function OrderBillDetails() {

    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();
    const { orderId } = useParams();
    const componentRef = useRef();

    const { order_bill_details_loading, order_bill_details } = useSelector((state) => state.RestaurantReducer);
    const getOrderBillDetails = (params) => dispatch(RestaurantAction.getOrderBillDetails(params));

    useEffect(() => {
        if (orderId) {
            getOrderBillDetails({ order_id: orderId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('order_bill_details').name}`}</title>
            </Helmet>
                <Box display="flex" justifyContent="flex-end" paddingTop={1} paddingRight={1}>
                    <GeneralButton label={<Print />} onClick={handlePrint} />
                </Box>
                <div ref={componentRef}>
                    {!order_bill_details_loading && Object.keys(order_bill_details).length === 0 && ( <NoData content1="No" content2="Receipt available" minHeight="55vh" /> )}
                    {(!order_bill_details_loading && Object.keys(order_bill_details).length > 0) && (
                    <Fragment>
                        <Box display="flex" justifyContent="center"> <CardHeader title={preAuthRoutes('order_bill_details').name} /> </Box>
                        {order_bill_details.gst && order_bill_details.gst.gst_number && <Typography variant="caption" textAlign={`left`} paddingLeft={'10px'}>{`GSTIN: ${order_bill_details.gst.gst_number}`}</Typography>}
                        <Box className='items' padding={'10px'} sx={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead style={{ display: `table-header-group` }}>
                                    <TableRow>
                                        <TableCell width={`50%`} align="left">Item </TableCell>
                                        <TableCell width={`20%`} align="center">Price </TableCell>
                                        <TableCell width={`10%`} align="center">Qty </TableCell>
                                        <TableCell width={`20%`} align="right">Amount </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order_bill_details.items.map((row) => (
                                        <TableRow key={row._id} >
                                            <TableCell align="left">
                                                <Typography variant='body2' style={{ textTransform: 'uppercase' }}> {`${row.title}`}</Typography>
                                                <Typography variant='caption' color="secondary" style={{ display: `flex`, alignItems: `center`, justifyContent: `left` }}>
                                                    {row.have_portions ? <Box style={{ marginRight: `5px`, texTransform: 'uppercase' }}>{row.portion}</Box> : ``}
                                                    <Box><CurrencySymbol />{`${row.price}`}</Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><CurrencySymbol />{row.item_actual_discounted_price}</Box></TableCell>
                                            <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>{row.item_quantity}</Box></TableCell>
                                            <TableCell align="right"><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{row.discounted_price}</Box></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <div style={{ overflowX: 'hidden' }}>
                            <Box padding={'10px'}>
                                <Grid container>
                                    <Grid item xs={8}> <Typography variant="h6" component={'span'}>Item total</Typography> </Grid>
                                    <Grid item xs={4}> <Typography variant="h6" component={'span'}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{order_bill_details.total_actual_price}</Box></Typography> </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={8}>Discount</Grid>
                                    <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{order_bill_details.total_discount}</Box></Grid>
                                </Grid>
                            </Box>
                            <Box padding={'10px'}>
                                <Grid container>
                                    <Grid item xs={8}>AMOUNT</Grid>
                                    <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{order_bill_details.total_discounted_price}</Box></Grid>
                                </Grid>
                                {order_bill_details.gst && order_bill_details.gst.cgst && order_bill_details.gst.sgst && (
                                    <Box className='gst-amount'>
                                        <Grid container>
                                            <Grid item xs={8}>CGST ({order_bill_details.gst.cgst.percent}%)</Grid>
                                            <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{order_bill_details.gst.cgst.amount}</Box></Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={8}>SGST ({order_bill_details.gst.sgst.percent}%)</Grid>
                                            <Grid item xs={4}><Box style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}><CurrencySymbol />{order_bill_details.gst.sgst.amount}</Box></Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Box>
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export { OrderBillDetails };
