import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: "100%",

      "& .MuiSlider-active, & .Mui-focusVisible": {
        boxShadow: "none !important",
      },

    },

    rail: {
      height: pxToRem(2),
      borderRadius: borders.borderRadius.md,
      opacity: 1,
    },

    track: {
      height: pxToRem(2),
      position: "relative",
      border: "none",
      borderRadius: borders.borderRadius.md,
      zIndex: 1,
    },

    thumb: {
      width: pxToRem(14),
      height: pxToRem(14),
      zIndex: 10,

      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  variants: []
};
