import { failure, request, success } from ".";
import { BrandServices } from "../services";

export const GET_BRANDS_LIST_REQUEST = 'GET_BRANDS_LIST_REQUEST';
export const GET_BRANDS_LIST_SUCCESS = 'GET_BRANDS_LIST_SUCCESS';
export const GET_BRANDS_LIST_FAILURE = 'GET_BRANDS_LIST_FAILURE';

export const GET_BRAND_DETAILS_REQUEST = 'GET_BRAND_DETAILS_REQUEST';
export const GET_BRAND_DETAILS_SUCCESS = 'GET_BRAND_DETAILS_SUCCESS';
export const GET_BRAND_DETAILS_FAILURE = 'GET_BRAND_DETAILS_FAILURE';

export const BrandAction = {

    getBrandList: (params) => {
        return dispatch => {
            dispatch(request(GET_BRANDS_LIST_REQUEST, params));
            return BrandServices.getBrandList(params)
                .then(
                    response => { return dispatch(success(GET_BRANDS_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_BRANDS_LIST_FAILURE, params, error)); }
                );
        };
    },

    getBrandDetails: (params) => {
        return dispatch => {
            dispatch(request(GET_BRAND_DETAILS_REQUEST, params));
            return BrandServices.getBrandDetails(params)
                .then(
                    response => { return dispatch(success(GET_BRAND_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(GET_BRAND_DETAILS_FAILURE, params, error)); }
                );
        };
    }

};
