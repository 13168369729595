/**
 * The base colors for the Soft UI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO Material using thie file.
 */
const colorPalette = {
  background: {
    default: "#F2F3F9",
    sidenav: "#1e1e2d",
    card: "#FFFFFF",
  },
  primary: {
    light: '#F75C39',
    main: '#EB5737',
    dark: '#D14E30'
  },
  secondary: {
    light: '#1690AB',
    main: '#15849E',
    dark: '#116F85'
  },
  info: {
    light: "#00C5DB",
    main: "#00AEC1",
    dark: "#00808E",
  },

  success: {
    light: "#4CAF50",
    main: "#689F38",
    dark: "#67BB6A",
  },

  warning: {
    light: "#FB8C00",
    main: "#FF6F00",
    dark: "#FC9D26",
  },

  error: {
    light: "#F44335",
    main: "#D32F2F",
    dark: "#F65F53",
  },

  light: {
    light: "#F0F2F5",
    main: "#F4F4F4",
    dark: "#E3E3E3",
  },

  dark: {
    light: "#686868",
    main: "#4D5156",
    dark: "#1A1A1A",
  },

  text: {
    light: "#303030",
    main: "#595d6e",
    dark: "#404040",
  },

  link: {
    light: '#6D92C1',
    main: '#595d6e',
    dark: '#3E618F'
  },

  grey: {
    100: "#F8F9FA",
    200: "#F0F2F5",
    300: "#DEE2E6",
    400: "#CED4DA",
    500: "#ADB5BD",
    600: "#6C757D",
    700: "#495057",
    800: "#343A40",
    900: "#212529",
  },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  background: colorPalette.background,

  transparent: "transparent",

  common: {
    white: "#FFFFFF",
    black: "#000",
  },

  grey: colorPalette.grey,

  primary: {
    light: colorPalette.primary.light,
    main: colorPalette.primary.main,
    dark: colorPalette.primary.dark
  },

  secondary: {
    light: colorPalette.secondary.light,
    main: colorPalette.secondary.main,
    dark: colorPalette.secondary.dark
  },

  info: {
    light: colorPalette.info.light,
    main: colorPalette.info.main,
    dark: colorPalette.info.dark
  },

  success: {
    light: colorPalette.success.light,
    main: colorPalette.success.main,
    dark: colorPalette.success.dark
  },

  warning: {
    light: colorPalette.warning.light,
    main: colorPalette.warning.main,
    dark: colorPalette.warning.dark
  },

  error: {
    light: colorPalette.error.light,
    main: colorPalette.error.main,
    dark: colorPalette.error.dark
  },

  light: {
    light: colorPalette.light.light,
    main: colorPalette.light.main,
    dark: colorPalette.light.dark
  },

  dark: {
    light: colorPalette.dark.light,
    main: colorPalette.dark.main,
    dark: colorPalette.dark.dark
  },

  text: {
    light: colorPalette.text.light,
    main: colorPalette.text.main,
    dark: colorPalette.text.dark,
    primary: colorPalette.primary.main,
    secondary: colorPalette.secondary.main,
    disabled: colorPalette.grey[500]
  },

  link: {
    light: colorPalette.link.light,
    main: colorPalette.link.main,
    dark: colorPalette.link.dark
  },

  gradients: {
    primary: {
      main: colorPalette.primary.main,
      state: colorPalette.primary.dark
    },

    secondary: {
      main: colorPalette.secondary.main,
      state: colorPalette.secondary.dark,
    },

    info: {
      main: colorPalette.info.main,
      state: colorPalette.info.dark,
    },

    success: {
      main: colorPalette.success.main,
      state: colorPalette.success.dark,
    },

    warning: {
      main: colorPalette.warning.main,
      state: colorPalette.warning.dark,
    },

    error: {
      main: colorPalette.error.main,
      state: colorPalette.error.dark,
    },

    light: {
      main: colorPalette.light.main,
      state: colorPalette.light.dark,
    },

    dark: {
      main: colorPalette.dark.main,
      state: colorPalette.dark.dark,
    },
  },

  badgeColors: {
    primary: {
      background: colorPalette.primary.main,
      text: colorPalette.text.light
    },

    secondary: {
      background: colorPalette.secondary.main,
      text: colorPalette.text.light
    },

    info: {
      background: colorPalette.info.main,
      text: colorPalette.text.light
    },

    success: {
      background: colorPalette.success.main,
      text: colorPalette.text.light
    },

    warning: {
      background: colorPalette.warning.main,
      text: colorPalette.text.light
    },

    error: {
      background: colorPalette.error.main,
      text: colorPalette.text.light
    },

    light: {
      background: colorPalette.light.main,
      text: colorPalette.text.dark
    },

    dark: {
      background: colorPalette.dark.main,
      text: colorPalette.text.light
    },
  },

  coloredShadows: {
    primary: colorPalette.primary.main,
    secondary: colorPalette.secondary.light,
    info: colorPalette.info.light,
    success: colorPalette.success.light,
    warning: colorPalette.warning.light,
    error: colorPalette.error.light,
    light: colorPalette.light.light,
    dark: colorPalette.dark.light,
  },

};
