import borders from "../../base/borders";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(5)} ${pxToRem(15)}`,
      borderRadius: borders.borderRadius.md,
      fontSize: typography.size.sm,
      transition: "background-color 300ms ease, color 300ms ease",
    },
  },
  variants: []
};
