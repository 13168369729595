import { apiServices } from '../../_helpers';
import queryString from 'query-string';

export const StoriesServices = {

    /**
     * Service for list brand promos
     * @author Akshay 
     */
    listStories: (params) => {

        let queryParam = { ...params };
        queryParam.page = params.page;
        if (params.search) queryParam.search = params.search; else delete queryParam.search;
        return apiServices.get(`/influencer/stories?${queryString.stringify(queryParam)}`)
            .then((response) => { return response; })
    },
    /**
     * Service for get story details
     * @author Akshay 
     */
    getStoryDetails: (params) => {
        return apiServices.post('/influencer/stories/detail', params)
            .then((response) => { return response; })
    },
    /**
     * Service for get story timeline
     * @author Akshay 
     */
    getStoryTimeline: (params) => {
        return apiServices.post('/influencer/stories/timeline', params)
            .then((response) => { return response; })
    },
    /**
     * Service for get story timeline
     * @author Akshay 
     */
    getStoryPromoDetails: (params) => {
        return apiServices.post('/influencer/stories/promo/details', params)
            .then((response) => { return response; })
    },

};
