import {
    GET_FAQ_LIST_REQUEST, GET_FAQ_LIST_SUCCESS, GET_FAQ_LIST_FAILURE
} from "../actions";

const initialState = {
    faqs_loading: false, faqs: [], faqs_errors: {},
};

export function FaqReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::notifications redux */
        case GET_FAQ_LIST_REQUEST: {
            return {
                ...state,
                faqs_loading: true,
                faqs_errors: {}
            };
        }
        case GET_FAQ_LIST_SUCCESS: {

            let newArray = [...state.faqs]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.faqs.forEach((payload) => {
                const faqIndex = newArray.findIndex(faq => faq._id === payload._id);
                if (faqIndex !== -1) {
                    newArray[faqIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                faqs_loading: false,
                faqs_errors: {},
                faqs: newArray,
            };

        }
        case GET_FAQ_LIST_FAILURE: {
            return {
                ...state,
                faqs_loading: false,
                faqs_errors: action.errors,
                faqs: []
            };
        }

        /** end::notifications redux */
        default:
            return state;
    }
}
