import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      position: "relative",
      borderRadius: borders.borderRadius.md,
      minHeight: "unset",
      padding: pxToRem(4),
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius: borders.borderRadius.md,
      transition: "all 500ms ease",
    },
  },
  variants: []
};
