import { failure, request, success } from ".";
import { ForgotPasswordServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const SEND_PWD_RST_LINK_REQUEST = 'SEND_PWD_RST_LINK_REQUEST';
export const SEND_PWD_RST_LINK_SUCCESS = 'SEND_PWD_RST_LINK_SUCCESS';
export const SEND_PWD_RST_LINK_FAILURE = 'SEND_PWD_RST_LINK_FAILURE';

export const PWD_RST_VERIFY_OTP_REQUEST = 'PWD_RST_VERIFY_OTP_REQUEST';
export const PWD_RST_VERIFY_OTP_SUCCESS = 'PWD_RST_VERIFY_OTP_SUCCESS';
export const PWD_RST_VERIFY_OTP_FAILURE = 'PWD_RST_VERIFY_OTP_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const ForgotPasswordAction = {

    sendPasswordResetLink: (params) => {
        return dispatch => {
            dispatch(request(SEND_PWD_RST_LINK_REQUEST, params));
            return ForgotPasswordServices.sendPasswordResetLink(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(SEND_PWD_RST_LINK_SUCCESS, params, response)); },
                    error => { return dispatch(failure(SEND_PWD_RST_LINK_FAILURE, params, error)); }
                );
        };
    },

    verifyOtp: (params) => {
        return dispatch => {
            dispatch(request(PWD_RST_VERIFY_OTP_REQUEST, params));
            return ForgotPasswordServices.verifyOtp(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(PWD_RST_VERIFY_OTP_SUCCESS, params, response)); },
                    error => { return dispatch(failure(PWD_RST_VERIFY_OTP_FAILURE, params, error)); }
                );
        };
    },

    resetPassword: (params) => {
        return dispatch => {
            dispatch(request(RESET_PASSWORD_REQUEST, params));
            return ForgotPasswordServices.resetPassword(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(RESET_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(RESET_PASSWORD_FAILURE, params, error)); }
                );
        };
    }

};
