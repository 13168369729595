import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import StyledBox from './StyledBox';

const Box = forwardRef(({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) =><StyledBox {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }} />);

/* `Box.defaultProps` is setting default values for the props that can be passed to the `Box`
component. If any of these props are not explicitly passed when using the `Box` component, they will
default to the values specified in `Box.defaultProps`. For example, if `variant` prop is not passed,
it will default to "contained". This helps to ensure that the component has default values for its
props and can be used more easily without having to pass every prop every time. */
Box.defaultProps = {
	variant: 'contained',
	bgColor: 'transparent',
	opacity: 1,
	borderRadius: 'none',
	shadow: 'none',
	coloredShadow: 'none',
};

/* This code is defining the prop types for the `Box` component. It is specifying the expected data
types and values for each prop that can be passed to the component. For example, `variant` prop can
only have the values of "contained" or "gradient", `bgColor` and `color` props should be strings,
`opacity` prop should be a number, `borderRadius` and `shadow` props should be strings, and
`coloredShadow` prop can only have specific values from the given array. This helps to ensure that
the component is used correctly and can catch errors early on during development. */
Box.propTypes = {
	variant: PropTypes.oneOf(['contained', 'gradient']),
	bgColor: PropTypes.string,
	color: PropTypes.string,
	opacity: PropTypes.number,
	borderRadius: PropTypes.string,
	shadow: PropTypes.string,
	coloredShadow: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark', 'none']),
};

export { Box };
