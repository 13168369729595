import React, { useEffect, useState } from 'react';
import { OrderItemStyled } from '.';
import { Box, Button, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';
import { CurrencyRupee, Delete } from '@mui/icons-material';
import { FormQuantityInput, RouteLink } from '../../../../../_components';
import { CurrencySymbol } from '../../../../../_components/currency-symbol';
import { cookieServices, handleInputChange } from '../../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RestaurantAction } from '../../../../../redux/actions';

const initialPayload = { item_quantity: 1 };

const OrderItem = ({ ...props }) => {

	let orderId = cookieServices.get('orderId');
	const { item } = props;
	const { image, title, item_status, item_status_text, short_description, discounted_price, item_discounted_price, portion_name, item_quantity, have_portions, item_actual_discounted_price, is_add_on, add_on_title } = item;

	const dispatch = useDispatch();
	const getOrderList = (params) => dispatch(RestaurantAction.getOrderList(params));



	useEffect(() => {

		getOrderList({ order_id: orderId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<OrderItemStyled>
			<CardContent>
				<Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
					<Grid item xs={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
						<CardMedia className="item-image" component="img" image={image} />
					</Grid>
					<Grid item xs={10}>
						<Grid container display={`flex`} alignItems={`center`} justifyContent={`center`}>
							<Grid item xs={12} display={`flex`} alignItems={`center`} justifyContent={`space-between`}>
								<Box>
									<Typography className="item-title" variant="h6">{title} {(have_portions && portion_name) ? `(${portion_name})` : ``} </Typography>
									<Typography variant="caption" component={'p'}>{short_description}</Typography>
								</Box>
								<Chip label={item_status_text} variant="filled" size="small" />
							</Grid>
							<Grid item xs={12}>
								<Box className={`price-box`}>
									<Typography variant="body2" className='item-price'><CurrencySymbol />{item_actual_discounted_price} X {item_quantity}</Typography>
									<Typography variant="53" color={`secondary`}><CurrencySymbol />{discounted_price}</Typography>
								</Box>

							</Grid>
							<Grid item xs={12} display={`flex`} alignItems={`center`} justifyContent={`left`}>
								<Typography variant="caption">{(is_add_on && add_on_title) ? `(Add On By ${add_on_title})`: ``}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</OrderItemStyled>
	);
};

export { OrderItem };
