import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    fontSize: "inherit",
    fill: "inherit"
  },
  styleOverrides: {
    fontSizeSmall: {
      fontSize: `${pxToRem(18)}`,
    },
  },
  variants: []
};
