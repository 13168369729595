import {
    COUPON_CODES_REQUEST, COUPON_CODES_SUCCESS, COUPON_CODES_FAILURE
} from "../actions";

const initialState = {
    coupon_codes_loading: false, coupon_codes: [], coupon_codes_errors: {}
};

export function CouponCodesReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::List coupon_codes redux */
        case COUPON_CODES_REQUEST: {
            return {
                ...state,
                coupon_codes_loading: true,
                coupon_codes_errors: {}
            };
        }
        case COUPON_CODES_SUCCESS: {

            let newArray = [...state.coupon_codes]; //making a new array

            if (action.data.page === 1) {
                newArray = [];
            }

            action.data.coupon_codes.forEach((payload) => {
                const promoIndex = newArray.findIndex(coupon_codes => coupon_codes._id === payload._id);
                if (promoIndex !== -1) {
                    newArray[promoIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });
            return {
                ...state,
                coupon_codes_loading: false,
                coupon_codes: newArray,
                coupon_codes_errors: {}
            };

        }
        case COUPON_CODES_FAILURE: {
            return {
                ...state,
                coupon_codes_loading: false,
                coupon_codes_errors: action.errors
            };
        }
        /** end::List coupon_codes redux */

        default:
            return state;
    }
}