
/**
 * The function converts a given pixel value to a rem value in CSS.
 * @param {Number} number - The number parameter is the value in pixels that you want to convert to rem units.
 * @param {Number} [baseNumber=16] - The `baseNumber` parameter is the base number used to convert pixels to
 * rems. By default, it is set to 16, which is the standard font size in pixels for most browsers.
 * @returns {String} The function `pxToRem` returns a string that represents the converted value of `number`
 * from pixels to rems. The converted value is calculated by dividing `number` by `baseNumber` (which
 * defaults to 16 if not provided) and appending the string "rem" to the result.
 * @author Akshay N
 * @created_at 21/05/2023
 */
function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export default pxToRem;
