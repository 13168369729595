import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    preSignInWrapper: {
        height: `100vh`
    },
    preSignInWrapperItemInner: {
        minWidth: `320px`,
        maxWidth: `630px`,
        minHeight: `300px`,
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(6)
    },
    preSigninFooter: {
        flexBasis: `unset !important`
    }
}))

export { useStyles };
