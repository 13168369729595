// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: `4px`,
      marginRight: `4px`
    },
  },
  variants: []
};
