import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Box } from '@mui/material';
import { FormInput } from '../../../../_components/form/form-input';
import { handleInputChange } from '../../../../_helpers/form/handleInputChange';
import { validateForm } from '../../../../_helpers/form/validateForm';
import { FormPassword } from '../../../../_components/form/form-password';
import { validate } from '.'
import { RegisterAction } from '../../../../redux/actions/RegisterAction';
import { SubmitButton } from '../../../../_components/controls';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';
import { ModalBody, ModalFooter, ModalHeader, SweetAlert } from '../../../../_components';

// Initialize form input values to null
const inputs = { name: '', insta_handle: '', email: '', mobile_number: '', password: '', confirm_password: '', key: '' };

function InfluencerCompleteProfile({ onClose, ...props }) {

    /** Declare hooks and plugins and constants */
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const profileData = props.data;
    const { key } = useParams();

    /** Redux reducer actions */
    const completeProfileSuccess = (params) => dispatch(RegisterAction.completeProfileSuccess(params));

    /** Redux reducer states */
    const { completed_profile_loading: isLoading, completed_profile_errors: completeProfileErrors } = useSelector((state) => state.RegisterReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {

        setErrors({ ...completeProfileErrors });
    }, [completeProfileErrors])

    useEffect(() => {
        if (profileData && profileData.insta_handle) {
            setData({
                ...data,
                name: profileData.name,
                insta_handle: profileData.insta_handle,
                key: key
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle complete profile form submit action
     * @param {object} e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchCompleteProfileAction();
    }

    /**
     * function to dispatch complete profile api
     * @author Akshay N
     */
    async function dispatchCompleteProfileAction() {
        const updated = await completeProfileSuccess(data);
        if (updated && updated.status === 1) {
            setData({ name: '', insta_handle: '', email: '', mobile_number: '', password: '', confirm_password: '' });
            onClose();
            SweetAlert.fire({
                title: 'Please verify your email',
                text: "Check on your registered email to complete the registration process",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = postAuthRoutes('promos').path;
                    navigate(redirectPath);
                }
            })
        }
    }
    function onCancel() {
        // e.preventDefault();
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit} noValidate>
                <ModalHeader textStyle={{ textAlign: `center`, width: `100%` }} id="auth-modal" title={preAuthRoutes(`complete_profile`).name} onClose={onCancel} />
                <ModalBody  >
                    <Box style={{ paddingLeft: `50px`, paddingRight: `50px` }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={1}
                                    label='Name'
                                    name='name'
                                    value={data.name}
                                    error={action.isSubmitted && errors.name ? errors.name : ''}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={2}
                                    label='Instagram Handle'
                                    name='insta_handle'
                                    value={data.insta_handle}
                                    error={action.isSubmitted && errors.insta_handle ? errors.insta_handle : ''}
                                    onChange={handleChange}
                                    maskType={`insta_handle`}
                                    inputProps={{ "autoCapitalize": "none" }}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={3}
                                    label='Email'
                                    name='email'
                                    value={data.email}
                                    error={action.isSubmitted && errors.email ? errors.email : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormInput
                                    tabIndex={4}
                                    label='Mobile Number'
                                    name='mobile_number'
                                    value={data.mobile_number}
                                    error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                    onChange={handleChange}
                                    maskType={`digit-only`}
                                    inputProps={{ maxLength: 12 }}
                                    autoComplete={`mobile_number`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={5}
                                    label='Password'
                                    name='password'
                                    value={data.password}
                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormPassword
                                    tabIndex={6}
                                    label='Re-enter Password'
                                    name='confirm_password'
                                    value={data.confirm_password}
                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter sx={{ justifyContent: `center`, width: `100%` }}>
                    <Box style={{ paddingLeft: `50px`, paddingRight: `50px`, width: `100%` }}>
                        <Stack direction="column" display={`flex`} alignItems="center" spacing={1} sx={{ width: `100%` }} >
                            <SubmitButton label='Submit' color={`secondary`} fullWidth loading={isLoading} />
                        </Stack>
                    </Box>
                </ModalFooter>
            </form>
        </React.Fragment>
    );
}

export { InfluencerCompleteProfile };
