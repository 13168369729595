import React from 'react';
import { CurrencyRupee } from '@mui/icons-material';
import { cookieServices } from '../../_helpers';

function CurrencySymbol() {

    let currencySymbol = cookieServices.get('currencySymbol');

    return (
        <> {currencySymbol ? <span dangerouslySetInnerHTML={{ __html: currencySymbol }} /> : <CurrencyRupee />} </>
    );
}

export { CurrencySymbol };