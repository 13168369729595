// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {
            boxShadow: `none`,
            marginBottom: `30px`
        }
    },
    variants: []
};
