import outlined from "../../../../_root/components/buttons/button/outlined";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...outlined,

  primary: {
    ...outlined.primary,
    backgroundColor: colors.transparent,
    borderColor: colors.primary.main,
    "&:hover": {
      backgroundColor: colors.primary.main,
      borderColor: colors.primary.dark,
      color: colors.text.main
    },
  },

  secondary: {
    ...outlined.secondary,
    backgroundColor: colors.transparent,
    borderColor: colors.secondary.main,

    "&:hover": {
      backgroundColor: colors.secondary.main,
      borderColor: colors.secondary.dark,
      color: colors.text.main,
    },
  },
};
