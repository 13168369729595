import React from 'react';
import { styled, Box } from '@mui/material';

const RestaurantCartStyled = styled(({ ...props }) => <Box {...props} />)`
	& .place-order {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;
		width: 100%;
		background-color: ${(props) => props.theme.palette.common.black};
	}
`;

export { RestaurantCartStyled };
