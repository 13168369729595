import divider from "../../../_root/components/divider/divider";
import rgba from "../../../_root/functions/rgba";
import colors from "../../base/colors";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...divider,
  styleOverrides: {
    ...divider.styleOverrides,
    root: {
      ...divider.styleOverrides.root,
      backgroundColor: colors.transparent,
      backgroundImage: `linear-gradient(to right, ${rgba(colors.dark.main, 0)}, ${rgba(colors.light.main, 0.4)}, ${rgba(colors.dark.main, 0)}) !important`,
    },

    vertical: {
      ...divider.styleOverrides.vertical,
      backgroundColor: colors.transparent,
      backgroundImage: `linear-gradient(to bottom, ${rgba(colors.dark.main, 0)}, ${rgba(colors.dark.main, 0.4)}, ${rgba(colors.dark.main, 0)}) !important`,
    },

    light: {
      ...divider.styleOverrides.light,
      backgroundColor: colors.transparent,
      backgroundImage: `linear-gradient(to right, ${rgba(colors.common.white, 0)}, ${colors.common.white}, ${rgba(colors.common.white, 0)}) !important`,

      "&.MuiDivider-vertical": {
        backgroundImage: `linear-gradient(to bottom, ${rgba(colors.common.white, 0)}, ${colors.common.white}, ${rgba(colors.common.white, 0)}) !important`,
      },
    },
  },
};
