import { apiServices } from '../../_helpers';
import queryString from 'query-string';


export const PromoServices = {

    /**
     * Service for list brand promos
     * @author Akshay 
     */
    listFeaturedPromos: (params) => {
        return apiServices.post(`/influencer/promo/featured/list`, params)
            .then((response) => { return response; })
    },
    /**
     * Service for list brand promos
     * @author Akshay 
     */
    listPromos: (params) => {
        let queryParam = { ...params };
        queryParam.page = params.page;
        if (params.search) queryParam.search = params.search; else delete queryParam.search;
        return apiServices.get(`/influencer/promo/list?${queryString.stringify(queryParam)}`)
            .then((response) => { return response; })
    },
    /**
     * Service for list brand promos
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getPromoDetails: (params) => {
        return apiServices.post('/influencer/promo/detail', params)
            .then((response) => { return response; })
    },

    /**
     * Service for list brand promos
     * @param {Object} params - The params which are used for the api
     * @author Akshay 
     */
    getPromoInfo: (params) => {
        return apiServices.post('/influencer/promo/info', params)
            .then((response) => { return response; })
    }

};
