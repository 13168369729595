import inputLabel from "../../../_root/components/form/inputLabel";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...inputLabel,
  styleOverrides: {
    ...inputLabel.styleOverrides,
    root: {
      ...inputLabel.styleOverrides.root,
      color: colors.dark.main,

      "&.Mui-focused": {
        color: colors.primary.main,
      },

    },

  },
};
