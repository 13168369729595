/**
 * The function returns true if the input matches a specific regular expression pattern for an
 * Instagram handle, and prevents any other input.
 * @param {Object} e - The parameter "e" is an event object that is passed to the function when it is triggered
 * by an event, such as a keypress event. It contains information about the event, such as the type of
 * event, the target element, and any data associated with the event. In this case,
 * @returns The function `returnInstagramHandle` returns `true` if the input character matches the
 * regular expression pattern, and `false` if it does not. If the input character is not a valid
 * character according to the regular expression, the function also prevents the default behavior of
 * the event (e.g. preventing the character from being typed into an input field).
 * @author Akshay N
 * @created_at 22/05/2023
 */
const returnInstagramHandle = (e) => {
    var regex = new RegExp("^[a-z0-9._]{1,30}");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
}

export { returnInstagramHandle }



