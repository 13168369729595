import rgba from "./rgba";
import pxToRem from "./pxToRem";

/**
 * The function returns a string representing a CSS box-shadow property with customizable offset,
 * radius, color, opacity, and inset.
 * @param {Array} [offset] - An array containing the horizontal and vertical offset of the shadow from the
 * element. The default value is an empty array.
 * @param {Array} [radius] - The `radius` parameter is an array that contains two values: the blur radius and
 * the spread radius of the box shadow. If no values are provided, the default values will be used.
 * @param {String} color - The color of the box shadow. It should be a valid CSS color value, such as hex code,
 * RGB, or color name.
 * @param {String} opacity - The opacity parameter is a number between 0 and 1 that determines the transparency
 * of the box shadow. A value of 0 means the shadow is completely transparent, while a value of 1 means
 * the shadow is completely opaque.
 * @param {String} [inset] - The `inset` parameter is an optional parameter that determines whether the box
 * shadow should be an inset shadow or an outset shadow. If the `inset` parameter is provided with a
 * non-empty string value, the box shadow will be an inset shadow. If the `inset` parameter is not
 * @returns {null} a string that represents a CSS box-shadow property value. The string includes the values
 * for the horizontal and vertical offset, blur radius, spread radius, color, opacity, and whether the
 * shadow is inset or not.
 * @return {null}
 * @author Akshay N
 * @created_at 21/05/2023
 */
function boxShadow(offset = [], radius = [], color, opacity, inset = "") {
  const [x, y] = offset;
  const [blur, spread] = radius;
  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(color, opacity)}`;

}

export default boxShadow;
