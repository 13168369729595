
import React, { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useNavigate } from 'react-router';
import { Container, SwipeableDrawer } from '@mui/material';
import { Header } from '.'
import { Outlet } from "react-router-dom";

const PostSignIn = ({ children, ...props }) => {

    let navigate = useNavigate();

    useEffect(() => {
        if (!cookieServices.get('accessToken'))
            navigate('/');
    }, [navigate])


    return (
        <React.Fragment>
            <Header />
            <Container maxWidth="sm" component="main" style={{ marginBottom: `60px` }}>
                <Outlet />
            </Container>
        </React.Fragment>
    )
}


export { PostSignIn }
