import extended from "../../../../_root/components/buttons/button-fab/extended";
import shadows from "../../../base/boxshadows";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...extended,
  primary: {
    ...extended.primary,
    "&:hover": {
      boxShadow: shadows.xs,
    },
    "&:focus:not(:hover)": {
      boxShadow: shadows.xs,
    },
  },

  secondary: {
    ...extended.secondary,
    "&:hover": {
      boxShadow: shadows.xs,
    },
    "&:focus:not(:hover)": {
      boxShadow: shadows.xs,
    },
  },
};
