import menu from "../../../_root/components/menu/menu";
import shadows from "../../base/boxshadows";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  ...menu,

  styleOverrides: {
    ...menu.styleOverrides,
    paper: {
      ...menu.styleOverrides.paper,
      color: colors.text.main,
      boxShadow: shadows.xs,
      backgroundColor: `${colors.common.white} !important`,
    },
  },
};
