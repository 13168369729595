import React from 'react'
import { StyledRouteLink } from '.'

function RouteLink({ to, label, ...props }) {

    return (
        <StyledRouteLink to={to} {...props}>{label}</StyledRouteLink>
    )
}

export { RouteLink }
