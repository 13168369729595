import shadows from "../../../_root/base/boxshadows";
import dialog from "../../../_root/components/dialog/dialog";
import rgba from "../../../_root/functions/rgba";
import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...dialog,
  styleOverrides: {
    ...dialog.styleOverrides,
    root: {
      ...dialog.styleOverrides.root,
      '& .MuiBackdrop-root': {
        backgroundColor: `${rgba(colors.common.black, 0.9)}`
      },
    },
    paper: {
      ...dialog.styleOverrides.paper,
      boxShadow: shadows.xs,
    }

  },
};
