
import Fade from "@mui/material/Fade";
import pxToRem from "../../functions/pxToRem";
import typography from "../../base/typography";
import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },
  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      fontSize: typography.size.sm,
      fontWeight: typography.fontWeightRegular,
      borderRadius: borders.borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
    },
  },
  variants: []
};
