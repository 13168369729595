import React, { useState } from 'react'
import { ContentCopy, Share } from '@mui/icons-material';
import { Box, IconButton, Menu, Stack, Typography } from '@mui/material';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { SocialShareStyled } from './';
import { GlobalAction } from '../../redux/actions/GlobalAction';
import { useDispatch } from 'react-redux';

function SocialShare({ type, title, desc, url, }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const dispatch = useDispatch();

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(`${title + '\n' + url}`);
        dispatch(GlobalAction.showToastMessage(`Copied to clipboard.`))
    }

    return (
        <SocialShareStyled>
            <IconButton color='primary' size='small' id="SocialShare" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick} >
                <Share />
            </IconButton>
            <Menu id="SocialShareMenu" anchorEl={anchorEl} open={open} onClose={handleClose}  >
                <Box>
                    <Typography sx={{ whiteSpace: `pre-wrap` }}>{`${title + '\n' + url}`}</Typography>
                </Box>
                <Stack direction="row" spacing={1} display={`flex`} alignItems="center">
                    <IconButton onClick={copyCodeToClipboard}>
                        <ContentCopy />
                    </IconButton>
                    <Box onClick={handleClose}>
                        <EmailShareButton url={url} subject={title} body="body">
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </Box>
                    <Box onClick={handleClose}>
                        <FacebookShareButton url={url} quote={title}  >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </Box>
                    <Box onClick={handleClose}>
                        <LinkedinShareButton url={url}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </Box>
                    <Box onClick={handleClose}>
                        <WhatsappShareButton url={url} title={title} separator=":: " >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </Box>
                </Stack>
            </Menu>
        </SocialShareStyled>
    )
}

export { SocialShare }