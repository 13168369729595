import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(16)} !important`,
      fontSize: typography.size.xl,
    },
  },
  variants: []
};
