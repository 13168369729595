import React from 'react'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import PropTypes from 'prop-types'
import { FormControl, FormHelperText } from '@mui/material';
import { FormTelePhoneInputStyle as useStyles } from '.';

function FormTelePhoneInput({ label, name, value, placeholder, className, onChange, tabIndex, id, error, formOpen, info, ...props }) {

  const [telePhoneValue, setTelePhoneValue] = React.useState('')

  const classes = useStyles();

  const handleChange = (value, info) => {
    setTelePhoneValue(value);

    onChange({
        target:{
            type: 'tel2',
            name: name,
            value: info.nationalNumber === '' ? '' : matchIsValidTel(value) ? '+' + info.countryCallingCode + info.nationalNumber : 'undefined',
        }
    });
  }

  return (
     <FormControl fullWidth className={classes.formControl}>
        <MuiTelInput
            className={`my-class-name ${classes.telePhonenputs}`}
            value={telePhoneValue}
            onChange={handleChange}
            defaultCountry="IN"
            forceCallingCode
            preferredCountries={['IN', 'US']}
            placeholder={placeholder}
            disableFormatting
        />
        {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
        {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
    </FormControl>
  )
}

FormTelePhoneInput.defaultProps = {
    className: ""
}

FormTelePhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func.isRequired
}

export { FormTelePhoneInput }
