import { failure, request, success } from ".";
import { StoriesServices } from "../services";

export const STORIES_REQUEST = 'STORIES_REQUEST';
export const STORIES_SUCCESS = 'STORIES_SUCCESS';
export const STORIES_FAILURE = 'STORIES_FAILURE';

export const STORY_DETAIL_REQUEST = 'STORY_DETAIL_REQUEST';
export const STORY_DETAIL_SUCCESS = 'STORY_DETAIL_SUCCESS';
export const STORY_DETAIL_FAILURE = 'STORY_DETAIL_FAILURE';

export const STORY_TIMELINE_REQUEST = 'STORY_TIMELINE_REQUEST';
export const STORY_TIMELINE_SUCCESS = 'STORY_TIMELINE_SUCCESS';
export const STORY_TIMELINE_FAILURE = 'STORY_TIMELINE_FAILURE';

export const STORY_PROMO_DETAIL_REQUEST = 'STORY_PROMO_DETAIL_REQUEST';
export const STORY_PROMO_DETAIL_SUCCESS = 'STORY_PROMO_DETAIL_SUCCESS';
export const STORY_PROMO_DETAIL_FAILURE = 'STORY_PROMO_DETAIL_FAILURE';

export const StoriesAction = {

    listStories: (params) => {
        return dispatch => {
            dispatch(request(STORIES_REQUEST, params));
            return StoriesServices.listStories(params)
                .then(
                    response => { return dispatch(success(STORIES_SUCCESS, params, response)); },
                    error => { return dispatch(failure(STORIES_FAILURE, params, error)); }
                );
        };
    },

    getStoryDetails: (params) => {
        return dispatch => {
            dispatch(request(STORY_DETAIL_REQUEST, params));
            return StoriesServices.getStoryDetails(params)
                .then(
                    response => { return dispatch(success(STORY_DETAIL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(STORY_DETAIL_FAILURE, params, error)); }
                );
        };
    },

    getStoryTimeline: (params) => {
        return dispatch => {
            dispatch(request(STORY_TIMELINE_REQUEST, params));
            return StoriesServices.getStoryTimeline(params)
                .then(
                    response => { return dispatch(success(STORY_TIMELINE_SUCCESS, params, response)); },
                    error => { return dispatch(failure(STORY_TIMELINE_FAILURE, params, error)); }
                );
        };
    },

    getStoryPromoDetails: (params) => {
        return dispatch => {
            dispatch(request(STORY_PROMO_DETAIL_REQUEST, params));
            return StoriesServices.getStoryPromoDetails(params)
                .then(
                    response => { return dispatch(success(STORY_PROMO_DETAIL_SUCCESS, params, response)); },
                    error => { return dispatch(failure(STORY_PROMO_DETAIL_FAILURE, params, error)); }
                );
        };
    }

};
