import styled from '@emotion/styled';
import { Close, Search } from '@mui/icons-material';
import { alpha, IconButton, InputAdornment, InputBase } from '@mui/material';
import React, { useState } from 'react'

// const SearchStyled = styled('div')(({ theme }) => ({
const SearchStyled = styled(({ ...props }) => (<div {...props} />))`
    position: relative;
    border-radius: 0.375rem; 
    border: 1px solid #d2d6da;
    background-color: ${props => alpha(props.theme.palette.common.white, 0.15)};
    &:hover {
        background-color: ${props => alpha(props.theme.palette.common.white, 0.25)};
    }
    margin-left: 0;
    width: '100%';
    ${props => props.theme.breakpoints.up("sm")} {
        margin-left: ${props => props.theme.spacing(3)}; 
        width: 'auto';
    }
`;

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));


const FormSearchInput = ({ ...props }) => {

    const { searchText, getSearchText } = props;

    const [searchKey, setSearchKey] = useState(searchText)

    const checkKeyPress = (e) => {
        if (e.key === 'Enter')
            isEnterPressed()
    };

    const handleChange = (e) => {
        let value = e.target.value;
        setSearchKey(value);
    }

    const isEnterPressed = () => {
        getSearchText(searchKey);
    }

    const clearSearch = () => {
        setSearchKey('');
        getSearchText('');
    }

    return (
        <SearchStyled style={props.sx}>
            <StyledInputBase
                fullWidth
                type="text"
                placeholder="Search…"
                value={searchKey}
                onKeyPress={checkKeyPress}
                onChange={handleChange}
                endAdornment={
                    searchKey ? (
                        <InputAdornment position="end" >
                            <IconButton aria-label="toggle password visibility" onClick={clearSearch} size='small' sx={{ paddingRight: `0.75rem` }}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="end" sx={{ visibility: `hidden` }}>
                            <IconButton aria-label="toggle password visibility" size='small' sx={{ paddingRight: `0.75rem` }}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                startAdornment={
                    <InputAdornment position="end" >
                        <IconButton aria-label="toggle password visibility" size='small'>
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </SearchStyled>
    )
}

export { FormSearchInput }